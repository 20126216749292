import { ISelectOption, Toast, getRandomKey, ToastHelper, ToastType } from '@streda/web_components';
import { ILocationRoom } from '../../../../../../models/location';
import { getRoomsApi } from '../../../../../../utils/api.utils';
import { getAccount } from '../../../../../../utils/auth.utils';

const handleOnChange = (
  option: ISelectOption,
  setOption: (option: ISelectOption) => void,
  rooms: ILocationRoom[],
  locationId: string,
  onChange: (newRooms) => void,
) => {
  if (option && rooms && onChange) {
    const newRoom = {
      name: option.label,
      typeId: option.value,
      id: getRandomKey().toString(),
      docks: [],
    };

    if (locationId) {
      getRoomsApi(getAccount())
        .roomAddRoom(locationId, newRoom)
        .then(room => {
          newRoom.id = room.data;
          onChange([...rooms, newRoom]);
          setOption({ value: '', label: '' });
          Toast.showToast({
            message: 'Room has been added.',
            colorType: 'Success',
          });
        })
        .catch(error => {
          ToastHelper(
            ToastType.DANGER,
            'Error during adding room. Please try again or contact with system administrator',
            error,
          );
        });
    } else {
      onChange([...rooms, newRoom]);
      setOption({ value: '', label: '' });
    }
  }
};

export default handleOnChange;
