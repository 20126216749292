import {
  getDeviceTypes,
  getLocationDetails,
  parseDockDevices,
  getLocationRoomDocks,
  DockWithZigbeeIdDto,
} from '@streda/web_components';
import { AxiosResponse } from 'axios';

export const initLocation = (locationId: string) => {
  let location;

  return getLocationDetails(locationId)
    .then((result: any) => {
      location = result;

      return Promise.all(location.rooms.map(room => getLocationRoomDocks(locationId, room.id)));
    })
    .then(docksResponse => {
      docksResponse.forEach((docks: AxiosResponse<DockWithZigbeeIdDto[]>, roomIndex) => {
        docks.data.forEach((dock, dockIndex) => {
          const dockCurrentData = location.rooms[roomIndex].docks[dockIndex];

          location.rooms[roomIndex].docks[dockIndex] = { ...dockCurrentData, ...dock };
        });
      });

      return getDeviceTypes();
    })
    .then(deviceTypes => {
      location.rooms.forEach((room, roomIndex) => {
        location.rooms[roomIndex].docks.forEach((dock, dockIndex) => {
          location.rooms[roomIndex].docks[dockIndex].devices = parseDockDevices(dock, deviceTypes);
        });
      });

      return Promise.resolve(location);
    });
};

export default initLocation;
