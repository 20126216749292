import * as React from 'react';
import { Button, ButtonType, RemoteTable, TabSwitch } from '@streda/web_components';
import { useHistory } from 'react-router-dom';
import { ICompaniesListState } from './companies-list.types';
import { getCompanies, getCompaniesColumns } from './companies-list.logic';

const CompaniesList: React.FC = () => {
  const history = useHistory();
  const [pageState, setPageState] = React.useState<ICompaniesListState>({
    companies: [],
    total: 0,
    filter: {
      active: true,
    },
    sort: { key: 'name', sortDirection: 'asc' },
    page: 0,
  });

  React.useEffect(() => {
    getCompanies(pageState, setPageState);
  }, [pageState.page, pageState.sort.key, pageState.sort.sortDirection]);

  return (
    <div className="page-content">
      <div className="d-flex bd-highlight">
        <div className="flex-grow-1 bd-highlight">
          <div className="h1-medium text-brand-highlight">Companies</div>
        </div>
        <div className="bd-highlight">
          <Button
            type={ButtonType.BUTTON}
            text="Add new company"
            onClick={() => history.push(`/companies/add`)}
          />
        </div>
      </div>
      <TabSwitch
        className="status-switch"
        options={[{ key: 'active', title: 'Active' }, { key: 'inactive', title: 'Inactive' }]}
        onChange={e =>
          getCompanies(
            { ...pageState, filter: { ...pageState.filter, active: e.key === 'active' } },
            setPageState,
          )
        }
      />
      <RemoteTable
        tableTestId="companies_table"
        className="table companies-table"
        onSortChange={sort => {
          setPageState({
            ...pageState,
            sort,
          });
        }}
        onRowClick={row => history.push(`/companies/${row.id}`)}
        columns={getCompaniesColumns()}
        data={pageState.companies}
        onLoadMore={() => {
          setPageState({
            ...pageState,
            page: pageState.page + 1,
          });
        }}
        showMoreButton={pageState.companies.length < pageState.total}
        emptyTableComponent={
          <div className="empty-table-content">
            <p className="empty-table-primary-label">Create first Company</p>
            <p className="empty-table-secondary-label">
              Add name of the company and invite the users
            </p>
          </div>
        }
      />
    </div>
  );
}

export default CompaniesList;
