import { Toast, ToastHelper, ToastType } from '@streda/web_components';
import { getLocationsApi } from '../../../../utils/api.utils';
import { getAccount } from '../../../../utils/auth.utils';

const onDuplicate: any = async (
  id: string,
  numberOfCopies: number,
  onHide?: (reload?: boolean, err?: any) => any,
) => {
  if (onHide) {
    onHide();
  }
  try {
    const response = await getLocationsApi(getAccount()).locationDuplicateLocation(
      id,
      numberOfCopies,
    );
    if (response) {
      Toast.showToast({
        message: 'Location has been duplicated',
        colorType: 'Success',
      });
    }
    if (onHide) {
      onHide(true);
    }
  } catch (error) {
    if (onHide) {
      onHide(false, error);
    }
    ToastHelper(ToastType.DANGER, 'An error occurred while duplicating the location', error);
  }
};

export default onDuplicate;
