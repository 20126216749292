export const formatFullDate = (date: Date) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  const minutes = date.getMinutes();
  const hours = date.getHours();
  return `${hours > 12 ? hours - 12 : hours}:${minutes < 10 ? `0${minutes}` : minutes} ${
    hours >= 12 ? 'PM' : 'AM'
  }, ${year}.${month < 10 ? `0${month}` : month}.${day < 10 ? `0${day}` : day}`;
};

export const formatDateOnly = (date: Date) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return `${year}.${month < 10 ? `0${month}` : month}.${day < 10 ? `0${day}` : day}`;
};
