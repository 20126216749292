import * as React from 'react';
import DocksListElement from './docks-list-element/docks-list-element';

function Dock({
  onDelete,
  snapInTypes,
  notLegacySnapInTypes,
  dock,
  onChange,
  addMode,
  snapInPositions,
  onDockNumberChange,
  pending,
  dockIndex,
  onDockOrderChange
}) {
  const snapInTypesWithExtraLegacyMemo = React.useMemo(() => {
    const legacyDock = snapInTypes.find(({ symbol }) => symbol === dock.dockCode);
    const isInNotLegacyDocks = notLegacySnapInTypes.some(({ symbol }) => symbol === dock.dockCode);
    const snapInTypesWithExtraLegacy = [...notLegacySnapInTypes];

    if (legacyDock && !isInNotLegacyDocks) {
      snapInTypesWithExtraLegacy.push(legacyDock);
    }

    return snapInTypesWithExtraLegacy;
  }, [snapInTypes, notLegacySnapInTypes]);

  return (
    <DocksListElement
      snapInTypes={snapInTypesWithExtraLegacyMemo}
      snapInPositions={snapInPositions}
      onDelete={() => onDelete(dock)}
      key={`dock-list-element-${dock.number}`}
      addMode={addMode}
      dock={dock}
      onChange={onChange}
      onDockNumberChange={onDockNumberChange}
      pending={pending}
      dockIndex={dockIndex}
      onDockOrderChange={onDockOrderChange}
    />
  );
}

export default React.memo(Dock);
