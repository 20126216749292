export type NetworkStatusType = 'active' | 'inactive' | 'partially-inactive' | 'unknown' | 'error';

export interface INetworkStatusIndicatorProps {
  status: NetworkStatusType;
  className?: string;
}

export const StatusTextDict = {
  active: 'All active',
  inactive: 'All Inactive',
  'partially-inactive': '1 Inactive',
  unknown: 'Unknown',
  error: 'Error',
};
