export enum UserRoles {
  installer = 'installer',
  administrator = 'administrator',
  superAdministrator = 'superAdministrator',
}

export type UserRoleType = keyof typeof UserRoles;

export default {
  UserRoles,
};
