import * as React from 'react';
import { AccountRoles, hasUserRole } from '@streda/web_components';
import InstallersSelection from './installers-selection';
import {
  IInstaller,
  ILocationEditModalState,
} from '../location-edit-modal/location-edit-modal.types';
import CompanySelection from './company-selection';

interface IBuildingCompany {
  onCompanyChange: (companyId: string) => void;
  onInstallersChange: (installers: IInstaller[]) => void;
  state: ILocationEditModalState;
}

function BuildingCompany({
  onCompanyChange,
  onInstallersChange,
  state,
}: IBuildingCompany): JSX.Element {
  return (
    <>
      {hasUserRole(AccountRoles.SUPER_ADMINISTRATOR) && (
        <CompanySelection onChange={onCompanyChange} state={state} />
      )}
      {state.company && (
        <InstallersSelection onChange={onInstallersChange} state={state} />
      )}
    </>
  );
}

export default BuildingCompany;
