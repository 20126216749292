import styled from 'styled-components';
import { Button, Modal } from '@streda/web_components';

export const StyledModal = styled(Modal)`
  .modal-dialog {
    min-width: 424px;
  }

  .modal-body {
    padding: 0;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 20px 32px 40px;
`;

export const ModalHeaderTitle = styled.span`
  font-size: 20px;
  user-select: none;
  font-weight: 500;
`;

export const ModalBody = styled.div`
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  max-height: 65vh;
  overflow-y: scroll;
  gap: 4px;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 32px 40px;
`;
export const EditButton = styled.span`
  cursor: pointer;
  user-select: none;
  display: inline-block;
  align-self: end;
  font-size: 14px;
  font-weight: 500;
`;
export const FullWidthButton = styled(Button)`
  width: 100%;
  margin-top: 18px;
`;
export const SectionTitle = styled.h6`
  margin-top: 24px;
  margin-bottom: 16px;
  user-select: none;
`;
