import * as React from 'react';
import {
  getCompanyApi,
  hideLoader,
  ISelectOption,
  ISortChangeEvent,
  LoaderMode,
  ModalLoader,
  showLoader,
  SortDirectionEnums,
  ToastHelper,
  ToastType,
} from '@streda/web_components';
import { getLocationsApi, LocationStatusEnum } from '../../../../utils/api.utils';
import { getAccount } from '../../../../utils/auth.utils';
import { ILocationsPageState } from './locations-page.types';

export const getFilterValue = (filter: any, filterKey: string) => {
  if (filter && filterKey && filter[filterKey]) {
    if (typeof filter[filterKey] === 'string') {
      return filter[filterKey] as string;
    }

    if (typeof filter[filterKey] === 'object' && filter[filterKey].value) {
      return filter[filterKey].value as string;
    }
  }

  return '';
};

export const searchCompanies = (searchPhrase: string): Promise<ISelectOption[]> =>
  getCompanyApi(getAccount())
    .companyGetCompanies(searchPhrase)
    .then(result => {
      const companies = result.data.data.map(company => ({
        label: company.name,
        value: company.name,
      }));
      return Promise.resolve(companies);
    })
    .catch(() => {
      return Promise.resolve([]);
    });

export const loadData = (
  deleteOld: boolean,
  state: ILocationsPageState,
  setPageState: (state: ILocationsPageState) => any,
) => {
  showLoader(<ModalLoader />, {
    mode: LoaderMode.COVER,
    loaderContainerSelector: '.locations-table',
  });
  getLocationsApi(getAccount())
    .locationGetLocations(
      getFilterValue(state.filter, 'addressCity'),
      getFilterValue(state.filter, 'addressStreet'),
      getFilterValue(state.filter, 'locationName'),
      LocationStatusEnum[
        getFilterValue(state.filter, 'status')
          ? getFilterValue(state.filter, 'status')
          : LocationStatusEnum.Draft
      ],
      getFilterValue(state.filter, 'companyName'),
      getFilterValue(state.filter, 'lotNumber'),
      getFilterValue(state.filter, 'notes'),
      state.sort.key,
      state.sort.sortDirection === SortDirectionEnums.DESCENDING,
      deleteOld ? 0 : state.pages + 1,
    )
    .then(result => {
      if (result.data.data.length && deleteOld) {
        setPageState({
          ...state,
          locations: result.data.data,
          pages: 0,
          total: result.data.recordsTotalNumber,
        });
      } else if (result.data.data.length && !deleteOld) {
        setPageState({
          ...state,
          locations: [...state.locations, ...result.data.data],
          pages: state.pages + 1,
          total: result.data.recordsTotalNumber,
        });
      } else {
        setPageState({ ...state, locations: [], pages: 0, total: 0 });
      }
      hideLoader();
    })
    .catch(error => {
      hideLoader();
      ToastHelper(
        ToastType.DANGER,
        'Error during getting locations data. Please try again or contact with system administrator',
        error,
      );
    });
};

export const onSortChange = (
  e: ISortChangeEvent,
  state: ILocationsPageState,
  setPageState: (newState: ILocationsPageState) => any,
) => {
  loadData(true, { ...state, sort: { key: e.key, sortDirection: e.sortDirection } }, setPageState);
};

export default {
  getFilterValue,
  onSortChange,
  loadData,
};
