import {
  AccountRoles,
  getAccount,
  getLocationAssigmentApi,
  getUserApi,
  PagedResultOfSystemUserDto,
  LocationAssigmentDto,
  ToastHelper,
  ToastType,
} from '@streda/web_components';
import { AxiosResponse } from 'axios';

export const getInstallersOptions = (
  companyId: string,
): Promise<AxiosResponse<PagedResultOfSystemUserDto>> =>
  getUserApi(getAccount())
    .userGetSystemUser(undefined, AccountRoles.INSTALLER, companyId)
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during getting installers. Please try again or contact with system administrator',
        error,
      );
      return Promise.resolve(error);
    });

export const assignInstaller = (
  locationId: string,
  userId: string,
): Promise<AxiosResponse<LocationAssigmentDto>> =>
  getLocationAssigmentApi(getAccount())
    .locationAssigmentAssignUser(locationId, userId)
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during assigning installer. Please try again or contact with system administrator',
        error,
      );
      return Promise.reject(error);
    });

export const removeInstall = (
  locationId: string,
  userId: string,
): Promise<AxiosResponse<LocationAssigmentDto>> =>
  getLocationAssigmentApi(getAccount())
    .locationAssigmentRemoveUser(locationId, userId)
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during removing installer. Please try again or contact with system administrator',
        error,
      );
      return Promise.resolve(error);
    });
