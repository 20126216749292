import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  FormModalProvider,
  FormModalConsumer,
  ModalLoader,
  showLoader,
  hideLoader,
  ButtonType,
} from '@streda/web_components';
import * as _ from 'lodash';
import InstallationStatus from '../installation-status/installation-status';
import {
  getEmptyLocationEditModalState,
  onChange,
  onStatusChange,
  getStepsDefinition,
  handleOnSubmit,
  onSubmit,
  handleOnStepChange,
  onFileDownload,
  handleFilesChange,
  handleOnHide,
  handleOnConfigurationClick,
  getPermanentSections,
  getModeTitle,
} from './location-edit-modal.logic';
import LocationDetails from '../location-details/location-details';
import { getValidatorByType } from './location-edit-modal.logic.validation';
import { ILocationEditModalProps, ILocationEditModalState } from './location-edit-modal.types';
import { init, updateInvitations } from './location-edit-modal.logic.api';
import RoomsSection from '../controls/rooms/rooms-section/rooms-section';
import { handleOnRoomsChange, handleOnDocksChange } from './location-edit-modal.logic.rooms';
import SnapinsList from '../snap-ins-list/snap-ins-list';
import { canEditLocationSection, getStatusNumberByName } from '../../../../utils/location.utils';
import LocationSettingsWrapper from '../location-settings/location-settings-wrapper';
import AssignOwner from '../controls/assign-owner';
import OwnersList from '../controls/owners-list';
import onInviteOwner, {
  onInvitationEmailUpdate,
  onRemoveOwnerInvitation,
} from './location-edit-modal.logic.owners';
import BuildingCompany from '../building-company/building-company';

const temporaryValidPinCode = '12345';

const LocationEditModal: React.FC<ILocationEditModalProps> = (props: ILocationEditModalProps) => {
  const [cmpState, setCmpState] = React.useState<ILocationEditModalState>(
    getEmptyLocationEditModalState(),
  );
  const [savePending, setSavePending] = React.useState(false);
  const [dataInitialized, setDataInitialized] = React.useState(false);
  const { state, onHide, onUpdate, reloadData } = props;
  const history = useHistory();

  React.useEffect(() => {
    if (state.visible) {
      showLoader(<ModalLoader />);

      init(state, setCmpState, onHide, hideLoader).then(() => {
        hideLoader();
        setDataInitialized(true);
      });
    }
  }, [state]);

  React.useEffect(() => {
    let updateInvitationsInterval;
    if (cmpState.data && state.id) {
      updateInvitationsInterval = setInterval(
        () => updateInvitations(state.id, setCmpState),
        15000,
      );
    }

    return () => clearInterval(updateInvitationsInterval);
  }, [state.id]);

  const onSaveHandler = async () => {
    setSavePending(true);
    await onSubmit(state.id, cmpState, onHide, null);
    setSavePending(false);
  };

  const editMode = !!state.id;

  return (
    <FormModalProvider
      mode={editMode ? 'Edit' : 'Add'}
      header={
        editMode
          ? `${cmpState.data.details.name ?? ''} (${cmpState.data.details.lotNumber ?? ''})`
          : 'Adding new location'
      }
      show={state && state.visible && dataInitialized}
      onHide={() => {
        setDataInitialized(false);
        handleOnHide(onHide);
      }}
      onStepChange={(oldStep, _newStep) => handleOnStepChange(oldStep, cmpState, setCmpState)}
      stepsDefinition={getStepsDefinition(cmpState, state.id)}
      permanentSections={getPermanentSections()}
      finishButtonText="Configure devices"
      finishButtonDisabled={savePending || _.isEmpty(cmpState.data.docks)}
      finishButtonPending={savePending}
      onSubmit={async e => {
        setSavePending(true);
        await handleOnSubmit(e, cmpState, onHide, history, state.id);
        setSavePending(false);
      }}
      onAlternativeFinishAction={_.once(async () => onSaveHandler())}
      alternativeFinishButtonText="Save draft and close"
      alternativeFinishButtonPending={savePending}
      alternativeFinishButtonDisabled={savePending}
      hideBackButton
      className="location-edit-modal"
      nextButtonId={`${getModeTitle(editMode)}_loc_next_step_button`}
    >
      <FormModalConsumer className="w-350" pageNumber={0}>
        <LocationDetails
          files={cmpState.specFiles}
          onFilesChange={files => handleFilesChange(files, cmpState, setCmpState)}
          onFileDownload={file => onFileDownload(state.id, file)}
          enableValidation={cmpState.showGeneralStepValidationErrors || !!state.id}
          validator={getValidatorByType('location-details', cmpState.data.details.status)}
          locationId={state.id}
          locationTypes={cmpState.locationTypes}
          locationDetails={cmpState.data.details}
          onChange={e =>
            onChange(
              { ...cmpState.data, details: e },
              state.id,
              cmpState,
              setCmpState,
              onUpdate,
              handleFilesChange,
            )
          }
        />
        {state.id ? (
          <>
            <div className="horizontal-divider m-t-36" />
            <InstallationStatus
              className="m-t-64"
              onStatusChange={statusNumber =>
                onStatusChange(statusNumber, state.id, cmpState, setCmpState, reloadData)
              }
              status={
                cmpState.data.details.status
                  ? getStatusNumberByName(cmpState.data.details.status)
                  : 0
              }
              location={cmpState}
            />
          </>
        ) : null}
        {/* <OwnerDetails
          enableValidation={cmpState.showGeneralStepValidationErrors || !!state.id}
          validator={getValidatorByType('owner-details', cmpState.data.details.status)}
          locationId={state.id}
          ownerDetails={cmpState.data.owner}
          onChange={e =>
            onChange({ ...cmpState.data, owner: e }, state.id, cmpState, setCmpState, onUpdate)
          }
        /> */}
      </FormModalConsumer>
      <FormModalConsumer pageNumber={1}>
        <BuildingCompany
          onCompanyChange={companyId => {
            cmpState.company = companyId;

            if (!companyId) {
              cmpState.installers = [];
            }

            setCmpState({ ...cmpState });
          }}
          onInstallersChange={installers => {
            cmpState.installers = installers;
            setCmpState({ ...cmpState });
          }}
          state={cmpState}
        />
      </FormModalConsumer>
      <FormModalConsumer className="w-350" pageNumber={2}>
        <RoomsSection
          addMode={!state.id || canEditLocationSection(cmpState.data.details.status, 'rooms')}
          className="w-100p"
          locationModel={cmpState.data}
          rooms={cmpState.data.rooms}
          onModelChange={newModel => handleOnRoomsChange(newModel.rooms, cmpState, setCmpState)}
          location={cmpState.data}
          editMode={editMode}
        />
      </FormModalConsumer>
      <FormModalConsumer pageNumber={3}>
        <SnapinsList
          addMode={!state.id || canEditLocationSection(cmpState.data.details.status, 'docks')}
          rooms={cmpState.data.rooms}
          docks={cmpState.data.docks}
          locationConnections={cmpState.data.connectionConfigurations}
          locationId={state.id}
          onChange={docks => handleOnDocksChange(docks, cmpState, setCmpState)}
        />
      </FormModalConsumer>
      <FormModalConsumer className="w-350" pageNumber={4}>
        {cmpState.company ? (
          <AssignOwner
            onAssign={async newOwnerEmail =>
              onInviteOwner(newOwnerEmail, cmpState, setCmpState, state.id)
            }
            currentInvitation={!_.isEmpty(cmpState.invitations) && cmpState.invitations[0]}
          />
        ) : (
          <span>Assign company to add Home Owner</span>
        )}
        {!_.isEmpty(cmpState.invitations) && (
          <OwnersList
            invitations={cmpState.invitations}
            company={cmpState.company}
            state={cmpState}
            onInvitationRemove={invitation => {
              onRemoveOwnerInvitation(invitation, cmpState, setCmpState);
            }}
            onInvitationEmailUpdate={(updatedInvitation, oldInvitation) =>
              onInvitationEmailUpdate(updatedInvitation, oldInvitation, cmpState, setCmpState)
            }
          />
        )}
      </FormModalConsumer>
      {/* <FormModalConsumer className="w-350" pageNumber={4}>
        <AssignInstaller
          alreadyAssigned={cmpState.data.installers.map(i => i.id)}
          onAssign={installer =>
            onAssignInstaller(installer, cmpState, setCmpState, onUpdate, state.id)
          }
          editMode={editMode}
        />
        {cmpState.data.installers && cmpState.data.installers.length ? (
          <div className="horizontal-divider m-t-24 m-b-24" />
        ) : null}
        <InstallersList
          title="Assigned Installers:"
          installers={cmpState.data.installers.map(i => i.id)}
          onDelete={id => onDeleteInstaller(id, cmpState, setCmpState, onUpdate, state.id)}
        />
      </FormModalConsumer> */}
      <FormModalConsumer className="configuration-step m-h-360" pageNumber={5}>
        <div className="w-500">
          {state.id ? (
            <Button
              className="m-b-32"
              type={ButtonType.BUTTON}
              text="Configure devices"
              onClick={() => handleOnConfigurationClick(state.id, history, cmpState.data)}
              testId={`${getModeTitle(editMode)}_loc_configure_devices_button`}
            />
          ) : null}
        </div>
      </FormModalConsumer>
      <FormModalConsumer className="m-h-360" pageNumber={6}>
        <LocationSettingsWrapper
          onAuthPinChange={pinCode => {
            const pinCodeValid = pinCode === temporaryValidPinCode;

            setCmpState({
              ...cmpState,
              systemSettingsAuthorized: pinCodeValid,
            });

            return pinCodeValid;
          }}
          authorized={cmpState.systemSettingsAuthorized}
          locationId={state.id}
        />
      </FormModalConsumer>
    </FormModalProvider>
  );
};

export default LocationEditModal;
