export interface ILoginPageProps {
  history: any;
  loginCallback(): any;
}

export interface ILoginData {
  login: string;
  password: string;
}

export const LoginErrorsStatuses = {
  403: 'The email or password you entered is incorrect.\nPlease try again.',
  504: 'Cannot connect to backend service.',
};

export const LoginErrorsCodes = {
  ECONNABORTED: 'Reached request timeout. Please try again.',
};

export const LoginErrorsMessages = {
  'Network Error': 'Network error. Check your internet connection.',
  'Insufficient Privileges':
    'Insufficient Privileges. Ensure user which you want to use has correct role.',
};
