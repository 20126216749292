import { AxiosResponse } from 'axios';
import {
  IModalState,
  LocationDto,
  getSnapinApi,
  DocksMap,
  RoomDto,
  SnapIn,
  ConnectionConfigurationDto,
  getInvitationApi,
  InvitationDto,
  InvitationStatusEnum,
  getLocationAssigmentApi,
  hasUserRole,
  AccountRoles,
  ToastHelper,
  ToastType,
} from '@streda/web_components';
import {
  getLocationsApi,
  getLocationEmptyModel,
  getDictionaryApi,
  getBaseConfiguration,
  getRoomsApi,
  getDocksApi,
  getConfigurationApi,
} from '../../../../utils/api.utils';
import { getAccount } from '../../../../utils/auth.utils';
import IBFile from '../../../../models/file';
import { ILocationEdit } from '../../../../models/location';
import {
  mapToLocationEdit,
  mapToLocationNewDto,
  mapToLocationDto,
} from '../../../../utils/location.utils';
import { IGetSnapInsParams, ILocationEditModalState } from './location-edit-modal.types';
import uploadMultipleAttachments from '../../../../api/uploadMultipleAttachments';

export const locationAdd = (locationModel: ILocationEdit) => {
  return getLocationsApi(getAccount()).locationCreateLocation(mapToLocationNewDto(locationModel));
};

export const uploadLocationSpecs = (id: string, files: File[]) => {
  return uploadMultipleAttachments(id, 0, files, {}, getBaseConfiguration(getAccount()));
};

export const locationEdit = (
  locationModel: ILocationEdit,
  specFiles = [],
  onSuccessCallback?: (model) => any,
  onFailureCallback?: () => any,
) => {
  const filesToUpload = specFiles.filter(f => f.file).map(f => f.file);

  getLocationsApi(getAccount())
    .locationUpdateLocation(mapToLocationDto(locationModel))
    .then(locationResponse => {
      const { data } = locationResponse;
      if (filesToUpload.length) {
        return uploadLocationSpecs(locationModel.id, filesToUpload).then(filesResponse =>
          Promise.resolve([data, filesResponse.data]),
        );
      }
      return Promise.resolve([data]);
    })
    .then(([locationData, filesData]) => {
      if (onSuccessCallback) {
        onSuccessCallback([locationData, filesData]);
      }
    })
    .catch(error => {
      if (onFailureCallback) {
        onFailureCallback();
      }

      ToastHelper(
        ToastType.DANGER,
        'Error during location edit. Please try again or contact with system administrator',
        error,
      );
    });
};

const setInitData = (
  loc: AxiosResponse<LocationDto>,
  locTypesDict: AxiosResponse<{
    [key: string]: string;
  }>,
  docks: DocksMap,
  invitations: InvitationDto[],
  snapIns: SnapIn[],
  connectionConfigurations: ConnectionConfigurationDto[],
  companyAssignment,
  setCmpState: (state: ILocationEditModalState) => void,
) => {
  const locEdit = mapToLocationEdit(loc.data, docks, snapIns, connectionConfigurations);
  setCmpState({
    specFiles: loc.data.files.map(f => {
      return { fileName: f.fileName, blobId: f.blobId } as IBFile;
    }),
    data: locEdit,
    locationTypes: Object.keys(locTypesDict.data).map(key => {
      return { value: key, label: locTypesDict.data[key] };
    }),
    systemSettingsAuthorized: false,
    invitations,
    company: companyAssignment.companyId,
    installers: companyAssignment.users,
  });
};

export const getRoomsDocks = (locationId: string, locationRooms: RoomDto[]) => {
  return Promise.all(
    locationRooms.map(room => getDocksApi(getAccount()).dockGetDocks(locationId, room.id)),
  );
};

const getSnapInsPage = (getSnapInsParams: IGetSnapInsParams, payload) => {
  const {
    criteriaSerialNumber,
    criteriaSnapInCode,
    criteriaLocationId,
    criteriaMacAddress,
    criteriaZigbeeId,
    orderBy,
    descending,
    pageNumber,
  } = getSnapInsParams;

  return getSnapinApi(getAccount())
    .snapInGetSnapIns(
      criteriaSerialNumber,
      criteriaSnapInCode,
      criteriaLocationId,
      criteriaMacAddress,
      criteriaZigbeeId,
      orderBy,
      descending,
      pageNumber,
    )
    .then(result => {
      const increasedPage = pageNumber + 1;
      const { data } = result;
      const { recordsTotalNumber, pageSize } = data;

      if (recordsTotalNumber > increasedPage * pageSize) {
        return getSnapInsPage(
          {
            criteriaLocationId,
            pageNumber: increasedPage,
          },
          payload,
        );
      }
      return Promise.resolve([...payload, ...data.data]);
    });
};

const getSnapIns = state => {
  const pageNumber = 0;

  return getSnapInsPage(
    {
      criteriaLocationId: state.id,
      pageNumber,
    },
    [],
  );
};

export const updateInvitations = (
  locationId: string,
  setCmpState: React.Dispatch<React.SetStateAction<ILocationEditModalState>>,
): Promise<void> => {
  if (locationId) {
    return Promise.all([
      getInvitationApi(getAccount()).invitationGetInvitationsForLocation(
        locationId,
        InvitationStatusEnum.Pending,
      ),
      getInvitationApi(getAccount()).invitationGetInvitationsForLocation(
        locationId,
        InvitationStatusEnum.Accepted,
      ),
    ]).then(([pendingInvitations, acceptedInvitations]) => {
      setCmpState(prevState => {
        return {
          ...prevState,
          invitations: [...pendingInvitations.data.data, ...acceptedInvitations.data.data],
        };
      });
    });
  }
  return Promise.resolve();
};

export const init = (
  state: IModalState,
  setCmpState: (state: ILocationEditModalState) => void,
  onHide?: () => void,
  hideLoader?: () => void,
) => {
  if (state.id) {
    return Promise.all([
      getLocationsApi(getAccount()).locationGetLocation(state.id),
      getDictionaryApi(getAccount()).dictionaryGetDict('LocationType'),
      getRoomsApi(getAccount()).roomGetRooms(state.id),
      getConfigurationApi(getAccount()).configurationGetConfigureConnections(state.id),
      getSnapIns(state),
      getInvitationApi(getAccount()).invitationGetInvitationsForLocation(
        state.id,
        InvitationStatusEnum.Pending,
      ),
      getInvitationApi(getAccount()).invitationGetInvitationsForLocation(
        state.id,
        InvitationStatusEnum.Accepted,
      ),
      getLocationAssigmentApi(getAccount()).locationAssigmentGetLocationAssigment(state.id),
    ])
      .then(
        ([
          loc,
          locTypesDict,
          locationRooms,
          connectionConfigurations,
          snapIns,
          pendingInvitations,
          acceptedInvitations,
          companyAssignment,
        ]) => {
          return new Promise(resolve => {
            getRoomsDocks(state.id, locationRooms.data).then(roomsDocks => {
              resolve([
                loc,
                locTypesDict,
                locationRooms,
                connectionConfigurations,
                roomsDocks,
                snapIns,
                pendingInvitations,
                acceptedInvitations,
                companyAssignment,
              ]);
            });
          });
        },
      )
      .catch(error => {
        onHide();
        hideLoader();
        ToastHelper(
          ToastType.DANGER,
          'Error during getting location details. Please try again or contact with system administrator',
          error,
        );
      })
      .then((res: any[]) => {
        const [
          loc,
          locTypesDict,
          locationRooms,
          connectionConfigurations,
          roomsDocks,
          snapIns,
          pendingInvitations,
          acceptedInvitations,
          companyAssignment,
        ] = res;
        const docks = {};

        locationRooms.data.forEach((room, index) => {
          docks[room.id] = roomsDocks[index].data;
        });
        loc.data.rooms = locationRooms.data;

        setInitData(
          loc,
          locTypesDict,
          docks,
          [...pendingInvitations.data.data, ...acceptedInvitations.data.data],
          snapIns,
          connectionConfigurations.data,
          companyAssignment.data,
          setCmpState,
        );
        return Promise.resolve();
      });
  }
  const isSuperAdmin = hasUserRole(AccountRoles.SUPER_ADMINISTRATOR);
  const { companyId } = getAccount();

  return Promise.all([getDictionaryApi(getAccount()).dictionaryGetDict('LocationType')]).then(
    ([locTypesDict]) => {
      setCmpState({
        locationTypes: Object.keys(locTypesDict.data).map(key => {
          return { value: key, label: locTypesDict.data[key] };
        }),
        data: getLocationEmptyModel(),
        systemSettingsAuthorized: false,
        company: isSuperAdmin ? null : companyId,
        installers: [],
      });
      return Promise.resolve();
    },
  );
};

export const downloadFile = (locationId: string, blobId: string): Promise<AxiosResponse<any>> => {
  return new Promise(resolve => {
    getLocationsApi(getAccount())
      .locationDownloadFile(locationId, blobId, {
        responseType: 'blob',
        timeout: 30000,
      })
      .then(result => {
        resolve(result);
      });
  });
};

export const sendInvitation = (
  locationId: string,
  companyId: string = null,
  email: string,
): Promise<AxiosResponse<any>> => {
  return new Promise((resolve, reject) => {
    getInvitationApi(getAccount())
      .invitationInviteHomeOwner({
        email,
        companyId,
        locationId,
      })
      .then(result => {
        resolve(result);
      })
      .catch(err => {
        reject(err);
      });
  });
};
