import * as React from 'react';
import { getAccount, getUserApi, LogoutIcon, RowOptions } from '@streda/web_components';
import {
  Email,
  FirstName,
  LastName,
  UserAvatar,
  UserInfo,
  UserInfoToggleContainer,
  UserName,
} from './user-dropdown.components';
import { IUserData } from './user-dropdown.types';
import { useAuth } from '../../../../utils/hooks/useAuth';

function UserDropdown() {
  const account = getAccount();
  const { userId } = account;
  const [userData, setUserData] = React.useState<IUserData>({
    name: '',
    surname: '',
    email: '',
  });
  const { email = '', surname = '', name = '' } = userData;
  const getUserInitials = () => `${name && name[0]}${surname && surname[0]}`;
  const auth = useAuth();

  React.useEffect(() => {
    getUserApi(account)
      .userGetSystemUser2(userId)
      .then(result => {
        const { name, surname, email } = result.data;

        setUserData({ name, surname, email });
      });
  }, []);

  return (
    <RowOptions
      alignRight
      toggleComponent={
        <UserInfoToggleContainer>
          <UserAvatar>{getUserInitials()}</UserAvatar>
          <UserInfo>
            <FirstName>{name}</FirstName>
            <LastName>{surname}</LastName>
          </UserInfo>
        </UserInfoToggleContainer>
      }
      options={[
        {
          value: (
            <UserInfoToggleContainer>
              <UserAvatar>{getUserInitials()}</UserAvatar>
              <UserInfo>
                <UserName>
                  {name} {surname}
                </UserName>
                <Email>{email}</Email>
              </UserInfo>
            </UserInfoToggleContainer>
          ),
        },
        {
          value: 'Log out',
          icon: <LogoutIcon />,
          onClick: () => auth.logout(),
        },
      ]}
    />
  );
}

export default UserDropdown;
