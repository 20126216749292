import * as React from 'react';
import { NoneButton } from '@streda/web_components';
import styled, { css } from 'styled-components';
import { useHistory, Switch, withRouter, Route, useRouteMatch } from 'react-router-dom';
import { IPanelsNavigationButtons } from './configuration-panel.types';

export const HeaderOptionButton = styled(NoneButton)<{ active?: boolean }>`
  font-size: 12px;
  margin-left: 16px;
  color: ${({ theme }) => theme.colors.d3gray};
  font-weight: 600;

  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.colors.font1};
    `}

  &:hover {
    color: ${({ theme }) => theme.colors.font1};
  }
`;

export const ConfigurationsActionBtnsContainer = styled.div`
  display: flex;
  padding: 68px 84px 0 84px;
  justify-content: end;
  align-items: center;
  gap: 8px;
  margin-right: 8px;
`;

export const PanelsNavigationButtons: React.FC<IPanelsNavigationButtons> = withRouter(
  ({ locationId, location }) => {
    const history = useHistory();
    const { url } = useRouteMatch();
    const { pathname } = location;
    const paths = {
      basicPanel: `${url}/connections`,
      advancedPanel: `${url}/connections-advanced`,
      scenes: `${url}/scenes`,
    };

    return (
      <Switch>
        <Route exact path={[paths.basicPanel, paths.advancedPanel, paths.scenes]}>
          <HeaderOptionButton
            type="button"
            onClick={() => history.push(`/location/${locationId}/configuration/connections`)}
            active={pathname === paths.basicPanel}
          >
            BASIC
          </HeaderOptionButton>
          <HeaderOptionButton
            type="button"
            onClick={() =>
              history.push(`/location/${locationId}/configuration/connections-advanced`)
            }
            active={pathname === paths.advancedPanel}
          >
            ADVANCED
          </HeaderOptionButton>
          <HeaderOptionButton
            type="button"
            onClick={() => history.push(`/location/${locationId}/configuration/scenes`)}
            active={pathname === paths.scenes}
          >
            SCENES
          </HeaderOptionButton>
        </Route>
      </Switch>
    );
  },
);
