import * as React from 'react';
import { hasUserRole, AccountRoles } from '@streda/web_components';
import { Switch, withRouter, Route } from 'react-router-dom';
import NotFoundPage from '../not-found-page';
import CompaniesList from './companies-list/companies-list';
import CompanyManage from './company-manage/company-manage';

const CompaniesApp: React.FC = () => {
  const isSuperAdmin = hasUserRole(AccountRoles.SUPER_ADMINISTRATOR);

  return (
    <Switch>
      {isSuperAdmin && (
        <Route path="/companies">
          <CompaniesList />
          <Route path={['/companies/add', '/companies/:companyId']}>
            <CompanyManage />
          </Route>
        </Route>
      )}
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default withRouter(CompaniesApp);
