import * as React from 'react';
import { NumberInput, ActionModal, LocationIcon } from '@streda/web_components';
import { IDuplicateModalProps } from './duplicate-modal.types';
import onDuplicate from './duplicate-modal.logic';

const DuplicateModal: React.FC<IDuplicateModalProps> = (props: IDuplicateModalProps) => {
  const [duplicateCountValue, setDuplicateCountValue] = React.useState<number>(1);
  const { state, onHide } = props;
  return (
    <ActionModal
      onHide={() => onHide(false)}
      onConfirm={() => onDuplicate(state.id, duplicateCountValue, onHide)}
      show={state.visible}
      icon={<LocationIcon />}
      title=""
      header="Duplicate location"
      description={
        <>
          <div className="duplicate-modal-text">How many duplications you want?</div>
          <div className="d-flex justify-content-center duplicate-modal-input">
            <NumberInput value={duplicateCountValue} onChange={setDuplicateCountValue} />
          </div>
        </>
      }
      altButton2Text="Duplicate"
    />
  );
};

export default DuplicateModal;
