import * as React from 'react';
import { ISelectOption, IFormErrorInfo, SnapInTypeDto, usePromise } from '@streda/web_components';
import styled from 'styled-components';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { IRoomsListProps } from './snap-ins-list.types';
import DocksList from '../docks-list/docks-list';
import handleOnDocksInRoomChange from './snap-ins-list.logic';
import { getDocksValidation } from '../location-edit-modal/validation.def';
import { getSnapInTypesOptions, getSnapInPositions } from '../../../../utils/dictionary.api.utils';

const MainContainer = styled.div`
  max-width: 1030px;
  min-width: 708px;
`;
const HeaderTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
`;
const HeaderLabel = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.gray30};
  margin-top: 8px;
  margin-bottom: 40px;
  line-height: 24px;
`;
const StyledCompatibilityTooltip = styled(ReactTooltip)`
  text-align: left;
  line-height: 18px;
`;

const SnapInsList: React.FC<IRoomsListProps> = (props: IRoomsListProps) => {
  const { className, rooms, onChange, addMode, docks, locationId, locationConnections } = props;
  const getNotLegacySnapInTypesOptionsPromise = React.useMemo(
    () => getSnapInTypesOptions(false),
    [],
  );
  const getSnapInTypesOptionsPromise = React.useMemo(() => getSnapInTypesOptions(), []);
  const getSnapInPositionsPromise = React.useMemo(getSnapInPositions, []);
  const [snapInTypes] = usePromise<SnapInTypeDto[]>(getSnapInTypesOptionsPromise, []);
  const [notLegacySnapInTypes] = usePromise<SnapInTypeDto[]>(
    getNotLegacySnapInTypesOptionsPromise,
    [],
  );
  const [snapInPositions] = usePromise<ISelectOption[]>(getSnapInPositionsPromise, []);
  const validationErrors: IFormErrorInfo[] = getDocksValidation({ rooms, docks });

  return (
    <MainContainer className={className}>
      <HeaderTitle>Docks & snap-ins</HeaderTitle>
      <HeaderLabel>
        Define dock type, snap-in type, dock number and specify the position in concrete room
      </HeaderLabel>
      <hr />
      {rooms
        ? rooms.map(room => (
            <DocksList
              snapInTypes={snapInTypes}
              notLegacySnapInTypes={notLegacySnapInTypes}
              snapInPositions={snapInPositions}
              validationErrors={validationErrors}
              className="w-100p m-t-24 p-b-36 radius-4"
              key={`socket-list-${room.id}`}
              addMode={addMode}
              room={room}
              locationId={locationId}
              docks={docks}
              onChange={newDocks => handleOnDocksInRoomChange(newDocks, docks, room.id, onChange)}
            />
          ))
        : null}
      <StyledCompatibilityTooltip id="dockCompatibilityWarning">
        Snap-in in this connection <br />
        will have limited function. <br />
        <br />
        Recommended connection: <br />
        E-only: basic & smart <br />
        Hybrid: flex & ultimate
      </StyledCompatibilityTooltip>
    </MainContainer>
  );
};

export default SnapInsList;
