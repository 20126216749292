import * as React from 'react';
import { ActionModal, SnapInIcon } from '@streda/web_components';

const SaveConfigurationModal = props => {
  const { onHide, onConfirm, show } = props;
  return (
    <ActionModal
      onHide={() => onHide(false)}
      onConfirm={() => onConfirm()}
      show={show}
      icon={<SnapInIcon width="100px" height="100px" />}
      title=""
      header="Save configuration?"
      description="All connections & configured elements will be saved."
    />
  );
};

export default SaveConfigurationModal;
