import { getDictionarySelectOptions, ISelectOption, SnapInTypeDto } from '@streda/web_components';
import { getDictionaryApi } from './api.utils';
import { getAccount } from './auth.utils';

export const getSnapInTypesOptions = (legacy?: boolean): Promise<SnapInTypeDto[]> => {
  return new Promise((resolve, reject) => {
    getDictionaryApi(getAccount())
      .dictionaryGetSnapInTypes(legacy)
      .then(result => {
        if (result) {
          resolve(result.data);
        } else {
          reject();
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getSnapInPositions = (): Promise<ISelectOption[]> => {
  return getDictionarySelectOptions('Position');
};
