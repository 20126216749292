import { getInvalidFields, IFormErrorInfo } from '@streda/web_components';
import { locationStatuses } from '../../../../utils/location.utils';

export type LocationValidationType =
  | 'location-details'
  | 'owner-details'
  | 'company'
  | 'rooms'
  | 'docks'
  | 'docks-count'
  | 'installers-count'
  | 'home-owner-count'
  | 'devices-installed';

const baseFieldValidation = (model, status, fieldsSection) => {
  const statusObject = locationStatuses.find(locationStatus => locationStatus.name === status);
  const fieldsToCheck = statusObject
    ? statusObject.fields
        .filter(({ fieldName, section }) => fieldName && section === fieldsSection)
        .map(({ fieldName, checkRules, pattern }) => ({
          fieldName,
          checkRules,
          pattern,
        }))
    : [];

  return getInvalidFields(model, fieldsToCheck);
};

export const getLocationDetailsValidation = (model: any, status) =>
  baseFieldValidation(model, status, 'details');

export const getCompanySelectionValidation = (model: any, status) =>
  baseFieldValidation(model, status, 'company');

export const getOwnerDetailsValidation = (model: any, status) =>
  baseFieldValidation(model, status, 'owner');

export const getDocksValidation = ({ rooms, docks }): IFormErrorInfo[] => {
  if (!rooms || !rooms.length) {
    return [{ concerns: 'rooms-count', validationLevel: ['missing'] }];
  }

  const invalidDocks: IFormErrorInfo[] = [];
  // eslint-disable-next-line prefer-spread
  const docksIds: string[] = [].concat.apply(
    [],
    rooms.map(r => {
      if (!docks[r.id]) {
        // eslint-disable-next-line no-param-reassign
        docks[r.id] = [];
      }

      return docks[r.id].map(s => s.number) ?? [];
    }),
  );
  const nonUniqueIds = docksIds.filter((item, index) => docksIds.indexOf(item) !== index);

  for (let i = 0; i < rooms.length; i += 1) {
    const roomDocks = docks[rooms[i].id];

    if (roomDocks && roomDocks.length) {
      for (let j = 0; j < roomDocks.length; j += 1) {
        const singleDock = roomDocks[j];

        if (nonUniqueIds.includes(singleDock.number)) {
          invalidDocks.push({
            concerns: singleDock.id,
            validationLevel: ['duplicate-number'],
          });
        }
      }
    }
  }
  return invalidDocks;
};

export const countValidator = (list: any[]): IFormErrorInfo[] =>
  list && list.length ? [] : [{ concerns: 'list-count', validationLevel: ['missing'] }];
