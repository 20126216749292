import _ = require('lodash');
import {
  deleteHOInvitation,
  deleteHOUser,
  InvitationDto,
  InvitationStatusEnum,
  Toast,
  ToastHelper,
  ToastType,
  updateHOInvitation,
  UpdateHomeOwnerDto,
  updateHOUser,
} from '@streda/web_components';
import { ILocationEditModalState } from './location-edit-modal.types';
import { sendInvitation } from './location-edit-modal.logic.api';

const onInvitationRemove = (invitation, state, setState) => {
  const { invitations } = state;
  const { id, email } = invitation;
  const removedElements = _.remove(
    invitations,
    (el: InvitationDto) => el.id === id || el.email === email,
  );
  if (removedElements.length) {
    setState({
      ...state,
      invitations,
    });
    Toast.showToast({
      colorType: 'Success',
      message: 'Success! Invitation has been removed',
    });
  } else {
    Toast.showToast({
      colorType: 'Danger',
      message: 'Error during finding invitation. Refresh card to see current invitations.',
    });
  }
};

const onInvitationUpdate = (invitation: UpdateHomeOwnerDto, state, setState) => {
  const { invitations } = state;
  const { oldEmail, newEmail } = invitation;
  const invitationIndex = invitations.findIndex(
    (invitation: InvitationDto) => invitation.email === oldEmail,
  );

  if (invitationIndex !== -1) {
    invitations[invitationIndex].email = newEmail;

    setState({
      ...state,
      invitations,
    });
    Toast.showToast({
      colorType: 'Success',
      message: 'Success! Invitation has been updated',
    });
  } else {
    Toast.showToast({
      colorType: 'Danger',
      message: 'Error during finding invitation. Refresh card to see current invitations.',
    });
  }
};

const onSendInvitationSuccess = (
  invitation,
  state: ILocationEditModalState,
  setState: (newState: ILocationEditModalState) => void,
  resolve: (value?) => void,
) => {
  const newState = {
    ...state,
    invitations: [
      ({
        ...invitation,
        status: 'Pending',
        timestamp: new Date().toISOString(),
      } as unknown) as InvitationDto,
    ],
  };
  setState({
    ...newState,
  });
  Toast.showToast({
    colorType: 'Success',
    message: 'Success! Home Owner has been assigned',
  });
  resolve();
};

const onInviteOwner = (
  email: string,
  state: ILocationEditModalState,
  setState: (newState: ILocationEditModalState) => void,
  locationId?: string,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    // During adding location locationId is not known yet for HO assigning step.
    // Sending invitation performing later after location create when locationId is known
    if (!locationId) {
      return onSendInvitationSuccess({ email }, state, setState, resolve);
    }

    return sendInvitation(locationId, state.company, email)
      .then(result => onSendInvitationSuccess(result.data, state, setState, resolve))
      .catch(error => {
        ToastHelper(
          ToastType.DANGER,
          'Error during invitation adding. Please try again or contact with system administrator',
          error,
        );
        reject(error);
      });
  });
};

export const onInvitationEmailUpdate = (
  updatedInvitation: UpdateHomeOwnerDto,
  invitation: InvitationDto,
  state: ILocationEditModalState,
  setState: (value: (((prevState: ILocationEditModalState) => ILocationEditModalState) | ILocationEditModalState)) => void,
) => {
  if (!updatedInvitation) {
    return Promise.resolve();
  }

  // For location adding when id is not known yet, just update invitation
  if (!state.data.id) {
    onInvitationUpdate(updatedInvitation, state, setState);
    return Promise.resolve();
  }

  const { status } = invitation;
  const invitationOperationHandler =
    status === InvitationStatusEnum.Accepted
      ? updateHOUser.bind(undefined, updatedInvitation.oldEmail, updatedInvitation)
      : updateHOInvitation.bind(undefined, updatedInvitation);

  return invitationOperationHandler().then(() =>
    onInvitationUpdate(updatedInvitation, state, setState),
  );
};

export const onRemoveOwnerInvitation = (
  invitation: InvitationDto,
  state: ILocationEditModalState,
  setState: (newState: ILocationEditModalState) => void,
) => {
  if (_.isEmpty(state.invitations)) {
    return;
  }

  // For location adding when id is not known yet, just remove invitation from list
  if (!state.data.id) {
    onInvitationRemove(invitation, state, setState);
    return;
  }

  const { status, email } = invitation;
  const invitationOperationHandler =
    status === InvitationStatusEnum.Accepted
      ? deleteHOUser.bind(undefined, email)
      : deleteHOInvitation.bind(undefined, invitation, InvitationStatusEnum.Error);

  invitationOperationHandler().then(() => onInvitationRemove(invitation, state, setState));
};
export default onInviteOwner;
