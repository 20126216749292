import * as React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import {
  AccountRoles,
  ActionModal,
  Button,
  ButtonColorType,
  ButtonType,
  CloseIcon,
  CompanyIcon,
  hasUserRole,
  hideLoader,
  IconButton,
  InvitationStatusEnum,
  LabeledInput,
  LabeledSelect,
  ModalLoader,
  RadioInput,
  showLoader,
  SystemUserDto,
} from '@streda/web_components';
import { addUser, getCompanies, getUser, updateUser } from './user-manage.logic';
import { UserRoles } from './user.manage.types';
import {
  EditButton,
  FullWidthButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeaderTitle,
  SectionTitle,
  StyledModal,
} from '../../../layout/AddEditModal';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2)
    .max(50)
    .required(),
  surname: Yup.string()
    .min(2)
    .max(50)
    .required(),
  email: Yup.string()
    .email()
    .required(),
  role: Yup.string().required(),
  companyName: Yup.string().when('role', {
    is: role => hasUserRole(AccountRoles.ADMINISTRATOR) || role === UserRoles.superAdministrator,
    then: Yup.string()
      .nullable()
      .notRequired(),
    otherwise: Yup.string().required(),
  }),
});

const UserManage: React.FC = () => {
  const isSuperAdmin = hasUserRole(AccountRoles.SUPER_ADMINISTRATOR);
  const initialValues: SystemUserDto = {
    name: '',
    surname: '',
    email: '',
    role: undefined,
    companyName: !isSuperAdmin ? null : undefined,
    accountEnabled: undefined,
    invitationStatus: InvitationStatusEnum.Accepted,
  };
  const history = useHistory();
  const { userId } = useParams() as {
    userId?: string;
  };
  const [previewMode, setPreviewMode] = React.useState(!!userId);
  const [companiesList, setCompaniesList] = React.useState([]);
  const [showDeactivateModal, setShowDeactivateModal] = React.useState(false);
  const onUserAdd = (user: SystemUserDto) => addUser(user);
  const onUserEdit = (user: SystemUserDto) => updateUser(user);
  const manageUser = async (values: SystemUserDto, id?: string) => {
    const isSuccess = id ? await onUserEdit(values) : await onUserAdd(values);

    if (isSuccess) {
      history.push('/reload');
      history.replace('/users');
    }
  };
  const onFormSubmit = (values: SystemUserDto) => manageUser(values, userId);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onFormSubmit,
  });
  const onRoleChange = event => {
    const role = event.currentTarget.value;
    if (role === UserRoles.superAdministrator) {
      formik.setFieldValue('companyName', null);
    }

    formik.setFieldValue('role', role);
  };
  const onResendInvitationClick = () => {
    formik.setSubmitting(true);
    manageUser(formik.values);
  };
  const onReactiveAccountClick = () => {
    formik.setFieldValue('accountEnabled', true);
    formik.submitForm();
  };

  React.useEffect(() => {
    formik.validateForm();

    if (isSuperAdmin) {
      getCompanies().then(response => {
        const companiesOptions = response.data.data.map(({ name }) => ({
          value: name,
          label: name,
        }));
        setCompaniesList(companiesOptions);
      });
    }

    if (userId) {
      showLoader(<ModalLoader />);
      getUser(userId)
        .then(response => {
          formik.setValues({
            ...formik.values,
            ...response.data,
          });
        })
        .finally(() => hideLoader());
    }
  }, [userId]);

  return (
    <div>
      <StyledModal title="" show onHide={null}>
        <form onSubmit={formik.handleSubmit}>
          <ModalHeader>
            <ModalHeaderTitle>{userId ? 'User details' : 'Add New User'}</ModalHeaderTitle>
            <IconButton
              type={ButtonType.BUTTON}
              icon={<CloseIcon />}
              tooltipId="modalTooltip"
              tooltipText="Delete"
              onClick={() => history.push('/users')}
            />
          </ModalHeader>
          <ModalBody>
            {previewMode && <EditButton onClick={() => setPreviewMode(false)}>Edit</EditButton>}
            <SectionTitle>1. Details</SectionTitle>
            <LabeledInput
              placeholder="Type name"
              label="First name"
              value={formik.values.name}
              onChange={value => formik.setFieldValue('name', value)}
              disabled={previewMode}
              type="text"
            />
            <LabeledInput
              placeholder="Type surname"
              label="Surname"
              value={formik.values.surname}
              onChange={value => formik.setFieldValue('surname', value)}
              disabled={previewMode}
              type="text"
            />
            <LabeledInput
              placeholder="Type email"
              label="Email"
              value={formik.values.email}
              onChange={value => formik.setFieldValue('email', value)}
              disabled={previewMode}
              type="email"
            />
            {userId && (
              <LabeledInput label="Status" value={formik.values.invitationStatus} disabled />
            )}
            <SectionTitle>2. Choose role</SectionTitle>
            <RadioInput
              name="role"
              label="Installer"
              value={UserRoles.installer}
              onChange={onRoleChange}
              checked={formik.values.role === UserRoles.installer}
              disabled={previewMode}
            />
            <RadioInput
              name="role"
              label="Administrator"
              value={UserRoles.administrator}
              onChange={onRoleChange}
              checked={formik.values.role === UserRoles.administrator}
              disabled={previewMode}
            />
            {isSuperAdmin && (
              <RadioInput
                name="role"
                label="Super Administrator"
                value={UserRoles.superAdministrator}
                onChange={onRoleChange}
                checked={formik.values.role === UserRoles.superAdministrator}
                disabled={previewMode}
              />
            )}
            {isSuperAdmin &&
              formik.values.role &&
              formik.values.role !== UserRoles.superAdministrator && (
                <>
                  <SectionTitle>3. Assign to Company</SectionTitle>
                  <LabeledSelect
                    options={companiesList}
                    value={{ value: formik.values.companyName, label: formik.values.companyName }}
                    label="Company name"
                    onChange={({ value }) => formik.setFieldValue('companyName', value)}
                    disabled={previewMode}
                    optionsListAsBodyPortal
                  />
                </>
              )}
          </ModalBody>
          <ModalFooter>
            {previewMode &&
              formik.values.accountEnabled &&
              formik.values.invitationStatus === InvitationStatusEnum.Accepted && (
                <FullWidthButton
                  type={ButtonType.BUTTON}
                  colorType={ButtonColorType.WHITE}
                  text="Deactivate access"
                  onClick={() => setShowDeactivateModal(true)}
                  pending={formik.isSubmitting}
                />
              )}
            {previewMode && formik.values.invitationStatus === InvitationStatusEnum.Pending && (
              <FullWidthButton
                type={ButtonType.BUTTON}
                colorType={ButtonColorType.WHITE}
                text="Resend invitation"
                onClick={onResendInvitationClick}
                pending={formik.isSubmitting}
              />
            )}
            {previewMode &&
              formik.values.invitationStatus !== InvitationStatusEnum.Pending &&
              !formik.values.accountEnabled && (
                <FullWidthButton
                  type={ButtonType.BUTTON}
                  colorType={ButtonColorType.WHITE}
                  text="Reactivate access"
                  onClick={onReactiveAccountClick}
                  pending={formik.isSubmitting}
                />
              )}
            {!previewMode && (
              <Button
                type={ButtonType.SUBMIT}
                text={userId ? 'Save changes' : 'Send invitation'}
                disabled={!formik.isValid || formik.isSubmitting}
                pending={formik.isSubmitting}
              />
            )}
          </ModalFooter>
        </form>
      </StyledModal>
      <ActionModal
        onHide={() => setShowDeactivateModal(false)}
        onConfirm={() => {
          formik.setFieldValue('accountEnabled', false);
          formik.submitForm();
        }}
        show={showDeactivateModal}
        icon={<CompanyIcon width="84px" height="84px" />}
        title=""
        header="Deactivate user"
        description={
          <>
            Do you want to deactivate user?
            <br />
            <b>
              {formik.values.name} {formik.values.surname}
            </b>
          </>
        }
      />
    </div>
  );
};

export default UserManage;
