import * as React from 'react';
import { saveAs } from 'file-saver';
import {
  Toast,
  LocationStatusEnum,
  IFormStepsDefinition,
  AccountRoles,
  hasUserRole,
  getLocationAssigmentApi,
  DetailsIcon,
  BuilderIcon,
  RoomIcon,
  SnapInIcon,
  SettingsIcon,
  HomeOwnerIcon,
  ToastHelper,
  ToastType,
} from '@streda/web_components';
import * as _ from 'lodash';
import {
  getLocationsApi,
  getLocationEmptyModel,
  getRoomsApi,
  getDocksApi,
} from '../../../../utils/api.utils';
import { getAccount } from '../../../../utils/auth.utils';
import { ILocationEdit } from '../../../../models/location';
import { ILocationEditModalState } from './location-edit-modal.types';
import {
  locationEdit,
  locationAdd,
  uploadLocationSpecs,
  downloadFile,
  sendInvitation,
} from './location-edit-modal.logic.api';
import IBFile from '../../../../models/file';
import { validateStep } from './location-edit-modal.logic.validation';
import { locationStatuses } from '../../../../utils/location.utils';
import { assignInstaller } from '../building-company/installers-selection.logic';

export const getEmptyLocationEditModalState = () => {
  return {
    locationTypes: [],
    roomTypes: [],
    dockTypes: [],
    snapInTypes: [],
    docks: [],
    data: getLocationEmptyModel(),
    rooms: [],
    locationConnections: [],
    systemSettingsAuthorized: false,
    invitations: [],
  };
};

const sendHoInvitations = (state, response) => {
  const locationId = response.data.id;
  const { invitations = [] } = state;

  return new Promise(resolve => {
    invitations
      .reduce((promise, { email }) => {
        return promise.then(() => sendInvitation(locationId, state.company, email));
      }, Promise.resolve())
      .then(() => {
        resolve({ response });
      });
  });
};

const assignInstallers = (state, response) => {
  const { installers } = state;
  const locationId = response.data.id;

  return new Promise(resolve => {
    installers
      .reduce((promise, { activeDirectoryId }) => {
        return promise.then(() => assignInstaller(locationId, activeDirectoryId));
      }, Promise.resolve())
      .then(() => {
        resolve({ response });
      });
  });
};

export const assignLocationToCompany = (locationId, companyId) => {
  return getLocationAssigmentApi(getAccount()).locationAssigmentAssignLocationToCompany({
    locationId,
    companyId,
  });
};

export const onSubmit = async (
  id: string,
  state: ILocationEditModalState,
  onHide: (reload?: boolean, err?: any) => any,
  history: any,
) => {
  if (id) {
    locationEdit(state.data);
    onHide(true);
  } else {
    const { data } = state;
    const { rooms } = data;

    await locationAdd({
      ...state.data,
    })
      .then(response => {
        const locationId = response.data.id;

        return new Promise(resolve => {
          rooms
            // eslint-disable-next-line no-shadow
            .reduce((p, room, index) => {
              return p.then(() => {
                return getRoomsApi(getAccount())
                  .roomAddRoom(locationId, room)
                  .then(roomResponse => {
                    rooms[index].databaseId = roomResponse.data;
                  });
              });
            }, Promise.resolve())
            .then(roomsResponse => {
              resolve({ response, roomsResponse });
            });
        });
      })
      .then(({ response }) => {
        const locationId = response.data.id;

        return new Promise(resolve => {
          rooms
            .map(room => {
              return data.docks[room.id].map(dock => ({ dock, roomId: room.databaseId }));
            })
            // @ts-ignore
            .flat()
            .reduce((p, { roomId, dock }) => {
              return p.then(() => {
                return getDocksApi(getAccount()).dockAddDock(locationId, roomId, dock);
              });
            }, Promise.resolve())
            .then(() => {
              resolve({ response });
            });
        });
      })
      .then(({ response }) => sendHoInvitations(state, response))
      .then(({ response }) => {
        const isSuperAdmin = hasUserRole(AccountRoles.SUPER_ADMINISTRATOR);

        if (!isSuperAdmin) {
          return Promise.resolve({ response });
        }

        const { company } = state;
        const locationId = response.data.id;

        return assignLocationToCompany(locationId, company).then(() =>
          Promise.resolve({ response }),
        );
      })
      .then(({ response }) => assignInstallers(state, response))
      .then(({ response }) => {
        if (state.specFiles) {
          uploadLocationSpecs(
            response.data.id,
            state.specFiles.map(specFile => specFile.file),
          );
        }
        if (history) {
          history.push(`/location/${response.data.id}/configuration/connections`);
        } else {
          Toast.showToast({
            colorType: 'Success',
            message: 'Location has been added to the list',
          });
          onHide(true);
        }
      })
      .catch(error => {
        ToastHelper(
          ToastType.DANGER,
          'Error during adding location. Please try again or contact with system administrator',
          error,
        );
      });
  }
};

export const handleOnSubmit = (
  e: any,
  state: ILocationEditModalState,
  onHide: (reload?: boolean, err?: any) => any,
  history: any,
  locationId?: string,
) => {
  e.preventDefault();
  return onSubmit(locationId, state, onHide, history);
};

export const onChange = (
  newData: ILocationEdit,
  id: string,
  state: ILocationEditModalState,
  setState: (newState: ILocationEditModalState) => any,
  onUpdate: (data: ILocationEdit, err?: any) => any,
  filesChangeHandler?,
) => {
  if (id) {
    locationEdit(
      { ...newData, details: { ...newData.details } },
      state.specFiles,
      ([, filesData]) => {
        Toast.showToast({
          colorType: 'Success',
          message: 'Location has been edited',
        });

        state.data = {
          ...state.data,
          ...newData,
          details: { ...newData.details },
        };

        if (onUpdate) {
          onUpdate(state.data);
        }

        const files = state.specFiles.map(file => {
          delete file.file;

          if (filesData) {
            file.blobId = filesData.shift();
          }

          return { ...file };
        });

        if (filesChangeHandler) {
          filesChangeHandler(files, state, setState);
        }
      },
      () => {
        const oldFiles = state.specFiles.filter(f => !f.file);

        if (filesChangeHandler) {
          filesChangeHandler(oldFiles, state, setState);
        }
      },
    );
  }
  setState({
    ...state,
    data: newData,
  });
};

export const onStatusChange = (
  statusNumber: number,
  locationId: string,
  state: ILocationEditModalState,
  setState: (newState: ILocationEditModalState) => void,
  reloadData: () => void,
) => {
  const newStatusInfo = locationStatuses.find(s => s.number === statusNumber);
  const oldStatusInfo = locationStatuses.find(s => s.name === state.data.details.status);
  if (newStatusInfo && newStatusInfo.number !== oldStatusInfo.number) {
    getLocationsApi(getAccount())
      .locationChangeLocationStatus(locationId, Object.values(LocationStatusEnum)[statusNumber])
      .then(() => {
        const newState = { ...state };
        newState.data.details.status = newStatusInfo.name;

        if (reloadData) {
          reloadData();
        }
        setState(newState);
        Toast.showToast({
          message: 'Status has been changed',
          colorType: 'Success',
        });
      })
      .catch(error => {
        ToastHelper(
          ToastType.DANGER,
          'Error during status change. Please try again or contact with system administrator',
          error,
        );
      });
  }
};

export const getModeTitle = (editMode: boolean) => {
  return editMode ? 'edit' : 'add';
};

export const getStepsDefinition = (
  state: ILocationEditModalState,
  locationId: string,
): IFormStepsDefinition[] => {
  const editMode = !_.isNull(locationId);
  const isSuperAdmin = hasUserRole(AccountRoles.SUPER_ADMINISTRATOR);
  const generalSteps = [
    { pageNumber: 0, title: 'General', icon: <DetailsIcon /> },
    {
      pageNumber: 1,
      disable: !locationId && !validateStep(state),
      title: isSuperAdmin ? 'Building Company' : 'Installers',
      icon: <BuilderIcon />,
      testId: `${getModeTitle(editMode)}_loc_configuration_tab`,
    },
    {
      pageNumber: 2,
      title: 'Rooms',
      disable: !locationId && !validateStep(state),
      icon: <RoomIcon />,
      testId: `${getModeTitle(editMode)}_loc_rooms_tab`,
    },
    {
      pageNumber: 3,
      title: 'Docks & Snap-in',
      disable: !locationId && !validateStep(state),
      icon: <SnapInIcon />,
      testId: `${getModeTitle(editMode)}_loc_docks_snapin_tab`,
    },
    {
      pageNumber: 4,
      title: 'Home Owner',
      disable: !locationId && !validateStep(state),
      icon: <HomeOwnerIcon />,
      testId: `${getModeTitle(editMode)}_loc_homeowners_tab`,
    },
    {
      pageNumber: 5,
      title: 'Configuration',
      disable: !locationId && !validateStep(state),
      icon: <SettingsIcon />,
      testId: `${getModeTitle(editMode)}_loc_company_tab`,
    },
  ];

  return generalSteps;
};

export const getPermanentSections = () => [
  {
    title: 'System Settings',
    icon: <SettingsIcon />,
    pageNumber: 6,
  },
];

export const handleOnStepChange = (
  oldStep: number,
  state: ILocationEditModalState,
  setState: (state: ILocationEditModalState) => void,
) => {
  switch (oldStep) {
    case 0:
      setState({ ...state, showGeneralStepValidationErrors: true });
      break;
    case 1:
      setState({ ...state, showDockValidationErrors: true });
      break;
    default:
      break;
  }
};

export const onFileDownload = (locationId: string, file: IBFile) => {
  if (file.file) {
    saveAs(file.file, file.fileName);
  } else if (locationId && file.blobId) {
    downloadFile(locationId, file.blobId).then(response => {
      saveAs(new Blob([response.data], { type: 'application/octet-stream' }), file.fileName);
    });
  }
};

export const handleFilesChange = (
  newFiles: IBFile[],
  state: ILocationEditModalState,
  setState: (state: ILocationEditModalState) => void,
) => {
  setState({ ...state, specFiles: newFiles });
};

export const handleOnHide = (onHide: () => void) => {
  if (onHide) {
    onHide();
  }
};

export const handleOnConfigurationClick = (
  locationId: string,
  history: any,
  data: ILocationEdit,
) => {
  if (!locationId) {
    locationAdd(data).then(response => {
      history.push(`/location/${response.data.id}/configuration/connections`);
    });
  } else {
    history.push(`/location/${locationId}/configuration/connections`);
  }
};
