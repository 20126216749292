export {
  basePath,
  getBaseConfiguration,
  getScenesApi,
  getLocationsApi,
  getDeviceStatesApi,
  getRoomsApi,
  getDeviceConfigurationApi,
  getDocksApi,
  getSnapinApi,
  getDictionaryApi,
  getConfigurationApi,
  getUserEmptyModel,
  getLocationEmptyModel,
  RequiredError,
  LocationStatusEnum,
} from '@streda/web_components';
