import { Toast, ToastHelper, ToastType } from '@streda/web_components';
import { getLocationsApi } from '../../../../utils/api.utils';
import { getAccount } from '../../../../utils/auth.utils';

export const deleteLocationFile = (
  locationId: string,
  blobId: string,
  successCallback,
): Promise<void> => {
  return getLocationsApi(getAccount())
    .locationDeleteFile(locationId, blobId)
    .then(() => {
      if (successCallback) {
        successCallback();
      }

      Toast.showToast({
        colorType: 'Success',
        message: 'File has been deleted.',
      });
    })
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during removing file. Please try again or contact with system administrator',
        error,
      );
    });
};

export default {
  deleteLocationFile,
};
