import * as React from 'react';
import { Checkbox, ConfirmModal, SnapInIcon } from '@streda/web_components';
import { CheckboxContainer } from './configuration-info-modal.components';

function ConfigurationInfoModal(props) {
  const { onConfirm, show } = props;
  const [doNotShowAgain, setDoNotShowAgain] = React.useState<boolean>(true);

  return (
    <ConfirmModal
      onConfirm={() => {
        onConfirm(doNotShowAgain);
      }}
      show={show}
      icon={<SnapInIcon width="100px" height="100px" />}
      title=""
      header="Devices configuration"
      altButton1Text="Got it"
      description={
        <>
          <p>
            To make location available for installation,
            <br />
            it is necessary to configure connections
            <br />
            between devices (such as switches).
          </p>
          <p>
            Location with no configured connections will be saved in a &quot;Draft&quot; status,
            which means it is not ready for installation.
          </p>
          <CheckboxContainer>
            <Checkbox
              label="Do not show again"
              checked={doNotShowAgain}
              onChange={event => setDoNotShowAgain(event.currentTarget.checked)}
            />
          </CheckboxContainer>
        </>
      }
    />
  );
}

export default ConfigurationInfoModal;
