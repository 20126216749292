import * as React from 'react';
import {
  getInitialDeviceState,
  LocationFullDto,
  unsubscribeForDeviceStates,
  useDeviceState,
  useDeviceStateDispatch,
  useHubConnection,
  useInitialDevicesConfiguration,
} from '@streda/web_components';
import { useParams, Route, useRouteMatch } from 'react-router-dom';
import LocationDashboardTopBar from '../components/LocationDashboardTopBar';
import { initLocation } from './location-dashboard.logic';
import { StyledPage } from './location-dashboard.components';
import LocationRooms from './location-rooms/location-rooms';
import LocationRoom from './location-room/location-room';

function LocationDashboard() {
  const { locationId } = useParams() as { locationId: string | undefined };
  const { url } = useRouteMatch();
  const [location, setLocation] = React.useState<LocationFullDto>();
  const { devicesState, hubConnection } = useDeviceState();
  const dispatch = useDeviceStateDispatch();

  useHubConnection(locationId);
  useInitialDevicesConfiguration(locationId);

  React.useEffect(() => {
    return () => {
      if (locationId && hubConnection) {
        unsubscribeForDeviceStates(hubConnection, locationId);
      }
    };
  }, [locationId, hubConnection]);

  const refreshHandler = () =>
    initLocation(locationId).then(result => {
      setLocation(result);
      return Promise.resolve();
    });

  React.useEffect(() => {
    if (!location) {
      return;
    }

    if (location.rooms) {
      getInitialDeviceState(dispatch, location.rooms, locationId);
    }
  }, [location]);

  React.useEffect(() => {
    refreshHandler();
  }, []);

  if (!location) {
    return null;
  }

  return (
    <div>
      <LocationDashboardTopBar withoutSearch />

      <StyledPage grid>
        <Route exact path={url}>
          <LocationRooms location={location} devicesState={devicesState} />
        </Route>
        <Route path={`${url}/room/:roomId`}>
          <LocationRoom location={location} onRoomChange={refreshHandler} />
        </Route>
      </StyledPage>
    </div>
  );
}

export default LocationDashboard;
