import * as React from 'react';
import { Switch, withRouter, Route } from 'react-router-dom';
import UsersPage from './users-page/users-page';
import NotFoundPage from '../not-found-page';
import UserManage from './user-manage/user-manage';

const UsersApp: React.FC = () => {
  return (
    <Switch>
      <Route path="/users">
        <UsersPage />
        <Route path={['/users/add', '/users/:userId']}>
          <UserManage />
        </Route>
      </Route>
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default withRouter(UsersApp);
