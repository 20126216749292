import * as React from 'react';
import {
  Button,
  ButtonType,
  ISelectOption,
  LabeledSelect,
  usePromise,
  getDictionarySelectOptions,
} from '@streda/web_components';
import _ = require('lodash');
import { IAddRoomProps } from './add-room.types';
import handleOnChange from './add-room.logic';
import { getModeTitle } from '../../../location-edit-modal/location-edit-modal.logic';

const AddRoom: React.FC<IAddRoomProps> = (props: IAddRoomProps) => {
  const { className, rooms, onChange, location } = props;
  const [state, setState] = React.useState({ value: '', label: '' });
  const getDictSelectOptionsPromise: Promise<ISelectOption[]> = React.useMemo(
    () => getDictionarySelectOptions('RoomType'),
    [],
  );
  const [options] = usePromise(getDictSelectOptionsPromise, []);
  const editMode = _.isNull(location.id);

  return (
    <div className={className}>
      <div className="h2-medium">Add new room</div>
      <div className="inline-flex-container m-t-20 justify-content-space-between align-bottom w-100p">
        <LabeledSelect
          label="Type of room"
          labelClassName="color-d3"
          className="w-100p"
          placeholder="Select room type..."
          id="add-room-filter"
          value={state}
          options={options}
          onChange={value => setState(value)}
        />
        <Button
          className="m-l-12"
          type={ButtonType.BUTTON}
          text="Add"
          disabled={!state.value}
          onClick={() => handleOnChange(state, setState, rooms, location.id, onChange)}
          testId={`${getModeTitle(editMode)}_loc_room_add_button`}
        />
      </div>
    </div>
  );
};

export default AddRoom;
