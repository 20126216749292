import { IAddDockState } from './add-dock.types';
import { ILocationDock } from '../../../../../models/location';


export const handleOnAdd = (
  state: IAddDockState,
  setState: (state: IAddDockState) => void,
  onAddDock: (dock: ILocationDock) => void,
) => {
  if (onAddDock) {
    const newDock: ILocationDock = {
      number: state.dockNumber,
      dockCode: state.dockCode,
      positionId: state.snapInPosition.value,
      cableType: state.cableType,
      name: state.name,
    };
    onAddDock(newDock);
    setState({
      ...state,
      dockNumber: '',
      dockCode: '',
      name: '',
      snapInPosition: { value: '', label: '' },
    });
  }
};

export const isDockIdAvailable = (dockId, docks) =>
  !Object.values(docks)
    // @ts-ignore
    .flat()
    .some(({ number }) => number === dockId);

export const isDockNumberValid = (state, docks) => {
  // e.g. 213-56-8, 123.4, abc_1
  const validDockNumberRegExp = /^([a-zA-Z0-9]|([a-zA-Z0-9]*[_\-.][a-zA-Z0-9]))+$/i;
  const { dockNumber } = state;

  return (
    dockNumber &&
    validDockNumberRegExp.test(dockNumber) &&
    dockNumber.length <= 30 &&
    isDockIdAvailable(dockNumber, docks)
  );
};

export default {
  isDockIdAvailable,
  handleOnAdd,
  isDockNumberValid,
};
