import { NetworkStatusType } from '../../../shared/status/network-status-indicator/network-status-indicator.types';

interface IBcbStatusesDict {
  [key: string]: NetworkStatusType;
}

export const bcbStatusesDict: IBcbStatusesDict = {
  Unknown: 'unknown',
  AllConnected: 'active',
  PartiallyConnected: 'partially-inactive',
  AllDisconnected: 'inactive',
  Error: 'error',
};

export const getNetworkStatus = (status: string) => {
  return bcbStatusesDict[status] ?? bcbStatusesDict.Unknown;
};
