import * as React from 'react';
import { IRoomsSectionProps } from './rooms-sections.types';
import RoomsList from '../rooms-list/rooms-list';
import { onRoomsChange } from './rooms-section.logic';
import AddRoom from '../add-room/add-room';

const RoomsSection: React.FC<IRoomsSectionProps> = (props: IRoomsSectionProps) => {
  const { className, locationModel, onModelChange, addMode, rooms, location } = props;

  return (
    <div className={className}>
      {addMode && (
        <AddRoom
          className="w-100p m-b-52"
          rooms={rooms}
          onChange={changedRooms => onRoomsChange(changedRooms, locationModel, onModelChange)}
          location={location}
        />
      )}
      <RoomsList
        className="w-100p"
        rooms={rooms}
        onChange={changedRooms => onRoomsChange(changedRooms, locationModel, onModelChange)}
        location={location}
        editable={addMode}
      />
    </div>
  );
};

export default RoomsSection;
