import { DashboardRooms } from '@streda/web_components';
import * as React from 'react';
import { SectionLabel } from './location-rooms.components';
import { ILocationRooms } from './location-rooms.types';

const LocationRooms: React.FC<ILocationRooms> = ({ location, devicesState }) => {
  return (
    <>
      <SectionLabel>Rooms:</SectionLabel>
      <DashboardRooms rooms={location.rooms} devicesStates={devicesState} />
    </>
  );
}

export default LocationRooms;
