import { IEventInfo } from '../../../shared/events/event-info/event-info.types';

export const installationStatusesFlow = ['Pending', 'Install', 'Operational', 'Stopped'];

export interface IInstallationStatusProps {
  className?: string;
  lastUpdate?: IEventInfo;
  status?: number;
  location?;
  onStatusChange(statusNumber: number): void;
}
