import { ILocationDock } from '../../../../models/location';

const handleOnDocksInRoomChange = (
  newDocks: ILocationDock[],
  docks,
  roomId: string,
  onChange: (docks) => void,
) => {
  if (newDocks && docks && roomId) {
    // eslint-disable-next-line no-param-reassign
    docks[roomId] = newDocks;
    onChange(docks);
  }
};

export default handleOnDocksInRoomChange;
