import * as React from 'react';
import { ModalLoader, StredaLogo } from '@streda/web_components';

export const CoverLoader = () => {
  return (
    <div className="cover-loader">
      <StredaLogo />
      <ModalLoader />
    </div>
  );
};

export default {
  CoverLoader,
};
