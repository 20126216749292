import * as React from 'react';
import {
  Dropdown,
  Button,
  ButtonType,
  ArrowDownIcon,
  variants,
  SnapInsGroupsEnums,
} from '@streda/web_components';
import styled from 'styled-components';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
  DockCableTypeEnum,
  ICableTypeDropdown,
  IContentElement,
  IToggleElement,
} from './cable-type-dropdown.types';

const Placeholder = styled.div`
  color: ${({ theme }) => theme.colors.font1}60;
`;

const ToggleContainer = styled.div<{
  inlineMode: boolean;
}>`
  display: flex;
  padding: ${({ inlineMode }) => (inlineMode ? 0 : '8px')};
  border: 0.75px solid
    ${({ theme, inlineMode }) => (inlineMode ? 'transparent' : theme.colors.primary)};
  color: ${({ theme }) => theme.colors.primary};
  margin: 0;
  height: 36px;
  text-align: left;
  justify-content: space-between;
  align-items: center;
`;

const DockCableTranslations = {
  [DockCableTypeEnum.Electric]: 'E-only',
  [DockCableTypeEnum.Hybrid]: 'Hybrid',
  [DockCableTypeEnum.Dockless]: 'Dockless',
};

const StyledButton = styled(Button)<{ compatibilityWarning?: boolean }>`
  border-color: ${({ theme, compatibilityWarning }) =>
    compatibilityWarning ? theme.colors.alert : undefined} !important;
  color: ${({ theme, compatibilityWarning }) =>
    compatibilityWarning ? theme.colors.alert : undefined} !important;
  min-width: unset;
`;

const CableOptionButton = styled(StyledButton)`
  width: 100%;
  margin-bottom: 8px;
`;

export const getDockCableTranslationBySymbol = (dockCableSymbol: DockCableTypeEnum): string =>
  DockCableTranslations[dockCableSymbol] || '';

const DropdownWrapper = styled.div`
  margin-right: 8px;
`;

const Toggle: React.FC<IToggleElement> = ({
  cableType,
  inlineMode,
  compatibilityWarning,
  disabled,
}) => {
  return (
    <ToggleContainer inlineMode={inlineMode}>
      {inlineMode ? (
        <StyledButton
          rounded
          text={DockCableTranslations[cableType] || ''}
          type={ButtonType.BUTTON}
          colorType={variants.WHITE}
          compatibilityWarning={compatibilityWarning}
          disabled={disabled}
        />
      ) : (
        DockCableTranslations[cableType] || <Placeholder>Cable type</Placeholder>
      )}

      {!inlineMode && <ArrowDownIcon />}
    </ToggleContainer>
  );
};

const Content: React.FC<IContentElement> = ({
  cableType,
  onCableChange,
  setShowDropdown,
  selectedDock,
}) => {
  const onChange = (cableType: DockCableTypeEnum) => {
    onCableChange(cableType);
    setShowDropdown(false);
  };

  const getCableTypeOptions = () => {
    const dockType = selectedDock.charAt(2);

    if (dockType === SnapInsGroupsEnums['0']) {
      return (
        <CableOptionButton
          rounded
          text={DockCableTranslations[DockCableTypeEnum.Dockless]}
          type={ButtonType.BUTTON}
          colorType={cableType === DockCableTypeEnum.Dockless ? variants.PRIMARY : variants.WHITE}
          onClick={() => onChange(DockCableTypeEnum.Dockless)}
        />
      );
    }
    return (
      <>
        <CableOptionButton
          rounded
          text={DockCableTranslations[DockCableTypeEnum.Electric]}
          type={ButtonType.BUTTON}
          colorType={cableType === DockCableTypeEnum.Electric ? variants.PRIMARY : variants.WHITE}
          onClick={() => onChange(DockCableTypeEnum.Electric)}
        />
        <CableOptionButton
          rounded
          text={DockCableTranslations[DockCableTypeEnum.Hybrid]}
          type={ButtonType.BUTTON}
          colorType={cableType === DockCableTypeEnum.Hybrid ? variants.PRIMARY : variants.WHITE}
          onClick={() => onChange(DockCableTypeEnum.Hybrid)}
        />
      </>
    );
  };

  return <div>{getCableTypeOptions()}</div>;
};

const CableTypeDropdown: React.FC<ICableTypeDropdown> = ({
  cableType,
  onCableChange,
  inlineMode,
  compatibilityWarning,
  disabled,
  selectedDock,
}) => {
  const [showDropdown, setShowDropdown] = React.useState(false);
  return (
    <DropdownWrapper>
      <Dropdown
        disabled={disabled}
        onToggle={() => {
          setShowDropdown(!showDropdown);
        }}
        show={showDropdown}
        toggleElement={
          <Toggle
            cableType={cableType}
            inlineMode={inlineMode}
            compatibilityWarning={compatibilityWarning}
            disabled={disabled}
          />
        }
        contentElement={
          <Content
            cableType={cableType}
            selectedDock={selectedDock}
            onCableChange={onCableChange}
            setShowDropdown={setShowDropdown}
          />
        }
      />
    </DropdownWrapper>
  );
};

export default CableTypeDropdown;
