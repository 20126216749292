import { Toast, ToastHelper, ToastType } from '@streda/web_components';
import { getScenesApi } from '../../../../../../utils/api.utils';
import { getAccount } from '../../../../../../utils/auth.utils';

export const getScenes = locationId => {
  return getScenesApi(getAccount())
    .sceneGetScenesForLocation(locationId)
    .then(result =>
      // TODO temporary fix, on BE should be standardised naming convention, bug ISO001-1462
      result.data.map(scene => ({
        ...scene,
        actions: scene.actions.map(action => {
          const { actionParameters } = action;

          if (actionParameters.switchLedAction) {
            actionParameters.SwitchLedAction = actionParameters.switchLedAction;
            delete actionParameters.switchLedAction;
          }

          if (actionParameters.color) {
            actionParameters.Color = actionParameters.color;
            delete actionParameters.color;
          }

          if (actionParameters.switchAction) {
            actionParameters.SwitchAction = actionParameters.switchAction;
            delete actionParameters.switchAction;
          }

          if (Number.isInteger(actionParameters.soundNumber)) {
            actionParameters.SoundNumber = actionParameters.soundNumber;
            delete actionParameters.soundNumber;
          }

          return { ...action, actionParameters };
        }),
      })),
    )
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during getting scenes. Please try again or contact with system administrator',
        error,
      );

      return error;
    });
};

export const invokeScene = (locationId, sceneId) => {
  return getScenesApi(getAccount())
    .sceneInvokeScenes(locationId, [sceneId])
    .then(result => {
      Toast.showToast({
        message: 'Success! Scene has been invoked',
        colorType: 'Success',
      });

      return result.data;
    })
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during invoking scene. Please try again or contact with system administrator',
        error,
      );

      return error;
    });
};

export const deleteScene = (sceneId, version, locationId) => {
  return getScenesApi(getAccount())
    .sceneDeleteScene(locationId, sceneId, version, true)
    .then(() => {
      Toast.showToast({
        message: 'Success! Scene has been deleted',
        colorType: 'Success',
      });

      return sceneId;
    })
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during deleting scene. Please try again or contact with system administrator',
        error,
      );

      return error;
    });
};

export default {
  getScenes,
};
