import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { DeviceConfigurationsProvider, DeviceStateProvider } from '@streda/web_components';
import Analytics from '../analytics/analytics';
import ConfigurationPage from './configuration/configuration';
import LocationDashboard from './location-dashboard/location-dashboard';

function LocationApp() {
  return (
    <Switch>
      <Route path="/location/:locationId/configuration">
        <ConfigurationPage />
      </Route>
      <Route path="/location/:locationId/analytics/:roomId?">
        <Analytics />
      </Route>
      <Route path="/location/:locationId">
        <DeviceStateProvider>
          <DeviceConfigurationsProvider>
            <LocationDashboard />
          </DeviceConfigurationsProvider>
        </DeviceStateProvider>
      </Route>
    </Switch>
  );
}

export default LocationApp;
