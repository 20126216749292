import { ISideBarState } from './sidebar.types';
import { hiddenOn, collapsedOn } from '../../../dictionaries/sidebar-pathnames';

export const comparePathNames = (pathname1: string, pathname2: string): boolean => {
  const pathnameArray1 = pathname1.split('/');
  const pathnameArray2 = pathname2.split('/');
  if (pathnameArray1.length !== pathnameArray2.length) {
    return false;
  }
  for (let i = 0; i < pathnameArray1.length; i += 1) {
    if (
      !pathnameArray1[i].startsWith(':') &&
      !pathnameArray2[i].startsWith(':') &&
      pathnameArray1[i] !== pathnameArray2[i]
    ) {
      return false;
    }
  }
  return true;
};

export const checkPathname = (checkArray: string[], pathname: string) => {
  for (let i = 0; i < checkArray.length; i += 1) {
    if (comparePathNames(checkArray[i], pathname)) {
      return true;
    }
  }
  return false;
};

const updateSidebar = (
  currentPathname: string,
  state: ISideBarState,
  setState: (newState: ISideBarState) => void,
) => {
  const isCollapsed = checkPathname(collapsedOn, currentPathname);
  const isHidden = checkPathname(hiddenOn, currentPathname);
  if (isCollapsed || isHidden) {
    setState({ ...state, onSpecialPath: true, isCollapsed, isHidden });
  } else if (state.onSpecialPath) {
    setState({ ...state, isCollapsed: false, isHidden: false, onSpecialPath: false });
  }
};

export default updateSidebar;
