import * as React from 'react';
import { EditableListElement } from '@streda/web_components';
import { IRoomsListProps } from './rooms-list.types';
import { handleOnElementTextChange, handleOnElementDelete } from './rooms-list.logic';

const RoomsList: React.FC<IRoomsListProps> = (props: IRoomsListProps) => {
  const { className, rooms, onChange, location, editable = true } = props;
  return (
    <div className={className}>
      <div className="w-100p">
        <div className="h3-medium m-b-24">Rooms list ({rooms.length})</div>
        {rooms && rooms.length
          ? rooms.map((room, index) => {
              return (
                <React.Fragment key={room.id}>
                  {index ? <div className="horizontal-divider" /> : null}
                  <EditableListElement
                    id={`editable-list-element-${index}`}
                    className="h-36"
                    text={room.name}
                    onTextChange={newText =>
                      handleOnElementTextChange(rooms, room.id, location.id, newText, onChange)
                    }
                    onDelete={() => handleOnElementDelete(rooms, room.id, location.id, onChange)}
                    editable={editable}
                    removable={editable}
                  />
                </React.Fragment>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default RoomsList;
