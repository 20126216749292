/* eslint-disable import/prefer-default-export */
/* eslint-disable no-console */
/* eslint-disable no-undef */
import { AccountInfo, EventType, InteractionStatus } from '@azure/msal-browser';
import { useMsal, IMsalContext } from '@azure/msal-react';
import _ = require('lodash');
import React = require('react');
import {
  AccountRoles,
  getAdminAuthApi,
  getRoleFromToken,
  processTokenResult,
  logout as msalLogout,
} from '@streda/web_components';
import {
  LoginErrorsStatuses,
  LoginErrorsCodes,
  LoginErrorsMessages,
} from '../../components/pages/login-page/login-page.types';

function isAllowedRole(roleName: string): boolean {
  switch (roleName) {
    case AccountRoles.ADMINISTRATOR:
    case AccountRoles.SUPER_ADMINISTRATOR:
      return true;
    default:
      return false;
  }
}

export const useAuth = (
  onSuccessCallback?: () => void,
  onErrorCallback?: (response: any) => void,
): {
  login: () => void;
  logout: () => void;
  accessToken: string;
  inProgress?: boolean;
  currAccount?: AccountInfo;
} => {
  const [accessToken, _setAccessToken] = React.useState('');
  const [authInProgress, setAuthInProgress] = React.useState(false);
  document.cookie = 'msal.interaction.status=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
  const msalContext: IMsalContext = useMsal();
  const { instance, accounts, inProgress } = msalContext;

  const login = () => {
    setAuthInProgress(true);
    instance.loginRedirect();
  };

  const logout = () =>
    msalLogout(
      msalContext,
      `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${process.env.REACT_APP_ADB2C_POST_LOGOUT_REDIRECT_URI}`,
    );

  const onAuthLoginSuccess = (idToken: string) => {
    setAuthInProgress(true);
    getAdminAuthApi()
      .userAuthLoginWithExternalToken(idToken)
      .then(result => {
        const { token } = result.data;
        const roleValue = getRoleFromToken(token);

        if (!roleValue.some(r => isAllowedRole(r))) {
          logout();
          setAuthInProgress(false);
          throw Object({ message: 'Insufficient Privileges' });
        } else {
          processTokenResult(result.data.userId, result, onSuccessCallback);
          setAuthInProgress(false);
        }
      })
      .catch(({ code, response = {}, message }) => {
        setAuthInProgress(false);
        const { status } = response;
        if (LoginErrorsStatuses[status]) {
          onErrorCallback(LoginErrorsStatuses[status]);
        } else if (LoginErrorsCodes[code]) {
          onErrorCallback(LoginErrorsCodes[code]);
        } else if (LoginErrorsMessages[message]) {
          onErrorCallback(LoginErrorsMessages[message]);
        } else {
          onErrorCallback(
            'Error during logging in. Please try again or contact with system administrator.',
          );
        }
      });
  };

  React.useEffect(() => {
    const callbackId = instance.addEventCallback(event => {
      if (
        event?.eventType === EventType.LOGIN_SUCCESS ||
        event?.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ) {
        setAuthInProgress(true);
        if (event?.payload?.idToken) {
          onAuthLoginSuccess(event?.payload?.idToken);
        } else {
          setAuthInProgress(false);
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  React.useEffect(() => {
    setAuthInProgress(false);
  }, []);

  const isInProgress = (): boolean => {
    return authInProgress || (!_.isEmpty(accounts) && inProgress !== InteractionStatus.None);
  };

  return {
    login,
    logout,
    accessToken,
    inProgress: isInProgress(),
  };
};
