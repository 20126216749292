import * as React from 'react';
import { Scene, AdvancedConnectionsPanel } from '@streda/web_components';
import { getScenes } from '../../scenes/scenes-list/scenes-list.logic.api';

function AdvancedConnections(props) {
  const { rooms, docks, deviceConnectionsState, locationId, location } = props;
  const [scenes, setScenes] = React.useState([] as Scene[]);
  const [deviceConnections, setDeviceConnections] = deviceConnectionsState;

  React.useEffect(() => {
    getScenes(locationId).then(scenesList => setScenes(scenesList));
  }, []);

  return (
    <div>
      <div className="configuration-panel-top-bar-mock" />
      <div id="DevicesConfiguration" className="configuration-panel-wrapper">
        <AdvancedConnectionsPanel
          rooms={rooms}
          docks={docks}
          scenes={scenes}
          updateConnectionsHandler={newConnections => {
            setDeviceConnections(newConnections);
          }}
          deviceConnections={deviceConnections}
          location={location}
          previewMode={false}
        />
      </div>
    </div>
  );
}

export default AdvancedConnections;
