import * as React from 'react';
import { formatFullDate } from '../../../../utils/date-formater';
import { IEventInfoProps } from './event-info.types';

const EventInfo: React.FC<IEventInfoProps> = (props: IEventInfoProps) => {
  const { eventInfo, className } = props;
  return (
    <div className={`ei-text ${className}`}>
      {`Last activity: ${formatFullDate(eventInfo.lastUpdate)}`}
      {eventInfo.user && (
        <>
          {' by'}
          <div className="ei-user-text"> {eventInfo.user}</div>
        </>
      )}
      {eventInfo.status && (
        <>
          {' | status:'}
          <div className="ei-user-text"> {eventInfo.status}</div>
        </>
      )}
    </div>
  );
};

export default EventInfo;
