import { Toast, ToastHelper, ToastType } from '@streda/web_components';
import { getLocationsApi } from '../../../../utils/api.utils';
import { getAccount } from '../../../../utils/auth.utils';

const onDelete: any = (id: string, onHide?: (reload?: boolean, error?: any) => any) => {
  getLocationsApi(getAccount())
    .locationDeleteLocation(id)
    .then(result => {
      if (result) {
        Toast.showToast({
          message: 'Location has been deleted',
          colorType: 'Success',
        });
      }
      if (onHide) {
        onHide(true);
      }
    })
    .catch(error => {
      if (onHide) {
        onHide(false, error);
      }

      ToastHelper(ToastType.DANGER, 'An error occurred while deleting the location', error);
    });
};

export default onDelete;
