import { GridFullRow } from '@streda/web_components';
import styled from 'styled-components';

export const SectionLabel = styled(GridFullRow)`
  color: ${({ theme }) => theme.colors.font1};
  font-size: 16px;
  margin-top: 16px;
`;

export default {
  SectionLabel,
};
