import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CompanyDto } from '@streda/web_components';
import CompanyEditModal from '../company-edit-modal/company-edit-modal';
import { getCompany } from '../company-edit-modal/company-edit-modal.logic';

const CompanyManage: React.FC = () => {
  const history = useHistory();
  const { companyId } = useParams() as {
    companyId?: string;
  };
  const [companyDetails, setCompanyDetails] = React.useState<CompanyDto>();
  React.useEffect(() => {
    if (companyId) {
      getCompany(companyId).then(response => {
        setCompanyDetails(response.data);
      });
    }
  }, [companyId]);

  if (companyId && !companyDetails) {
    return null;
  }

  return (
    <CompanyEditModal
      companyDetails={companyDetails}
      onSaveSuccess={() => {
        history.push('/');
        history.replace('/companies');
      }}
      onClose={() => {
        history.push('/companies');
      }}
    />
  );
};

export default CompanyManage;
