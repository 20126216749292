import * as React from 'react';
import { Button, Input, Select } from '@streda/web_components';
import styled from 'styled-components';
import { flexBasis, FlexBasisProps } from 'styled-system';
import { IAddDockProps } from './add-dock.types';
import { handleOnAdd, isDockNumberValid } from './add-dock.logic';
import { getModeTitle } from '../../location-edit-modal/location-edit-modal.logic';
import CableTypeDropdown from '../cable-type-dropdown/cable-type-dropdown';
import DockDropdown from '../dock-dropdown/dock-dropdown';

const StyledFlexItem = styled.div<FlexBasisProps>`
  ${flexBasis}
`;

export const StyledPositionSelect = styled(Select)`
  input {
    min-width: unset;
  }
`;

const StyledAddButton = styled(Button)`
  button {
    min-width: unset;
  }
`;

const AddDock: React.FC<IAddDockProps> = (props: IAddDockProps) => {
  const { className, onAdd, snapInTypes, snapInPositions, docks, editMode } = props;
  const dockState = React.useState({
    dockCode: '',
    dockName: '',
    dockNumber: '',
    snapInPosition: { value: '', label: '', group: '' },
    cableType: null,
    name: '',
  });
  const [dock, setDock] = dockState;
  const { dockCode, cableType, dockNumber, snapInPosition } = dock;
  const onDockUpdate = state => {
    setDock(state);
  };

  return (
    <div className={className}>
      <div className="inline-flex-container w-100p">
        <StyledFlexItem flexBasis="40%">
          <DockDropdown
            snapInTypes={snapInTypes}
            dock={dock}
            onSnapInSelect={dockDetails => {
              onDockUpdate({
                ...dock,
                ...dockDetails,
              });
            }}
          />
        </StyledFlexItem>
        <StyledFlexItem flexBasis="20%">
          <CableTypeDropdown
            disabled={!dockCode}
            cableType={cableType}
            selectedDock={dockCode}
            onCableChange={cableType =>
              onDockUpdate({
                ...dock,
                cableType,
              })
            }
          />
        </StyledFlexItem>
        <StyledFlexItem flexBasis="15%">
          <Input
            className="m-r-12"
            placeholder="Dock ID"
            value={dockNumber}
            onChange={value => onDockUpdate({ ...dock, dockNumber: value.trim() })}
            testId={`${getModeTitle(editMode)}_loc_dock_id_input`}
          />
        </StyledFlexItem>
        <StyledFlexItem flexBasis="15%">
          <StyledPositionSelect
            className="m-r-12 flex-shrink-0"
            options={snapInPositions}
            placeholder="Position specify"
            value={snapInPosition}
            onChange={value => onDockUpdate({ ...dock, snapInPosition: value })}
            testId={`${getModeTitle(editMode)}_loc_dock_position_input`}
            maxDropdownHeight={200}
          />
        </StyledFlexItem>
        <StyledFlexItem flexBasis="10%">
          <StyledAddButton
            disabled={
              !dockCode || !cableType || !snapInPosition.value || !isDockNumberValid(dock, docks)
            }
            className="m-0"
            type="button"
            text="Add"
            onClick={() => handleOnAdd(dock, onDockUpdate, onAdd)}
            testId={`${getModeTitle(editMode)}_loc_add_snapin_button`}
          />
        </StyledFlexItem>
      </div>
    </div>
  );
};

export default AddDock;
