import { DashboardDocks } from '@streda/web_components';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { SectionLabel } from '../location-rooms/location-rooms.components';
import { ILocationRoom } from './location-room.types';

const LocationRoom: React.FC<ILocationRoom> = ({ location, onRoomChange }) => {
  const { roomId } = useParams() as { roomId: string };
  const room = location.rooms.find(room => room.id === roomId);

  if (!room.docks) {
    return null;
  }

  return (
    <>
      <SectionLabel>Devices:</SectionLabel>
      <DashboardDocks
        room={room}
        locationId={location.id}
        locationDetails={location}
        supportSnapInReplace={false}
        onDataChange={onRoomChange}
      />
    </>
  );
};

export default LocationRoom;
