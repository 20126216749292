import { Toast, DockDto, ToastHelper, ToastType } from '@streda/web_components';
import { ILocationDock } from '../../../../models/location';
import { getDocksApi, getSnapinApi } from '../../../../utils/api.utils';
import { getAccount } from '../../../../utils/auth.utils';

export const handleOnAddDock = (
  newDock: ILocationDock,
  docks: ILocationDock[],
  locationId: string,
  roomId: string,
  onChange: (docks: DockDto[]) => void,
) => {
  if (onChange && newDock) {
    if (locationId) {
      getDocksApi(getAccount())
        .dockAddDock(locationId, roomId, newDock)
        .then(dock => {
          onChange(docks ? [...docks, dock.data] : [dock.data]);
          Toast.showToast({
            message: 'Dock has been added.',
            colorType: 'Success',
          });
        })
        .catch(error => {
          ToastHelper(
            ToastType.DANGER,
            'Error during adding dock. Please try again or contact with system administrator',
            error,
          );
        });
    } else {
      onChange(docks ? [newDock, ...docks] : [newDock]);
    }
  }
};

export const handleOnDockDelete: (
  dock: DockDto,
  docks: ILocationDock[],
  locationId: string,
  onChange: (docks: ILocationDock[]) => void,
) => Promise<any> = (dock, docks, locationId: string, onChange) => {
  if (!onChange || !dock) {
    return Promise.resolve();
  }

  if (!locationId) {
    onChange(docks.length ? docks.filter(s => s !== dock) : []);
    return Promise.resolve();
  }

  const getSnapInPromise = dock.snapInId
    ? getSnapinApi(getAccount()).snapInGetSnapInById(dock.snapInId)
    : Promise.resolve(null);

  return getSnapInPromise
    .then(response => {
      return response
        ? getSnapinApi(getAccount()).snapInDeleteSnapInFromLocation(
            locationId,
            response.data.serialNumber,
          )
        : Promise.resolve(null);
    })
    .then(() => {
      return getDocksApi(getAccount()).dockRemoveDock(locationId, dock.number);
    })
    .then(() => {
      onChange(docks.length ? docks.filter(s => s !== dock) : []);
      Toast.showToast({
        message: 'Dock has been deleted.',
        colorType: 'Success',
      });
      return Promise.resolve();
    })
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during deleting dock. Please try again or contact with system administrator',
        error,
      );
    });
};

const getUpdatedDocks = (docks, updatedDock) => {
  const dockIndex = docks.findIndex(dock => dock.number === updatedDock.number);
  docks[dockIndex] = updatedDock;
  return [...docks];
};

export const onDockNumberChange = (
  oldDockNumber: string,
  newDockNumber: string,
  locationId: string,
  docks: ILocationDock[],
  onChange: (docks: ILocationDock[]) => void,
) => {
  const oldDockIndex = docks.findIndex(dock => dock.number === oldDockNumber);

  if (!locationId) {
    docks[oldDockIndex].number = newDockNumber;
    onChange(docks);
    return Promise.resolve();
  }

  return getDocksApi(getAccount())
    .dockUpdateDockPUT(locationId, {
      oldDockId: oldDockNumber,
      newDockId: newDockNumber,
    })
    .then(() => {
      docks[oldDockIndex].number = newDockNumber;
      onChange(docks);
      Toast.showToast({
        message: 'Dock number has been updated.',
        colorType: 'Success',
      });
      return Promise.resolve();
    })
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during saving dock number. Please try again or contact with system administrator',
        error,
      );
      return Promise.reject(error);
    });
};

export const handleOnDockChange = (
  updatedDock,
  docks: ILocationDock[],
  locationId: string,
  onChange: (docks: ILocationDock[]) => void,
) => {
  if (locationId) {
    return getDocksApi(getAccount())
      .dockUpdateDockPOST(locationId, updatedDock)
      .then(() => {
        onChange(getUpdatedDocks(docks, updatedDock));
        Toast.showToast({
          message: 'Dock has been updated.',
          colorType: 'Success',
        });
        return Promise.resolve();
      })
      .catch(error => {
        ToastHelper(
          ToastType.DANGER,
          'Error during saving dock. Please try again or contact with system administrator',
          error,
        );
        return Promise.reject(error);
      });
  }

  onChange(getUpdatedDocks(docks, updatedDock));
  return Promise.resolve();
};

export const onDockOrderChange = (locationId, roomId, indexFrom, indexTo) =>
  getDocksApi(getAccount())
    .dockChangeOrder(locationId, roomId, {
      indexFrom,
      indexTo,
    })
    .then(() => {
      Toast.showToast({
        message: 'Dock order has been changed.',
        colorType: 'Success',
      });
    })
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during changing dock order. Please try again or contact with system administrator',
        error,
      );
      return Promise.reject(error);
    });
