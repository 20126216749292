import { ILocationEditModalState } from './location-edit-modal.types';
import { ILocationRoom } from '../../../../models/location';

export const handleOnRoomsChange = (
  rooms: ILocationRoom[],
  state: ILocationEditModalState,
  setState: (newState: ILocationEditModalState) => void,
) => {
  if (rooms) {
    state.data.rooms = rooms;
    setState({ ...state });
  }
};

export const handleOnDocksChange = (
  docks: ILocationRoom[],
  state: ILocationEditModalState,
  setState: (newState: ILocationEditModalState) => void,
) => {
  if (docks) {
    state.data.docks = docks;
    setState({ ...state });
  }
};

export default {
  handleOnRoomsChange,
  handleOnDocksChange,
};
