// NOSONAR:
import {
  getRandomKey,
  LocationNewDto,
  LocationDto,
  RoomNewDto,
  RoomDto,
  ConnectionConfigurationDto,
  DocksMap,
  SnapIn,
} from '@streda/web_components';
import { ILocationEdit, ILocationRoom } from '../models/location';
import IBFile from '../models/file';

export enum LocationFieldRulesEnum {
  MISSING = 'missing',
  INVALID = 'invalid',
}

export interface ILocationFields {
  dataPath: string;
  fieldName: string;
  section: string;
  checkRules?: LocationFieldRulesEnum[];
  pattern?: RegExp;
}

const detailsRequiredField = {
  section: 'details',
  checkRules: [LocationFieldRulesEnum.MISSING],
};
const draftFields: ILocationFields[] = [
  {
    dataPath: 'details.typeId',
    fieldName: 'typeId',
    ...detailsRequiredField,
  },
  {
    dataPath: 'details.contactDetailsEmail',
    fieldName: 'contactDetailsEmail',
    section: 'details',
    checkRules: [LocationFieldRulesEnum.INVALID],
    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  },
  {
    dataPath: 'details.contactDetailsPhoneNumber',
    fieldName: 'contactDetailsPhoneNumber',
    section: 'details',
    checkRules: [LocationFieldRulesEnum.INVALID],
    pattern: /^\+\d{2}\s\d{3}(-?\d{3}){2}$/,
  },
  {
    dataPath: 'details.addressCity',
    fieldName: 'addressCity',
    ...detailsRequiredField,
  },
  {
    dataPath: 'details.addressPostalCode',
    fieldName: 'addressPostalCode',
    ...detailsRequiredField,
  },
  {
    dataPath: 'details.name',
    fieldName: 'name',
    ...detailsRequiredField,
  },
  {
    dataPath: 'details.lotNumber',
    fieldName: 'lotNumber',
    ...detailsRequiredField,
  },
  {
    dataPath: 'details.addressHouseNumber',
    fieldName: 'addressHouseNumber',
    ...detailsRequiredField,
  },
  {
    dataPath: 'details.addressStreet',
    fieldName: 'addressStreet',
    ...detailsRequiredField,
  },
];
const pendingFields: ILocationFields[] = [
  ...draftFields,
  {
    dataPath: 'rooms',
    fieldName: null,
    section: null,
    checkRules: [LocationFieldRulesEnum.MISSING],
  },
  {
    dataPath: 'docks',
    fieldName: null,
    section: null,
    checkRules: [LocationFieldRulesEnum.MISSING],
  },
  {
    dataPath: 'connectionConfigurations',
    fieldName: null,
    section: null,
    checkRules: [LocationFieldRulesEnum.MISSING],
  },
];
const operationalFields: ILocationFields[] = [
  ...pendingFields,
  {
    dataPath: 'assignedBcbs',
    fieldName: null,
    section: null,
    checkRules: [LocationFieldRulesEnum.MISSING],
  },
];
export const testedSnapInsCustomValidation = location => {
  const { data } = location;
  const { snapIns, docks } = data;
  const flattedDocks = Object.values(docks).flat();

  return (
    snapIns.length === flattedDocks.length &&
    snapIns.every(snapIn => snapIn.snapInStatus === 'Tested')
  );
};
export const locationStatuses = [
  {
    fields: draftFields,
    allowedTransitions: [1],
    locationCanBeDeleted: true,
    canEditHardware: true,
    canEditConnections: true,
    number: 0,
    name: 'Draft',
  },
  {
    fields: pendingFields,
    allowedTransitions: [0, 2],
    locationCanBeDeleted: true,
    canEditHardware: false,
    canEditConnections: true,
    number: 1,
    name: 'Pending',
  },
  {
    fields: pendingFields,
    allowedTransitions: [0, 1, 3, 4],
    locationCanBeDeleted: false,
    canEditHardware: false,
    canEditConnections: true,
    number: 2,
    name: 'Install',
  },
  {
    fields: operationalFields,
    customValidation: testedSnapInsCustomValidation,
    allowedTransitions: [4, 5, 6],
    locationCanBeDeleted: false,
    canEditHardware: false,
    canEditConnections: true,
    number: 3,
    name: 'Operational',
  },
  {
    fields: operationalFields,
    allowedTransitions: [3, 6],
    locationCanBeDeleted: false,
    canEditHardware: true,
    canEditConnections: true,
    number: 4,
    name: 'Maintenance',
  },
  {
    fields: operationalFields,
    allowedTransitions: [3, 6],
    locationCanBeDeleted: false,
    canEditHardware: false,
    canEditConnections: true,
    number: 5,
    name: 'Suspended',
  },
  {
    fields: operationalFields,
    allowedTransitions: [],
    locationCanBeDeleted: false,
    canEditHardware: false,
    canEditConnections: false,
    number: 6,
    name: 'Stopped',
  },
];

export const canEditLocationSection = (locationStatusName: string, sectionName: string) => {
  const statusObject = locationStatuses.find(status => status.name === locationStatusName);

  switch (sectionName) {
    case 'docks':
    case 'rooms': {
      return statusObject && statusObject.canEditHardware;
    }
    case 'connectionConfigurations': {
      return statusObject && statusObject.canEditConnections;
    }
    default: {
      return false;
    }
  }
};
export const getStatusByName = (statusName: string) =>
  locationStatuses.find(({ name }) => name === statusName);
export const getStatusNumberByName = statusName =>
  locationStatuses.find(({ name }) => name === statusName).number;

export const mapToLocationNewDto = (location: ILocationEdit) => {
  const rooms: RoomNewDto[] = [];
  if (location && location.rooms) {
    location.rooms.forEach(r => {
      const roomDto: RoomNewDto = {
        name: r.name,
        typeId: r.typeId,
        // @ts-ignore
        docks: [],
      };
      rooms.push(roomDto);
    });
  }
  const result: LocationNewDto = {
    locationName: location.details.name,
    lotNumber: location.details.lotNumber,
    locationTypeId: location.details.typeId,
    addressCity: location.details.addressCity,
    addressStreet: location.details.addressStreet,
    addressHouseNumber: location.details.addressHouseNumber,
    addressPostalCode: location.details.addressPostalCode,
    notes: location.details.notes,
    contactDetailsEmail: location.details.contactDetailsEmail,
    contactDetailsPhoneNumber: location.details.contactDetailsPhoneNumber,
    // @ts-ignore
    rooms,
  };
  return result;
};

export const mapToLocationDto = (location: ILocationEdit) => {
  const rooms: RoomDto[] = [];
  if (location && location.rooms) {
    location.rooms.forEach(r => {
      const roomDto: RoomDto = {
        id: r.databaseId,
        name: r.name,
        typeId: r.typeId,
        // @ts-ignore
        docks: [],
      };
      r.docks.forEach(s => {
        // @ts-ignore
        roomDto.docks.push({
          number: s.number,
          dockCode: s.dockCode,
          positionId: 'CeilingNear', // TODO
          devices: s.devices,
        });
      });
      rooms.push(roomDto);
    });
  }

  const result: LocationDto = {
    id: location.id,
    lotNumber: location.details.lotNumber,
    locationName: location.details.name,
    locationTypeId: location.details.typeId,
    status: location.details.status,
    insertDate: location.insertDate,
    addressCity: location.details.addressCity,
    addressStreet: location.details.addressStreet,
    addressHouseNumber: location.details.addressHouseNumber,
    addressPostalCode: location.details.addressPostalCode,
    files: location.details.files,
    assignedBcbs: location.assignedBcbs,
    bcbConnectedStatus: location.bcbConnectedStatus,
    // TODO fix connection configurations
    connectionConfigurations: [],
    deviceTypeConfigurationDefault: null,
    locationConfiguration: null,
    contactDetailsEmail: location.details?.contactDetailsEmail || null,
    contactDetailsPhoneNumber: location.details?.contactDetailsPhoneNumber || null,
  };
  return result;
};

export const mapToLocationEdit = (
  location: LocationDto,
  docks: DocksMap = {},
  snapIns: SnapIn[] = [],
  connectionConfigurations: ConnectionConfigurationDto[] = [],
) => {
  // TODO remove rooms from location model
  const rooms: ILocationRoom[] = [];
  // @ts-ignore
  if (location.rooms) {
    // @ts-ignore
    location.rooms.forEach(r => {
      const room: ILocationRoom = {
        id: r.id || getRandomKey().toString(),
        name: r.name,
        typeId: r.typeId,
        docks: [],
      };

      rooms.push(room);
    });
  }
  const locationEdit: ILocationEdit = {
    id: location.id,
    details: {
      lotNumber: location.lotNumber,
      name: location.locationName,
      typeId: location.locationTypeId,
      status: location.status,
      addressCity: location.addressCity,
      addressStreet: location.addressStreet,
      addressHouseNumber: location.addressHouseNumber,
      addressPostalCode: location.addressPostalCode,
      files: location.files
        ? location.files.map(f => {
            const file: IBFile = {
              fileName: f.fileName,
              blobId: f.blobId,
              fileLength: f.fileLength,
              fileType: f.fileType,
              contentType: f.contentType,
            };
            return file;
          })
        : [],
      notes: location.notes,
      contactDetailsEmail: location.contactDetailsEmail,
      contactDetailsPhoneNumber: location.contactDetailsPhoneNumber,
    },
    bcbConnectedStatus: location.bcbConnectedStatus,
    insertDate: location.insertDate,
    assignedBcbs: location.assignedBcbs,
    rooms,
    docks,
    snapIns,
    connectionConfigurations,
  };
  return locationEdit;
};
