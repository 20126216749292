import {
  getCompanyApi,
  getUserApi,
  getInvitationApi,
  PagedResultOfCompanyDto,
  Toast,
  SystemUserDto,
  SystemUserInvitationDto,
  ToastHelper,
  ToastType,
} from '@streda/web_components';
import { AxiosResponse } from 'axios';
import { getAccount } from '../../../../utils/auth.utils';

export const addUser = ({ name, surname, ...restUserProps }: SystemUserDto): Promise<any> => {
  const invitationUser: SystemUserInvitationDto = {
    firstName: name,
    lastName: surname,
    ...restUserProps,
  };

  return getInvitationApi(getAccount())
    .invitationInviteSystemUser(invitationUser)
    .then(result => {
      Toast.showToast({
        message: 'User has been invited.',
        colorType: 'Success',
      });
      return Promise.resolve(result);
    })
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during user inviting. Please try again or contact with system administrator',
        error,
      );
      return Promise.reject(error);
    });
};

export const updateUser = (user: SystemUserDto): Promise<AxiosResponse> =>
  getUserApi(getAccount())
    .userUpdateSystemUser({
      ...user,
      displayName: `${user.name} ${user.surname}`,
    })
    .then(result => {
      Toast.showToast({
        message: 'User has been updated.',
        colorType: 'Success',
      });
      return Promise.resolve(result);
    })
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during update user. Please try again or contact with system administrator',
        error,
      );
      return Promise.reject(error);
    });

export const getCompanies = (): Promise<AxiosResponse<PagedResultOfCompanyDto>> =>
  getCompanyApi(getAccount())
    .companyGetCompanies()
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during getting companies. Please try again or contact with system administrator',
        error,
      );
      return Promise.reject(error);
    });

export const getUser = (userId: string): Promise<AxiosResponse<SystemUserDto>> =>
  getUserApi(getAccount())
    .userGetSystemUser2(userId)
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during user details. Please try again or contact with system administrator',
        error,
      );
      return Promise.reject(error);
    });

export default {
  addUser,
  updateUser,
  getUser,
  getCompanies,
};
