import { Toast, ToastHelper, ToastType } from '@streda/web_components';
import { ILocationRoom } from '../../../../../../models/location';
import { getRoomsApi } from '../../../../../../utils/api.utils';
import { getAccount } from '../../../../../../utils/auth.utils';

export const handleOnElementTextChange = (
  rooms: ILocationRoom[],
  elementId: string,
  locationId: string,
  newName: string,
  onChange: (newRooms: ILocationRoom[]) => void,
) => {
  const newRooms = [...rooms];
  const room = newRooms.find(r => r.id === elementId);
  if (room) {
    room.name = newName;
  }

  if (locationId) {
    getRoomsApi(getAccount())
      .roomUpdateRoom(locationId, room)
      .then(() => {
        onChange(newRooms);
      });
  } else {
    onChange(newRooms);
  }
};

export const handleOnElementDelete = (
  rooms: ILocationRoom[],
  elementId: string,
  locationId: string,
  onChange: (newRooms: ILocationRoom[]) => void,
) => {
  const newRooms = rooms.filter(r => r.id !== elementId);

  if (locationId) {
    getRoomsApi(getAccount())
      .roomRemoveRoom(locationId, elementId)
      .then(() => {
        onChange(newRooms);
        Toast.showToast({
          message: 'Room has been deleted.',
          colorType: 'Success',
        });
      })
      .catch(error => {
        ToastHelper(
          ToastType.DANGER,
          'Error during deleting room. Please try again or contact with system administrator',
          error,
        );
      });
  } else {
    onChange(newRooms);
  }
};
