import * as React from 'react';
import {
  ActionModal,
  CloseIcon,
  HomeOwnerIcon,
  Input,
  InputStyleTypeEnum,
  InvitationDto,
  SnapInIcon,
  Text,
  UpdateHomeOwnerDto,
} from '@streda/web_components';
import styled from 'styled-components';
import { ILocationEditModalState } from '../location-edit-modal/location-edit-modal.types';

const OwnersDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 18px;
`;

const OwnerDetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const OwnerDetailsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
`;

const OwnerEmailText = styled(Text)`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #191919;
`;

const InvitationStatusText = styled(Text)`
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #a3a3a3;
`;

interface IOwnersListProps {
  invitations?: InvitationDto[];
  company: string;
  state: ILocationEditModalState;
  onInvitationRemove: (invitation: InvitationDto) => void;
  onInvitationEmailUpdate: (
    invitation: UpdateHomeOwnerDto,
    oldInvitation: InvitationDto,
  ) => Promise<any>;
}

function Invitation({ invitation, company, onInvitationRemove, onInvitationEmailUpdate, state }) {
  const { id } = state.data;
  const { email, locationId } = invitation;
  const [localEmail, setLocalEmail] = React.useState(email);
  const [pending, setPending] = React.useState<boolean>(false);
  const [showEmailChangeConfirmModal, setShowEmailChangeConfirmModal] = React.useState<boolean>(
    false,
  );

  return (
    <>
      <OwnersDetailsWrapper key={invitation.email}>
        <OwnerDetailsColumn>
          <OwnerDetailsRow>
            <Input
              value={localEmail}
              styleType={InputStyleTypeEnum.INLINE}
              onBlur={() => {
                if (id) {
                  setShowEmailChangeConfirmModal(true);
                } else {
                  onInvitationEmailUpdate(
                    {
                      oldEmail: email,
                      newEmail: localEmail,
                      locationId,
                      companyId: company,
                    },
                    invitation,
                  );
                }
              }}
              onChange={newEmail => setLocalEmail(newEmail)}
            />
            <CloseIcon type="button" onClick={() => onInvitationRemove(invitation)} />
          </OwnerDetailsRow>
          <OwnerDetailsRow>
            <InvitationStatusText>{invitation.status}</InvitationStatusText>
          </OwnerDetailsRow>
        </OwnerDetailsColumn>
      </OwnersDetailsWrapper>
      <ActionModal
        onHide={() => {
          setLocalEmail(email);
          setShowEmailChangeConfirmModal(false);
        }}
        pending={pending}
        onConfirm={() => {
          setPending(true);
          onInvitationEmailUpdate(
            {
              oldEmail: email,
              newEmail: localEmail,
              locationId,
              companyId: company,
            },
            invitation,
          )
            .catch(() => setLocalEmail(email))
            .finally(() => {
              setShowEmailChangeConfirmModal(false);
              setPending(false);
            });
        }}
        icon={<HomeOwnerIcon width={80} height={80} />}
        show={showEmailChangeConfirmModal}
        title=""
        header="Update Home Owner?"
        description={
          <>
            Are you sure you want to update
            <br /> Home Owner email?
            <br /> New invitation will be send
            <br /> to changed email address.
          </>
        }
        altButton2Text="Confirm"
      />
    </>
  );
}

const OwnersList: React.FunctionComponent<IOwnersListProps> = props => {
  const { invitations, ...restProps } = props;
  return (
    <>
      <div className="horizontal-divider m-t-24 m-b-24" />
      <div className="h2-medium">Assigned:</div>
      {invitations?.map(invitation => (
        <Invitation invitation={invitation} {...restProps} />
      ))}
      <div className="horizontal-divider m-t-24 m-b-24" />
    </>
  );
};

export default OwnersList;
