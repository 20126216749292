import { NetworkStatusType } from './network-status-indicator.types';

const getClassNameForStatus = (status: NetworkStatusType): string => {
  const baseClassName = 'control-center-text text-label-bold radius-4 h-32 w-64';
  switch (status) {
    case 'active':
      return `${baseClassName} color-status-active`;
    case 'partially-inactive':
      return `${baseClassName} color-status-inactive`;
    case 'inactive':
      return `${baseClassName} b-color-status-inactive`;
    case 'unknown':
      return `${baseClassName} color-status-unknown`;
    case 'error':
      return `${baseClassName} b-error-validation-color`;
    default:
      return baseClassName;
  }
};

export default getClassNameForStatus;
