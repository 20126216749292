import styled from 'styled-components';
import { Button, NoneButton, SnapInIcon } from '@streda/web_components';

export const Placeholder = styled.div`
  color: ${({ theme }) => theme.colors.font1}60;
  overflow: hidden;
`;

export const PlaceholderIcon = styled(SnapInIcon)`
  margin-right: 16px;
`;

export const ToggleContainer = styled.div<{ inlineMode: boolean }>`
  display: flex;
  padding: 8px;
  border: 0.75px solid
    ${({ theme, inlineMode }) => (inlineMode ? 'transparent' : theme.colors.primary)};
  color: ${({ theme }) => theme.colors.primary};
  margin: 0;
  height: 36px;
  text-align: left;
  justify-content: space-between;
`;

export const StyledSnapInName = styled.div`
  padding-left: 16px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  flex: 1;
  overflow: hidden;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  width: 72px;
  min-width: unset;
`;

export const ProfileBtnsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const SectionContainer = styled.div`
  margin-bottom: 16px;
`;

export const SnapInName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledToggle = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

export const ProfileInToggle = styled.span<{ compatibilityWarning: boolean }>`
  color: ${({ theme, compatibilityWarning }) =>
    compatibilityWarning ? theme.colors.alert : undefined};
  padding-right: 16px;
`;

export const SnapInsGroupLabel = styled.div`
  font-size: 14px;
  margin-bottom: 24px;
`;

export const SnapInsOption = styled(NoneButton)`
  font-size: 14px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 16px;
  text-align: left;
`;

export const DropdownWrapper = styled.div`
  margin-right: 8px;
`;
