import * as React from 'react';
import {
  TimelineOptions,
  getLocationsSnapshots,
  makeLocationsSnapshot,
  LocationIcon,
  ActionModal,
  Button,
  ButtonType,
  DeleteIcon,
  deleteLocationsSnapshot,
} from '@streda/web_components';
import { useHistory } from 'react-router-dom';
import { prepareBackupsChainOption, onBackupRestore } from './location-settings.logic';
import { getAccount } from '../../../../utils/auth.utils';
import { Container, Title, TitleContainer } from './location-settings.components';
import { IPending, PendingTypeEnum } from './location-settings.types';

function LocationSettings(props) {
  const { locationId } = props;
  const [showRestoreModal, setShowRestoreModal] = React.useState({
    show: false,
    snapshotId: null,
  });
  const [showDeleteModal, setShowDeleteModal] = React.useState({
    show: false,
    snapshotId: null,
  });
  const [locationSnapshots, setLocationSnapshots] = React.useState([]);
  const [pending, setPending] = React.useState<IPending>();
  const history = useHistory();

  React.useEffect(() => {
    if (locationId) {
      getLocationsSnapshots(getAccount, locationId).then((snapshots: any) =>
        setLocationSnapshots(snapshots.data),
      );
    }
  }, [locationId]);

  const onMakeSnapshot = () => {
    setPending({
      type: PendingTypeEnum.MAKING_SNAPSHOT,
    });
    makeLocationsSnapshot(getAccount, locationId)
      .then(({ id, name, creationDateTime }) =>
        setLocationSnapshots([...locationSnapshots, { id, name, creationDateTime }]),
      )
      .finally(() => setPending(undefined));
  };
  const onDeleteSnapshot = snapshotId => {
    deleteLocationsSnapshot(getAccount, snapshotId).then(() =>
      setLocationSnapshots(locationSnapshots.filter(({ id }) => id !== snapshotId)),
    );
  };

  return (
    <div className="location-settings">
      <Container>
        <TitleContainer>
          <Title>
            <span className="location-settings-param-description-primary">
              Configuration history
            </span>
            <span className="location-settings-param-description-secondary">
              Restore previous configuration and settings
            </span>
          </Title>
          <Button
            type={ButtonType.BUTTON}
            text="Make snapshot"
            pending={pending?.type === PendingTypeEnum.MAKING_SNAPSHOT}
            onClick={onMakeSnapshot}
          />
        </TitleContainer>

        <TimelineOptions
          items={prepareBackupsChainOption(
            locationSnapshots,
            setShowRestoreModal,
            setShowDeleteModal,
          )}
        />
      </Container>

      <ActionModal
        onHide={() => setShowRestoreModal({ ...showRestoreModal, show: false })}
        onConfirm={() => {
          setShowRestoreModal({ ...showRestoreModal, show: false });
          onBackupRestore(locationId, showRestoreModal.snapshotId, history);
        }}
        show={showRestoreModal.show}
        icon={<LocationIcon width="100px" height="100px" />}
        header="Restore backup?"
        description="All connections & configured elements will be sent to SCB"
      />
      <ActionModal
        onHide={() => setShowDeleteModal({ ...showDeleteModal, show: false })}
        onConfirm={() => {
          onDeleteSnapshot(showDeleteModal.snapshotId);
          setShowDeleteModal({ ...showDeleteModal, show: false });
        }}
        show={showDeleteModal.show}
        icon={<DeleteIcon width="100px" height="100px" />}
        header="Delete backup?"
        description="Backup will be permanently deleted from the system"
      />
    </div>
  );
}

export default LocationSettings;
