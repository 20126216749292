import { AccountRoles, hasUserRole, ISelectOption } from '@streda/web_components';
import _ = require('lodash');
import { installationStatusesFlow } from './installation-status.types';
import {
  locationStatuses,
  ILocationFields,
  LocationFieldRulesEnum,
} from '../../../../utils/location.utils';

export const getStatusProgress = (status: number) => {
  if (status < 0) {
    return 1;
  }
  if (status === installationStatusesFlow.length - 1) {
    return 100;
  }
  const totalChars = installationStatusesFlow.join('').length + 3 * installationStatusesFlow.length;
  if (status === 0) {
    return ((installationStatusesFlow[0].length + 3) / totalChars / 2) * 100;
  }
  const closedStatuses = installationStatusesFlow.slice(0, status);
  return (
    ((closedStatuses.join('').length + closedStatuses.length * 3) / totalChars) * 100 +
    ((locationStatuses.find(s => s.number === status).name.length + 3) / totalChars / 2) * 100
  );
};

export const getHighlightedStatusIndex = (statusValue: ISelectOption) => {
  if (+statusValue.value > 2 && +statusValue.value < 6) {
    return 2;
  }
  return installationStatusesFlow.indexOf(statusValue.label);
};

export const getStatusChangeErrorMsg = (location: any, fields: ILocationFields[]): string => {
  let errorReason =
    'Error during status change. Please try again or contact with system administrator.';
  if (fields) {
    const requiredDetails = fields.filter(field => field.section === 'details');
    if (requiredDetails) {
      const detailsKeys = requiredDetails.map(detail => detail.fieldName);
      const missingFields = detailsKeys.filter(detailKey => _.isEmpty(location.details[detailKey]));
      if (!_.isEmpty(missingFields)) {
        errorReason = `Some of the locations details are missing: ${missingFields.map(
          field => `${field}${missingFields.length > 1 && ', '}`,
        )}`;
      }
    }
    // Assigned bcbs check
    if (
      fields.find(field => field.dataPath === 'assignedBcbs') &&
      _.isEmpty(location.assignedBcbs)
    ) {
      errorReason = 'Location has to have assigned bcbs to change the status';
    }
    // Connection configuration check
    if (
      fields.find(field => field.dataPath === 'connectionConfigurations') &&
      _.isEmpty(location?.connectionConfigurations)
    ) {
      errorReason = 'Location has to have connection configuration to change the status';
    }
  }
  return errorReason;
};

export const validateStatusChange = (
  currentStatusNumber: number,
  changeStatusNumber: number,
  location,
): { valid: boolean; msg?: string } => {
  let valid = true;
  let msg = '';
  const isSuperAdmin = hasUserRole(AccountRoles.SUPER_ADMINISTRATOR);

  // For super admin, transitions from every status to every should be allowed
  if (isSuperAdmin) {
    return { valid, msg };
  }

  const currentStatus = locationStatuses.find(({ number }) => number === currentStatusNumber);
  const changeStatus = locationStatuses.find(({ number }) => number === changeStatusNumber);
  const allowedStatuses = locationStatuses
    .filter(({ number }) => currentStatus.allowedTransitions.includes(number))
    .filter(statusToVerify =>
      statusToVerify.fields
        .filter(({ checkRules }) => checkRules?.includes(LocationFieldRulesEnum.MISSING))
        .every(fieldPath => {
          const value = fieldPath.dataPath.split('.').reduce((previous, element) => {
            if (!previous) {
              return location.data[element];
            }

            return previous[element];
          }, null);

          const valueKeys = Object.keys(value || {});
          if (Array.isArray(value)) {
            return value.length > 0;
          }
          if (valueKeys) {
            return valueKeys.some(key =>
              Array.isArray(value[key]) ? value[key].length > 0 : !!value[key],
            );
          }

          return !!value;
        }),
    );
  if (allowedStatuses.find(el => el.number === changeStatusNumber)) {
    valid = true;
  } else {
    const errorMsg = getStatusChangeErrorMsg(location.data, changeStatus.fields);
    valid = false;
    msg = errorMsg;
  }
  return { valid, msg };
};

export const getAllowedOptions = (): ISelectOption[] => {
  return locationStatuses.map(({ number, name }) => ({
    value: number.toString(),
    label: name,
  }));
};
