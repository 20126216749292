import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { showLoader, hideLoader, LoaderMode, getLocationEmptyModel } from '@streda/web_components';
import { IConfigurationPageProps, IConfigurationPageState } from './configuration.types';
import initConfigurationPage from './configuration.logic';
import ConfigurationPanel from './configuration-panel/configuration-panel';
import { CoverLoader } from '../../../shared/loader/loader';

const Configuration: React.FC<IConfigurationPageProps> = (props: IConfigurationPageProps) => {
  const { locationId } = useParams() as { locationId: string | undefined };
  const { className } = props;
  const [state, setState] = React.useState<IConfigurationPageState>({
    location: getLocationEmptyModel(),
    rooms: [],
    docks: {},
  });
  const history = useHistory();

  React.useEffect(() => {
    showLoader(<CoverLoader />, {
      mode: LoaderMode.COVER,
    });
    initConfigurationPage(locationId, setState).then(() => {
      hideLoader();
    });
  }, []);

  return (
    <div className={className}>
      <div className="inline-flex-container devices-configuration-page">
        <ConfigurationPanel
          onConfigurationChange={() => initConfigurationPage(locationId, setState)}
          className="devices-configuration b-color-b2 h-100vh"
          locationDetails={state.location}
          rooms={state.rooms}
          docks={state.docks}
          locationId={locationId}
          onLocationChange={location => setState({ ...state, location })}
        />
      </div>
    </div>
  );
};

export default Configuration;
