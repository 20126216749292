import * as React from 'react';
import { OwnerEmailForm, InvitationDto } from '@streda/web_components';
import styled from 'styled-components';

const AssingOwnerTitle = styled.div`
  margin-bottom: 50px;
`;

export interface IAssignOwnerProps {
  onAssign?: (ownerEmail: string) => Promise<any>;
  currentInvitation?: InvitationDto;
}

const AssignOwner: React.FC<IAssignOwnerProps> = props => {
  const { onAssign, currentInvitation } = props;
  const [sending, setSending] = React.useState(false);
  const [emailSent, setEmailSent] = React.useState(false);

  React.useEffect(() => {
    if (currentInvitation?.email) {
      setEmailSent(true);
      setSending(false);
    } else {
      setEmailSent(false);
    }
  }, [currentInvitation]);

  const onAddHandler = (value: string) => {
    if (currentInvitation.status === 'Pending') {
      return;
    }
    setSending(true);
    onAssign(value)
      .then(() => {
        setEmailSent(true);
        setSending(false);
      })
      .catch(() => {
        setEmailSent(false);
        setSending(false);
      });
  };
  return (
    <>
      <AssingOwnerTitle className="h2-medium">Assign Home Owner to a location</AssingOwnerTitle>
      <OwnerEmailForm
        onSubmit={el => onAddHandler(el)}
        inProgress={sending}
        emailSent={emailSent}
      />
    </>
  );
};

export default AssignOwner;
