import * as React from 'react';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router';
import {
  AccountRoles,
  CompanyIcon,
  hasUserRole,
  HomeIcon,
  NoneButton,
  SvgImage,
  UsersIcon,
  HomeOwnerIcon,
  updateQueryParams,
} from '@streda/web_components';
import NavItem from '../nav-item/nav-item';
import { ISideBarProps, ISideBarState } from './sidebar.types';
import updateSidebar from './sidebar.logic';
import { getAssetPath } from '../../../utils/images.utils';
import UserDropdown from './user-dropdown/user-dropdown';

const SideBar: React.FC<ISideBarProps> = (props: ISideBarProps) => {
  const { className } = props;
  const location = useLocation();
  const history = useHistory();
  const [state, setState] = React.useState<ISideBarState>({});
  React.useEffect(() => updateSidebar(location.pathname, state, setState), [location]);
  const isSuperAdmin = hasUserRole(AccountRoles.SUPER_ADMINISTRATOR);

  return (
    <div className={className ?? ''}>
      <div
        className={clsx({
          sidebar: true,
          collapsed: state.isCollapsed,
          hidden: state.isHidden,
        })}
      >
        <NoneButton
          className={`app-logo ${state.isCollapsed ? 'collapsed' : ''}`}
          type="button"
          onClick={() => setState({ ...state, isCollapsed: !state.isCollapsed })}
        >
          <SvgImage inAssetsPath={getAssetPath('img/logo-icon.svg')} />
        </NoneButton>
        <div className="sidebar-container">
          <NavItem icon={<HomeIcon />} collapsed={state.isCollapsed} to="/locations">
            Locations
          </NavItem>
          {isSuperAdmin && (
            <NavItem icon={<CompanyIcon />} collapsed={state.isCollapsed} to="/companies">
              Companies
            </NavItem>
          )}
          {!isSuperAdmin && (
            <NavItem
              icon={<CompanyIcon />}
              collapsed={state.isCollapsed}
              onClick={() =>
                updateQueryParams(
                  {
                    editCompany: '',
                  },
                  history,
                  location,
                )
              }
            >
              Company
            </NavItem>
          )}
          <NavItem icon={<UsersIcon />} collapsed={state.isCollapsed} to="/users">
            Users
          </NavItem>
          <NavItem icon={<HomeOwnerIcon />} collapsed={state.isCollapsed} to="/homeowners">
            Homeowners
          </NavItem>
        </div>
        <div className="align-center user-menu">
          <UserDropdown />
        </div>
      </div>
    </div>
  );
};

export default SideBar;
