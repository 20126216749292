import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
  ExpandableSection,
  hideLoader,
  showLoader,
  ModalLoader,
  DeleteSceneModal,
  getRoomIconById,
  NoneButton,
  PlusSquareIcon,
  SceneTile,
  GridRelay,
  SubGrid,
} from '@streda/web_components';
import { getScenes, deleteScene, invokeScene } from './scenes-list.logic.api';
import { Container } from './scenes-list.components';

function ScenesList(props) {
  const { locationId, rooms, docks } = props;
  const history = useHistory();
  const [scenes, setScenes] = React.useState([]);
  const [scenesInvokingProgress, setScenesInvokingProgress] = React.useState([]);
  const [deleteSceneModalState, setDeleteSceneModalState] = React.useState({
    visible: false,
    sceneToDeleteId: null,
    version: null,
  });

  React.useEffect(() => {
    getScenes(locationId).then(scenesList => setScenes(scenesList));
  }, []);

  return (
    <Container>
      <div className="configuration-panel-top-bar-mock" />
      <div className="scenes-list-header">
        <span className="scenes-list-header-title text-brand-highlight">Scenes</span>
        <NoneButton
          data-testid="create_scene_button"
          type="button"
          className="button-none--not-hovered"
          onClick={() => {
            history.push(`/location/${locationId}/configuration/scenes/create-scene`);
          }}
        >
          <PlusSquareIcon />
        </NoneButton>
      </div>

      <SubGrid>
        {[
          {
            name: 'Global',
          },
          ...rooms,
        ].map(room => (
          <ExpandableSection
            key={`room_${room.id}`}
            title={room.name}
            icon={getRoomIconById(room.typeId)}
            grid
          >
            <GridRelay>
              {scenes
                .filter(scene =>
                  room.id ? scene.roomIds.includes(room.id) : scene.roomIds.length === 0,
                )
                .map(scene => (
                  <SceneTile
                    scene={scene}
                    docks={docks}
                    pending={scenesInvokingProgress.includes(scene.id)}
                    onSceneEdit={event => {
                      event.preventDefault();
                      event.stopPropagation();
                      history.push(`/location/${locationId}/configuration/scenes/edit-scene`, {
                        scene,
                      });
                    }}
                    onSceneDelete={event => {
                      event.preventDefault();
                      event.stopPropagation();
                      setDeleteSceneModalState({
                        visible: true,
                        sceneToDeleteId: scene.id,
                        version: scene.version,
                      });
                    }}
                    onSceneInvoke={event => {
                      event.preventDefault();
                      event.stopPropagation();

                      setScenesInvokingProgress([...scenesInvokingProgress, scene.id]);
                      invokeScene(locationId, scene.id).finally(() =>
                        setScenesInvokingProgress(progressIds => [
                          ...progressIds.filter(sceneId => sceneId !== scene.id),
                        ]),
                      );
                    }}
                  />
                ))}
            </GridRelay>
          </ExpandableSection>
        ))}
      </SubGrid>
      <DeleteSceneModal
        show={deleteSceneModalState.visible}
        onHide={() =>
          setDeleteSceneModalState({ visible: false, sceneToDeleteId: null, version: null })
        }
        onConfirm={() => {
          const { sceneToDeleteId, version } = deleteSceneModalState;

          showLoader(<ModalLoader />);
          deleteScene(sceneToDeleteId, version, locationId)
            .then(sceneId => setScenes(scenes.filter(scene => scene.id !== sceneId)))
            .finally(() => hideLoader());
          setDeleteSceneModalState({ visible: false, sceneToDeleteId: null, version: null });
        }}
      />
    </Container>
  );
}

export default ScenesList;
