import * as React from 'react';
import {
  hideLoader,
  ModalLoader,
  restoreLocationConfigurationBySnapshot,
  showLoader,
  TimelineOptionsItemsType,
} from '@streda/web_components';
import { getAccount } from '../../../../utils/auth.utils';

const bcbRestartTimeout = 15000;

export const prepareBackupsChainOption = (
  backups,
  setShowRestoreModal: ({ show, snapshotId }) => void,
  setShowDeleteModal: ({ show, snapshotId }) => void,
): TimelineOptionsItemsType[] =>
  backups.map(({ name, creationDateTime, id }) => {
    const actions = [
      {
        name: 'restore',
        triggerFunction: () =>
          setShowRestoreModal({
            show: true,
            snapshotId: id,
          }),
      },
      {
        name: 'delete',
        triggerFunction: () =>
          setShowDeleteModal({
            show: true,
            snapshotId: id,
          }),
      },
    ];

    return {
      name,
      creationDateTime,
      actions,
    };
  });

export const onBackupRestore = (locationId, backupId, history) => {
  showLoader(<ModalLoader />);

  restoreLocationConfigurationBySnapshot(getAccount, locationId, backupId)
    .then(() => {
      setTimeout(() => {
        history.push('/locations/reload');
      }, bcbRestartTimeout);
    })
    .catch(() => hideLoader());
};

export default {
  prepareBackupsChainOption,
  onBackupRestore,
};
