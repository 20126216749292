import * as React from 'react';
import { dark, light } from '@streda/web_components';
import { ThemeProvider as SCThemeProvider } from 'styled-components';

export interface ThemeContextType {
  isDark: boolean;
  toggleTheme: () => void;
  setDarkTheme?: (value: boolean) => void;
}

const ThemeContext = React.createContext<ThemeContextType>({
  isDark: false,
  toggleTheme: () => null,
  setDarkTheme: value => value,
});

const ThemeContextProvider: React.FC = ({ children }) => {
  const [isDark, setIsDark] = React.useState(false);

  const toggleTheme = () => {
    setIsDark(prevState => {
      return !prevState;
    });
  };

  const setDarkTheme = value => {
    setIsDark(value);
  };

  return (
    <ThemeContext.Provider value={{ isDark, toggleTheme, setDarkTheme }}>
      <SCThemeProvider theme={isDark ? dark : light}>{children}</SCThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeContextProvider };
