import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  CompanyDto,
  getMyCompany,
  hasQueryParam,
  hideLoader,
  ModalLoader,
  showLoader,
  updateQueryParams,
} from '@streda/web_components';
import CompanyEditModal from './pages/companies/company-edit-modal/company-edit-modal';

const CompanyEdit: React.FC = function() {
  const location = useLocation();
  const history = useHistory();
  const [showCompanyEdit, setShowCompanyEdit] = React.useState<boolean>(false);
  const [companyDetails, setCompanyDetails] = React.useState<CompanyDto>();

  React.useEffect(() => {
    if (!showCompanyEdit) {
      setCompanyDetails(undefined);
      return;
    }

    showLoader(<ModalLoader />);
    getMyCompany()
      .then(company => setCompanyDetails(company))
      .finally(() => {
        hideLoader();
      });
  }, [showCompanyEdit]);

  React.useEffect(() => {
    const hasShowSettingsParam = hasQueryParam('editCompany', location);

    setShowCompanyEdit(hasShowSettingsParam);
  }, [location]);
  const closeModal = () => {
    updateQueryParams(
      {
        editCompany: undefined,
      },
      history,
      location,
    );
  };

  if (!companyDetails) {
    return null;
  }

  return (
    <CompanyEditModal
      companyDetails={companyDetails}
      onClose={() => closeModal()}
      onSaveSuccess={() => closeModal()}
    />
  );
};

export default CompanyEdit;
