import * as React from 'react';
import {
  AccountRoles,
  EmptyTable,
  hasUserRole,
  hideLoader,
  HomeOwnerDto,
  IRemoteTableColumn,
  LoaderMode,
  ModalLoader,
  RemoteTable,
  showLoader,
  HomeOwnerIcon,
} from '@streda/web_components';
import styled from 'styled-components';
import { TabSpacer } from '../users/users-page/users-page';
import useHomeowners from './homeowners.logic';
import { HomeownerSortKey } from './homeowners.types';

const HomeownersPage = styled.div`
  padding-top: 95px;
  overflow: hidden;
  .homeowners-table {
    height: calc(100vh - 300px);
    bottom: 0;
    background-color: white;
    margin-top: 33px;
    position: relative;
  }
`;

export const getHomeownersColumns = (homeownersLenght: number = 0) => {
  const columns = [
    { key: 'homeownerId', name: 'homeownerId', hide: true },
    {
      key: 'icon',
      name: '',
      custom: true,
      customComponent: row => <HomeOwnerIcon key={row.id} />,
      disableSort: true,
      headerStyle: { width: '48px' },
      rowStyle: { paddingTop: '16px', paddingBottom: '16px', paddingLeft: '11px' },
    },
    {
      key: 'name',
      name: 'Name',
      custom: true,
      customComponent: (row: HomeOwnerDto) => {
        return <>{row.name}</>;
      },
      disableSort: homeownersLenght <= 1,
      rowStyle: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    {
      key: 'surname',
      name: 'Surname',
      custom: true,
      customComponent: (row: HomeOwnerDto) => {
        return <>{row.surname}</>;
      },
      disableSort: homeownersLenght <= 1,
      rowStyle: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    {
      key: 'email',
      name: 'Email',
      custom: true,
      disableSort: true,
      customComponent: (row: HomeOwnerDto) => {
        return <>{row.emails[0]}</>;
      },
      rowStyle: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  ] as IRemoteTableColumn[];

  if (hasUserRole(AccountRoles.SUPER_ADMINISTRATOR)) {
    columns.splice(4, 0, {
      key: 'companyName',
      name: 'Company name',
      disableSort: true,
    });
  }

  return columns;
};

interface IHomeownersProps {}

export const handleDataLoad = (isLoading: boolean) => {
  if (isLoading) {
    showLoader(<ModalLoader />, {
      mode: LoaderMode.COVER,
      loaderContainerSelector: '.homeowners-table',
      zIndex: 0,
    });
  } else {
    hideLoader();
  }
};

const Homeowners: React.FunctionComponent<IHomeownersProps> = () => {
  const [page, setPage] = React.useState(1);
  const [sortKey, setSortKey] = React.useState<HomeownerSortKey>(null);
  const [descending, setDescending] = React.useState<boolean>(false);
  const { homeowners, totalHomeowners, loading } = useHomeowners(page, 20, sortKey, descending);

  React.useEffect(() => {
    handleDataLoad(loading);
  }, [loading, sortKey, descending]);

  return (
    <HomeownersPage className="page-content">
      <div className="d-flex bd-highlight">
        <div className="flex-grow-1 bd-highlight">
          <div className="h1-medium text-brand-highlight">Homeowners</div>
        </div>
      </div>
      <TabSpacer height={72} />
      <RemoteTable
        tableTestId="homeowners-table"
        className="homeowners-table"
        onSortChange={e => {
          setDescending(e.sortDirection === 'desc');
          setSortKey(e.key as HomeownerSortKey);
        }}
        columns={getHomeownersColumns(homeowners.length)}
        data={homeowners}
        onLoadMore={() =>
          setPage(prevState => {
            return prevState + 1;
          })
        }
        showMoreButton={homeowners.length < totalHomeowners}
        emptyTableComponent={
          <EmptyTable
            icon={
              <HomeOwnerIcon className="empty-list-indicator-icon" height="18vh" width="18vh" />
            }
            title="No homeowners"
            description={
              <>
                All homeowners will be displayed here. <br />
                Refresh tabs to browse homeowners or{' '}
                <b>Add homeowner to a location</b>
              </>
            }
          />
        }
      />
    </HomeownersPage>
  );
};

export default Homeowners;
