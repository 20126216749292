import styled from 'styled-components';
import { Page } from '@streda/web_components';

export const StyledPage = styled(Page)`
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export default StyledPage;
