import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import * as React from 'react';
import { render } from 'react-dom';
import { msalConfig } from './authConfig';
import App from './components/App';
import { AnalyticsProvider } from './contexts/Analytics/Analytics';
import { ThemeContextProvider } from './ThemeContext';

const rootEl = document.getElementById('root');

const msalInstance = new PublicClientApplication(msalConfig);

render(
  <div className="full-height">
    <ThemeContextProvider>
      <AnalyticsProvider>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </AnalyticsProvider>
    </ThemeContextProvider>
  </div>,
  rootEl,
);
