import * as React from 'react';
import { ActionModal, LocationIcon } from '@streda/web_components';
import { IDeleteModalProps } from './delete-modal.types';
import onDelete from './delete-modal.logic';

const DeleteModal: React.FC<IDeleteModalProps> = (props: IDeleteModalProps) => {
  const { state, onHide } = props;
  return (
    <ActionModal
      onHide={() => onHide(false)}
      onConfirm={() => onDelete(state.id, onHide)}
      show={state.visible}
      icon={<LocationIcon />}
      title=""
      header="Delete location?"
      description={
        <>
          Do you want to delete location?
          <br />
          All status information will be gone.
        </>
      }
    />
  );
};

export default DeleteModal;
