export enum DockCableTypeEnum {
  Electric = 'electric',
  Hybrid = 'hybrid',
  Dockless = 'dockless',
}

export interface IToggleElement {
  cableType: DockCableTypeEnum;
  inlineMode?: boolean;
  compatibilityWarning?: boolean;
  disabled?: boolean;
}

export interface IContentElement {
  cableType: DockCableTypeEnum;
  onCableChange: (cableType: DockCableTypeEnum) => void;
  setShowDropdown: (dropdownStatus: boolean) => void;
  selectedDock: string;
}

export interface ICableTypeDropdown {
  cableType: DockCableTypeEnum;
  onCableChange: (cableType: DockCableTypeEnum) => void;
  inlineMode?: boolean;
  compatibilityWarning?: boolean;
  disabled?: boolean;
  selectedDock: string;
}
