export const getAssetPath = inAssetsPath => {
  let path = '';

  try {
    path = `${window.location.protocol}//${window.location.host}${
      // eslint-disable-next-line global-require,import/no-dynamic-require
      require(`../assets/${inAssetsPath}`).default
    }`;
  } catch {
    window.console.error(`Cannot find image ${inAssetsPath}`);
  }

  return path;
};

export default {
  getAssetPath,
};
