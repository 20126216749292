export const collapsedOn: string[] = [];

export const hiddenOn: string[] = [
  '/location/:id/configuration/connections',
  '/location/:id/configuration/connections-advanced',
  '/location/:id/configuration/scenes',
  '/location/:id/configuration/scenes/create-scene',
  '/location/:id/configuration/scenes/edit-scene',
  '/location/:id/devices-states',
  '/location/:id/analytics',
  '/location/:id/analytics/:roomId?',
  '/location/:id',
  '/location/:id/room/:roomId',
];
