import * as React from 'react';
import {
  ArrowRightIcon,
  CompanyIcon,
  getCompanyApi,
  hideLoader,
  IRemoteTableColumn,
  LoaderMode,
  ModalLoader,
  showLoader,
  SortDirectionEnums,
  ToastHelper,
  ToastType,
} from '@streda/web_components';
import { getAccount } from '../../../../utils/auth.utils';
import { ICompaniesListState } from './companies-list.types';

export const getCompanies = (
  state: ICompaniesListState,
  setPageState: (listState: ICompaniesListState) => any,
) => {
  showLoader(<ModalLoader />, {
    mode: LoaderMode.COVER,
    loaderContainerSelector: '.companies-table',
  });
  getCompanyApi(getAccount())
    .companyGetCompanies(
      undefined,
      state.filter.active,
      undefined,
      state.sort.key,
      state.sort.sortDirection === 'desc',
      state.page,
    )
    .then(result => {
      const companies =
        state.page === 0 ? result.data.data : [...state.companies, ...result.data.data];

      setPageState({
        ...state,
        companies,
        total: result.data.recordsTotalNumber,
      });
      hideLoader();
    })
    .catch(error => {
      hideLoader();
      ToastHelper(
        ToastType.DANGER,
        'Error during getting companies data. Please try again or contact with system administrator',
        error,
      );
    });
};

export const getCompaniesColumns = () => {
  return [
    { key: 'id', name: '', hide: true },
    {
      key: 'icon',
      name: '',
      custom: true,
      customComponent: row => <CompanyIcon />,
      disableSort: true,
      headerStyle: { width: '48px' },
      rowStyle: { paddingTop: '16px', paddingBottom: '16px', paddingLeft: '11px' },
    },
    {
      key: 'name',
      name: 'Company name',
      sortDirection: SortDirectionEnums.ASCENDING,
    },
    {
      disableSort: true,
      key: '',
      name: '',
      align: 'right',
      custom: true,
      customComponent: () => <ArrowRightIcon />,
      headerStyle: { width: '40px', marginRight: '16px' },
      rowStyle: { overflow: 'unset' },
    },
  ] as IRemoteTableColumn[];
};

export default {
  getCompanies,
  getCompaniesColumns,
};
