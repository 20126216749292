import { parseDockDevices, DockWithZigbeeIdDto } from '@streda/web_components';
import { AxiosResponse } from 'axios';
import { getDictionaryApi, getLocationsApi } from '../../../../utils/api.utils';
import { getAccount } from '../../../../utils/auth.utils';
import { mapToLocationEdit } from '../../../../utils/location.utils';
import { getRoomsDocks } from '../../locations/location-edit-modal/location-edit-modal.logic.api';

const initConfigurationPage = (locationId: string, setState: (state) => void) => {
  const state = {
    location: null,
    rooms: [],
    docks: {},
  };

  return getLocationsApi(getAccount())
    .locationGetLocation(locationId)
    .then(result => {
      state.location = mapToLocationEdit(result.data);
      state.rooms = result.data.rooms;
      state.rooms.forEach(room => {
        const roomId = room.id;

        state.docks[roomId] = room.docks;
      });

      return getRoomsDocks(locationId, state.rooms);
    })
    .then(docksResponse => {
      docksResponse.forEach((docks: AxiosResponse<DockWithZigbeeIdDto[]>, index) => {
        const roomId = state.rooms[index].id;

        docks.data.forEach((dock, dockIndex) => {
          const dockCurrentData = state.docks[roomId][dockIndex];

          state.docks[roomId][dockIndex] = { ...dockCurrentData, ...dock };
        });
      });

      return getDictionaryApi(getAccount()).dictionaryGetDeviceTypes();
    })
    .then(deviceTypes => {
      state.rooms.forEach(room => {
        state.docks[room.id].forEach((dock, index) => {
          state.docks[room.id][index].devices = parseDockDevices(dock, deviceTypes.data);
        });
      });

      setState({ location: state.location, rooms: state.rooms, docks: state.docks });
      return Promise.resolve();
    });
};

export default initConfigurationPage;
