import * as React from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import {
  Button,
  TabSwitch,
  RemoteTable,
  TableFilter,
  IModalState,
  LocationStatusEnum,
  ButtonType,
  LabeledFilterSelect,
  hasUserRole,
  AccountRoles,
  LocationIcon,
  ISelectOption,
} from '@streda/web_components';
import LocationEditModal from '../location-edit-modal/location-edit-modal';
import DuplicateModal from '../duplicate-modal/duplicate-modal';
import DeleteModal from '../delete-modal/delete-modal';
import { ILocationEdit } from '../../../../models/location';
import {
  getLocationColumns,
  ILocationRowOption,
} from '../location-edit-modal/location-edit-modal.types';
import { ILocationsPageProps, ILocationsPageState } from './locations-page.types';
import { locationStatuses } from '../../../../utils/location.utils';
import { onSortChange, loadData, searchCompanies } from './locations-page-logic';

function CompanySelectDropdown(state, onChange): JSX.Element {
  const [defaultOptions, setDefaultOptions] = React.useState<ISelectOption[]>([]);
  const [selectedCompany, setSelectedCompany] = React.useState<ISelectOption>();
  React.useEffect(() => {
    searchCompanies('').then(defaultCompanies => setDefaultOptions(defaultCompanies));
  }, []);

  return (
    <LabeledFilterSelect
      label="Company name"
      placeholder="Type company name"
      id="companyFilterSelect"
      minSearchTextLength={1}
      values={search => searchCompanies(search)}
      value={selectedCompany}
      onSelect={option => {
        setSelectedCompany(option);
        onChange(option.value);
      }}
      testId="filter_company_name"
      key="company"
      searchTimeout={500}
      defaultOptions={defaultOptions}
      onChange={event => {
        const typedCompany = event.target.value;
        const companyToSelect = defaultOptions.find(
          ({ label }) => label.toLowerCase() === typedCompany.toLowerCase(),
        );

        onChange(companyToSelect);
      }}
    />
  );
}

const getFilterFields = () => {
  const filtersFields: {
    key?: string;
    placeholder?: string;
    label?: string;
    testId?: string;
    customComponent?: (state, onChange) => JSX.Element;
  }[] = [
    {
      key: 'addressCity',
      placeholder: 'Type city',
      label: 'City',
      testId: 'filter-city',
    },
    {
      key: 'locationName',
      placeholder: 'Type project name',
      label: 'Project name',
      testId: 'filter-project',
    },
    {
      key: 'lotNumber',
      placeholder: 'Type lot number',
      label: 'Lot number',
      testId: 'filter-lot-number',
    },
    {
      key: 'notes',
      placeholder: 'Type note',
      label: 'Notes',
      testId: 'filter-notes',
    },
  ];

  if (hasUserRole(AccountRoles.SUPER_ADMINISTRATOR)) {
    filtersFields.unshift({
      key: 'companyName',
      label: 'Company',
      customComponent: CompanySelectDropdown,
    });
  }

  return filtersFields;
};

const LocationsPage: React.FC<ILocationsPageProps> = (props: ILocationsPageProps) => {
  const { className } = props;
  const history = useHistory();
  const { search } = useLocation();
  const { locationId } = useParams() as { locationId: string | undefined };
  const searchParams = React.useMemo(() => new URLSearchParams(search) as URLSearchParams, [
    search,
  ]);
  const getLocationId = (): string => {
    const isAddingLocation = locationId === 'addLocation';
    return isAddingLocation ? undefined : locationId;
  };
  const [pageState, setPageState] = React.useState<ILocationsPageState>({
    locations: [],
    total: 0,
    filter: {
      status: searchParams.get('status') || LocationStatusEnum.Draft,
    },
    sort: { key: 'date', sortDirection: 'asc' },
    formModalState: {
      visible: !!locationId,
      id: getLocationId(),
    },
    deleteModalState: { visible: false },
    duplicateModalState: { visible: false },
  });
  const setDuplicateModalState = (state: IModalState) =>
    setPageState({ ...pageState, duplicateModalState: state });
  const setDeleteModalState = (state: IModalState) =>
    setPageState({ ...pageState, deleteModalState: state });
  const getLocationRowOptions = (status = LocationStatusEnum.Draft) => {
    const statusObject = locationStatuses.find(
      ({ number }) => number === Object.values(LocationStatusEnum).findIndex(st => st === status),
    );

    const options: ILocationRowOption[] = [
      {
        value: 'Duplicate',
        onClick: row => {
          setDuplicateModalState({ id: row.id, visible: true });
        },
        testId: 'dublicate_button',
      },
      {
        value: 'Configure devices',
        onClick: row => {
          history.push(`/location/${row.id}/configuration/connections`);
        },
        testId: 'configure_divices_button',
      },
      {
        value: 'Scenes',
        onClick: row => {
          history.push(`/location/${row.id}/configuration/scenes`);
        },
        testId: 'scenes_button',
      },
      {
        value: 'Homeowner Panel',
        onClick: row => {
          history.push(`/location/${row.id}`);
        },
        testId: 'home_owner_panel_button',
      },
    ];

    if (statusObject && statusObject.locationCanBeDeleted) {
      options.push({
        value: 'Remove',
        onClick: row => {
          setDeleteModalState({ id: row.id, visible: true });
        },
      });
    }

    return options;
  };

  React.useEffect(() => {
    loadData(true, pageState, setPageState);
  }, []);

  React.useEffect(
    () =>
      setPageState({
        ...pageState,
        formModalState: {
          visible: !!locationId,
          id: getLocationId(),
        },
      }),
    [locationId],
  );

  React.useEffect(() => {
    let statusParam = searchParams.get('status');

    if (statusParam === pageState?.filter?.status) {
      return;
    }

    if (!statusParam || statusParam === 'null') {
      statusParam = LocationStatusEnum.Draft;
      searchParams.set('status', LocationStatusEnum.Draft);
      history.replace({ search: searchParams.toString() });
    }

    loadData(
      true,
      {
        ...pageState,
        filter: { ...pageState.filter, status: statusParam },
      },
      setPageState,
    );
  }, [searchParams, pageState]);

  return (
    <div className={className ?? ''}>
      <div className="d-flex bd-highlight">
        <div className="flex-grow-1 bd-highlight">
          <div className="h1-medium text-brand-highlight">Locations</div>
        </div>
        <div className="bd-highlight">
          <Button
            type={ButtonType.BUTTON}
            text="Add new location"
            onClick={() =>
              history.push(`/locations/addLocation?status=${searchParams.get('status')}`)
            }
          />
        </div>
      </div>
      <TabSwitch
        className="status-switch"
        options={[
          { key: LocationStatusEnum.Draft, title: 'Draft' },
          { key: LocationStatusEnum.Pending, title: 'Pending' },
          { key: LocationStatusEnum.Install, title: 'Install' },
          { key: LocationStatusEnum.Operational, title: 'Operational' },
          { key: LocationStatusEnum.Maintenance, title: 'Maintenance' },
          { key: LocationStatusEnum.Suspended, title: 'Suspended' },
          { key: LocationStatusEnum.Stopped, title: 'Stopped' },
        ]}
        onChange={e => history.push(`/locations?status=${e.key}`)}
        activeTab={searchParams.get('status')}
      />
      <TableFilter
        componentTestId="filters_menu"
        cancelButtonId="filters_cancel_button"
        clearButtonId="filters_menu_clear_button"
        className="filter"
        onSave={e =>
          loadData(
            true,
            { ...pageState, filter: { ...e, status: pageState.filter.status } },
            setPageState,
          )
        }
        options={getFilterFields()}
      />
      <RemoteTable
        tableTestId="locations_table"
        className="table locations-table"
        onSortChange={e => onSortChange(e, pageState, setPageState)}
        onRowClick={row => {
          history.push(`/locations/${row.id}?status=${searchParams.get('status')}`);
        }}
        columns={getLocationColumns(getLocationRowOptions(pageState.filter.status))}
        data={pageState.locations}
        onLoadMore={() => loadData(false, pageState, setPageState)}
        showMoreButton={pageState.locations.length < pageState.total}
        emptyTableComponent={
          <div className="empty-table-content">
            <LocationIcon className="empty-list-indicator-icon" height="18vh" width="18vh" />
            <p className="empty-table-primary-label">No locations</p>
            <p className="empty-table-secondary-label">
              All locations will be displayed here. <br />
              Refresh tabs to browse locations or <b>Add new location</b>
            </p>
          </div>
        }
      />
      <LocationEditModal
        state={pageState.formModalState}
        reloadData={() => loadData(true, { ...pageState }, setPageState)}
        onUpdate={(loc: ILocationEdit) => {
          if (pageState.locations) {
            const locIndex = pageState.locations.findIndex(u => u.id === loc.id);
            if (locIndex !== -1) {
              const newList = [...pageState.locations];
              newList[locIndex].addressCity = loc.details.addressCity;
              newList[locIndex].addressStreet = loc.details.addressStreet;
              newList[locIndex].addressHouseNumber = loc.details.addressHouseNumber;
              newList[locIndex].addressPostalCode = loc.details.addressPostalCode;
              newList[locIndex].locationName = loc.details.name;
              setPageState({ ...pageState, locations: newList });
            }
          }
        }}
        onHide={(reload, err) => {
          if (reload) {
            loadData(true, { ...pageState, formModalState: { visible: false } }, setPageState);
          }

          history.push(`/locations?status=${searchParams.get('status')}`);
        }}
      />
      <DuplicateModal
        state={pageState.duplicateModalState}
        onHide={(reload, err) => {
          if (reload) {
            loadData(true, { ...pageState, duplicateModalState: { visible: false } }, setPageState);
          } else {
            setDuplicateModalState({ visible: false });
            if (err) {
              // TODO Toast
            }
          }
        }}
      />
      <DeleteModal
        onHide={reload => {
          if (reload) {
            loadData(true, { ...pageState, deleteModalState: { visible: false } }, setPageState);
          } else {
            setDeleteModalState({ visible: false });
          }
        }}
        state={pageState.deleteModalState}
      />
    </div>
  );
};

export default LocationsPage;
