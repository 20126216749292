import * as React from 'react';
import { showLoader, ModalLoader, LoaderMode, hideLoader } from '@streda/web_components';

export const loadData = (fetchingData: boolean, mode?: LoaderMode, containerId?: string) => {
  if (fetchingData) {
    return showLoader(<ModalLoader />, {
      mode: mode || LoaderMode.COVER,
      loaderContainerSelector: containerId || '.location_analytics_table',
      zIndex: 0,
    });
  }
  return hideLoader();
};

export default loadData;
