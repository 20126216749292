import * as React from 'react';
import { BasicConnectionsPanel } from '@streda/web_components';

function BasicConnections(props) {
  const { rooms, docks, deviceConnectionsState, location } = props;
  const [deviceConnections, setDeviceConnections] = deviceConnectionsState;

  return (
    <div>
      <div className="configuration-panel-top-bar-mock" />
      <div id="DevicesConfiguration" className="configuration-panel-wrapper">
        <BasicConnectionsPanel
          rooms={rooms}
          docks={docks}
          updateConnectionsHandler={newConnections => {
            setDeviceConnections(newConnections);
          }}
          deviceConnections={deviceConnections}
          location={location}
          previewMode={false}
        />
      </div>
    </div>
  );
}

export default BasicConnections;
