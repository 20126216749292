import * as React from 'react';
import {
  UploadAttachment,
  LabeledSelect,
  ModelInput,
  EditableSection,
  Textarea,
  onModelInputChange,
} from '@streda/web_components';
import ValidationStatus from '../../../shared/validation/validation-status/validation-status';
import { ILocationDetailsProps } from './location-details.types';
import { onFileDelete } from './location-details.logic';

const LocationDetails: React.FC<ILocationDetailsProps> = (props: ILocationDetailsProps) => {
  const getModeTitle = (editMode: boolean) => {
    return editMode ? 'edit' : 'add';
  };
  const {
    locationDetails,
    locationTypes,
    onChange,
    locationId,
    validator,
    enableValidation,
    files,
    onFilesChange,
    onFileDownload,
  } = props;
  return (
    <EditableSection
      model={locationDetails}
      modelId={locationId}
      title="Location details"
      validator={validator}
      enableValidation={enableValidation}
      onChange={onChange}
      onCancel={() => {
        onFilesChange(files.filter(file => !file.file));
      }}
      testId="location_details"
    >
      {(model, editMode, setModel, invalidFields) => {
        const isContactPhoneInvalid = invalidFields?.some(
          iField => iField.concerns === 'contactDetailsPhoneNumber',
        );
        const isContactEmailInvalid = invalidFields?.some(
          iField => iField.concerns === 'contactDetailsEmail',
        );

        return (
          <>
            {invalidFields?.length ? (
              <ValidationStatus
                className="m-t-16"
                iconName="validation-status"
                message="Please fill out correctly invalid fields"
              />
            ) : null}
            <ModelInput
              disabled={editMode}
              onChange={setModel}
              model={model}
              propName="name"
              label="Project name"
              error={invalidFields?.some(iField => iField.concerns === 'name')}
              id={`${getModeTitle(editMode)}_loc_project_name`}
            />
            <ModelInput
              disabled={editMode}
              onChange={setModel}
              model={model}
              propName="lotNumber"
              label="Lot number"
              error={invalidFields?.some(iField => iField.concerns === 'lotNumber')}
              id={`${getModeTitle(editMode)}_loc_lot_number`}
            />
            <LabeledSelect
              disabled={editMode}
              value={locationTypes.find(t => t.value === model.typeId) ?? { value: '', label: '' }}
              onChange={e => setModel({ ...model, typeName: e.label, typeId: e.value })}
              label="Location type"
              options={locationTypes}
              error={invalidFields?.some(iField => iField.concerns === 'typeId')}
              testId={`${getModeTitle(editMode)}_loc_type`}
            />
            <div className="inputs-inline">
              <ModelInput
                disabled={editMode}
                className="input-small"
                onChange={setModel}
                model={model}
                propName="addressPostalCode"
                label="ZIP code"
                error={invalidFields?.some(iField => iField.concerns === 'addressPostalCode')}
                id={`${getModeTitle(editMode)}_loc_zip`}
              />
              <ModelInput
                disabled={editMode}
                onChange={setModel}
                model={model}
                propName="addressCity"
                label="City"
                error={invalidFields?.some(iField => iField.concerns === 'addressCity')}
                id={`${getModeTitle(editMode)}_loc_city`}
              />
            </div>
            <div className="inputs-inline">
              <ModelInput
                disabled={editMode}
                onChange={setModel}
                model={model}
                propName="addressStreet"
                label="Street"
                error={invalidFields?.some(iField => iField.concerns === 'addressStreet')}
                id={`${getModeTitle(editMode)}_loc_street`}
              />
              <ModelInput
                disabled={editMode}
                className="input-small"
                onChange={setModel}
                model={model}
                propName="addressHouseNumber"
                label="Number"
                error={invalidFields?.some(iField => iField.concerns === 'addressHouseNumber')}
                id={`${getModeTitle(editMode)}_loc_number`}
              />
            </div>
            <div className="inputs-inline">
              <ModelInput
                disabled={editMode}
                className="input-small"
                onChange={setModel}
                model={model}
                propName="contactDetailsEmail"
                errorMessage={isContactEmailInvalid && 'Use correct email format'}
                label="Email"
                error={isContactEmailInvalid}
                id={`${getModeTitle(editMode)}_loc_email`}
              />
              <ModelInput
                disabled={editMode}
                onChange={setModel}
                model={model}
                propName="contactDetailsPhoneNumber"
                label="Phone number"
                error={isContactPhoneInvalid}
                errorMessage={
                  isContactPhoneInvalid && 'Correct format +00 123-456-789 / +00 123456789'
                }
                id={`${getModeTitle(editMode)}_loc_phone`}
              />
            </div>
            <Textarea
              label="Notes"
              onChange={e => onModelInputChange(e, setModel, model, 'notes')}
              value={model?.notes}
              disabled={editMode}
              id={`${getModeTitle(editMode)}_loc_notes`}
            />
            <UploadAttachment
              disabled={editMode}
              onFileDownload={onFileDownload}
              title="Specification document"
              className="loc-spec-attachments"
              value={files}
              type="multi"
              onChange={onFilesChange}
              onDelete={file => onFileDelete(locationId, files, file, onFilesChange)}
              accept=".pdf, .svg, .txt, .png, .jpg, .jpeg, .docx"
              tooltipInfo="This file up to 100 MB, with non<br />viruses and format: pdf, jpg,<br />jpeg, png, svg, txt, docx"
            />
          </>
        );
      }}
    </EditableSection>
  );
};

export default LocationDetails;
