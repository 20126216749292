import { ILocationRoom, ILocationEdit } from '../../../../../../models/location';

export const onRoomsChange = (
  newRooms: ILocationRoom[],
  locationModel: ILocationEdit,
  onChange: (newMode: ILocationEdit) => void,
) => {
  if (newRooms && locationModel && onChange) {
    const newModel = { ...locationModel, rooms: newRooms };
    onChange(newModel);
  }
};

export default {
  onRoomsChange,
};
