import {
  DockDto,
  ISelectOption,
  SnapInsGroupsEnums,
  SnapInsGroupType,
} from '@streda/web_components';
import { ILocationDock } from '../../../../../models/location';
import { DockCableTypeEnum } from '../cable-type-dropdown/cable-type-dropdown.types';

export const handleOnDelete = (onDelete: () => void) => {
  if (onDelete) {
    onDelete();
  }
};

export const handleOnChange = (
  newDock: ILocationDock,
  onChange: (dock: ILocationDock) => Promise<void>,
) => {
  if (newDock && onChange) {
    return onChange(newDock);
  }

  return Promise.reject(new Error());
};

export const handleOnSnapInChange = (
  dock: ILocationDock,
  updatedDock,
  cableType: DockCableTypeEnum,
  onChange: (dock: ILocationDock) => Promise<void>,
) => {
  const newDock: ILocationDock = {
    ...dock,
    ...updatedDock,
    cableType,
  };
  handleOnChange(newDock, onChange);
};

export const handleOnSnapInPositionChange = (
  dock: ILocationDock,
  snapInPosition: ISelectOption,
  onChange: (dock: ILocationDock) => Promise<void>,
  setSnapInPosition: (snapIn: ISelectOption) => void,
) => {
  const newDock: ILocationDock = {
    ...dock,
    positionId: snapInPosition.value,
  };
  handleOnChange(newDock, onChange).then(() => setSnapInPosition(snapInPosition));
};

export const getDockCableType = (
  dockCode: string,
  currentCableType: DockCableTypeEnum,
): DockCableTypeEnum => {
  const cableTypeSymbol: SnapInsGroupType = dockCode.charAt(2);

  if (cableTypeSymbol === SnapInsGroupsEnums['0']) {
    return DockCableTypeEnum.Dockless;
  }

  return currentCableType === DockCableTypeEnum.Dockless
    ? DockCableTypeEnum.Hybrid
    : currentCableType;
};
