import * as React from 'react';
import { ActionModal, SnapInIcon } from '@streda/web_components';

const SendConfigurationModal = props => {
  const { onHide, onConfirm, show } = props;
  return (
    <ActionModal
      onHide={() => onHide(false)}
      onConfirm={() => onConfirm()}
      show={show}
      icon={<SnapInIcon width="100px" height="100px" />}
      title=""
      header="Send location to SCB's?"
      description="Configuration of the location will be send to all assigned SCB's."
    />
  );
};

export default SendConfigurationModal;
