import { getCompanyApi, ISelectOption, ToastHelper, ToastType } from '@streda/web_components';
import { AxiosResponse } from 'axios';
import { getAccount } from '../../../../utils/auth.utils';
import { assignLocationToCompany } from '../location-edit-modal/location-edit-modal.logic';

export const getCompaniesOptions = (): Promise<ISelectOption[]> =>
  getCompanyApi(getAccount())
    .companyGetCompanies()
    .then(result => {
      const companiesOptions = result.data.data.map(({ name, id }) => ({
        value: id,
        label: name,
      }));
      return Promise.resolve(companiesOptions);
    })
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during getting companies. Please try again or contact with system administrator',
        error,
      );
      return Promise.resolve([]);
    });

export const assignCompanyToLocation = (
  locationId: string,
  companyId: string,
): Promise<AxiosResponse> =>
  assignLocationToCompany(locationId, companyId).catch(error => {
    ToastHelper(
      ToastType.DANGER,
      'Error during assigning company. Please try again or contact with system administrator',
      error,
    );
    return Promise.reject(error);
  });

export default {
  getCompaniesOptions,
};
