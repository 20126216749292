import {
  Toast,
  ConnectionConfig,
  DevicesConnection,
  ToastHelper,
  ToastType,
} from '@streda/web_components';
import { getConfigurationApi, getScenesApi } from '../../../../../utils/api.utils';
import { getAccount } from '../../../../../utils/auth.utils';

export const sendLocationToBcb = locationId =>
  getConfigurationApi(getAccount())
    .configurationSendLocationToBcbs(locationId)
    .then(() => {
      Toast.showToast({
        message: "Success!. Configuration has been send to SCB's",
        colorType: 'Success',
      });

      return Promise.resolve();
    })
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during sending configuration. Please try again or contact with system administrator',
        error,
      );

      return Promise.reject(error);
    });

export const saveConfigurations = (locationId, connections: DevicesConnection[]) =>
  getConfigurationApi(getAccount())
    .configurationReplaceConnectionConfigurations(
      locationId,
      false,
      connections.map(connection => connection.connection),
    )
    .then(() => {
      Toast.showToast({
        message: 'Success!. Configuration has been saved',
        colorType: 'Success',
      });
      return Promise.resolve();
    })
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during saving configuration. Please try again or contact with system administrator',
        error,
      );

      return Promise.reject(error);
    });

export const saveScene = (locationId, scene) => {
  const sceneObject = {
    ...scene,
    actions: scene.actions.map(({ action, actionParameters, targetDevice }) => ({
      action,
      actionParameters,
      targetDevice,
    })),
  };
  const saveSceneFunction = scene.id
    ? getScenesApi(getAccount()).sceneUpdateScene(locationId, sceneObject)
    : getScenesApi(getAccount()).sceneAddScene(locationId, sceneObject);

  return saveSceneFunction
    .then(() => {
      Toast.showToast({
        message: 'Success! Scene has been saved',
        colorType: 'Success',
      });
      return Promise.resolve();
    })
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during saving scene. Please try again or contact with system administrator',
        error,
      );

      return Promise.reject(error);
    });
};

export const removeDevicePairConnection = (locationId: string, configurationId: string) => {
  return getConfigurationApi(getAccount()).configurationRemoveConnectionConfiguration(
    locationId,
    configurationId,
  );
};

export const getConfiguration = (locationId: string): Promise<ConnectionConfig[]> => {
  return new Promise((resolve, reject) => {
    getConfigurationApi(getAccount())
      .configurationGetConfigureConnections(locationId)
      .then(result => {
        resolve(result.data);
      })
      .catch(err => {
        window.console.error(err);
        reject(err);
      });
  });
};
