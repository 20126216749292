import * as React from 'react';
import { Button, ButtonType, PinCodeInput } from '@streda/web_components';

const LocationSettingsAuth = props => {
  const { onPinCodeChange } = props;
  const [pinCode, setPinCode] = React.useState(null);
  const [pinCodeInvalid, setPinCodeInvalid] = React.useState(false);

  return (
    <div className="location-settings-auth">
      <h5>Login to system settings</h5>
      {pinCodeInvalid && (
        <p className="location-settings-auth-invalid-code">
          Oups! Invalid code <br /> Please try again
        </p>
      )}
      <PinCodeInput invalid={pinCodeInvalid} onChange={code => setPinCode(code)} />
      <Button
        className="location-settings-auth-login-button"
        type={ButtonType.BUTTON}
        text="Login to settings"
        onClick={() => {
          const pinCodeValid = onPinCodeChange(pinCode);

          if (pinCodeValid) {
            return;
          }

          setPinCodeInvalid(true);
        }}
      />
    </div>
  );
};

export default LocationSettingsAuth;
