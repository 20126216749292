import * as React from 'react';
import { Select, Toast } from '@streda/web_components';
import EventInfo from '../../../shared/events/event-info/event-info';
import { IInstallationStatusProps, installationStatusesFlow } from './installation-status.types';
import {
  getAllowedOptions,
  getHighlightedStatusIndex,
  getStatusProgress,
  validateStatusChange,
} from './installation-status.logic';

const InstallationStatus: React.FC<IInstallationStatusProps> = (
  props: IInstallationStatusProps,
) => {
  const { status, lastUpdate, onStatusChange, className, location } = props;
  const allowedOptions = getAllowedOptions();
  const statusValue =
    allowedOptions &&
    allowedOptions.length &&
    allowedOptions.some(s => s.value === status.toString())
      ? allowedOptions.find(s => s.value === status.toString())
      : null;

  const changeStatusWithValidation = (statusToChange: number) => {
    const { valid, msg } = validateStatusChange(status, statusToChange, location);
    if (valid) {
      Toast.showToast({
        message: 'Status change in progress..',
        colorType: 'Success',
      });
      onStatusChange(statusToChange);
    } else {
      Toast.showToast({
        message: msg,
        colorType: 'Danger',
      });
    }
  };
  return (
    <div className={className ?? ''}>
      <div className="installation-status-header">
        <div>Installation status</div>
        <Select
          className="installation-status-select"
          value={statusValue}
          onChange={e => {
            if (e.value) {
              changeStatusWithValidation(+e.value);
            }
          }}
          options={allowedOptions}
          testId="location_details_status"
        />
      </div>
      <div className="installation-labels">
        {installationStatusesFlow.map((s, index) => {
          return (
            <React.Fragment key={s}>
              <div className={getHighlightedStatusIndex(statusValue) === index ? 'active' : ''}>
                {`${index + 1}. ${s}`}
              </div>
              {index !== installationStatusesFlow.length - 1 && <div>{'>'}</div>}
            </React.Fragment>
          );
        })}
      </div>
      <div className="inst-status-progress">
        <div
          className="inst-status-bar"
          style={{
            width: `${getStatusProgress(getHighlightedStatusIndex(statusValue))}%`,
          }}
        />
      </div>
      {lastUpdate && <EventInfo className="is-event-info" eventInfo={lastUpdate} />}
    </div>
  );
};

export default InstallationStatus;
