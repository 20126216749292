import * as React from 'react';
import { hot } from 'react-hot-loader';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { IAccount, UnsupportedScreenModal, useMatchBreakpoints } from '@streda/web_components';
import '../assets/scss/styles.scss';
import LoginPage from './pages/login-page/login.page';
import { getAccount } from '../utils/auth.utils';
import MainView from './main-view';
import InfoPagesApp from './pages/privacy-policy/privacy-policy-app';

const App = () => {
  const [account, setAccount] = React.useState<IAccount>(getAccount());

  const loginCallback = () => {
    const storageAccount = getAccount();
    if (
      !account ||
      storageAccount.accessToken !== account.accessToken ||
      storageAccount.userName !== account.userName
    ) {
      setAccount(storageAccount);
    }
  };

  const authorized = account && account.accessToken && account.userName;

  const { isUnsupported } = useMatchBreakpoints();

  return (
    <div id="app" className="full-height">
      <>
        <UnsupportedScreenModal visible={isUnsupported} />
        <Router>
          <Switch>
            <Route path="/login">
              <LoginPage loginCallback={loginCallback} />
            </Route>
            <Route path="/privacypolicy" component={InfoPagesApp} />
            {authorized && (
              <Route>
                <MainView />
              </Route>
            )}
            {!authorized && (
              <Route>
                <Redirect to="/login" />
              </Route>
            )}
          </Switch>
        </Router>
      </>
    </div>
  );
};

declare let module: object;

export default hot(module)(App);
