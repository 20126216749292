import { getCompanyApi, NewCompanyDto, Toast, ToastHelper, ToastType } from '@streda/web_components';
import { AxiosResponse } from 'axios';
import { getAccount } from '../../../../utils/auth.utils';

export const addCompany = (company: NewCompanyDto): Promise<boolean> =>
  getCompanyApi(getAccount())
    .companyCreateCompany(company)
    .then(() => {
      Toast.showToast({
        message: 'Company has been added.',
        colorType: 'Success',
      });
      return Promise.resolve(true);
    })
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during adding company. Please try again or contact with system administrator',
        error,
      );
      return Promise.resolve(false);
    });

export const updateCompany = (companyId: string, companyData: NewCompanyDto): Promise<boolean> =>
  getCompanyApi(getAccount())
    .companyUpdateCompany(companyId, companyData)
    .then(() => {
      Toast.showToast({
        message: 'Company has been updated.',
        colorType: 'Success',
      });
      return Promise.resolve(true);
    })
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during edit company. Please try again or contact with system administrator',
        error,
      );
      return Promise.resolve(false);
    });

export const getCompany = (companyId: string): Promise<AxiosResponse<NewCompanyDto>> =>
  getCompanyApi(getAccount())
    .companyGetCompany(companyId)
    .then(company => {
      return Promise.resolve(company);
    })
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during getting company details. Please try again or contact with system administrator',
        error,
      );
      return Promise.reject(error);
    });

export default {
  addCompany,
  updateCompany,
  getCompany,
};
