import * as React from 'react';
import {
  Button,
  ButtonType,
  EditableListElement,
  hideLoader,
  ISelectOption,
  LabeledSelect,
  ModalLoader,
  showLoader,
  Toast,
} from '@streda/web_components';
import styled from 'styled-components';
import {
  IInstaller,
  ILocationEditModalState,
} from '../location-edit-modal/location-edit-modal.types';
import { assignInstaller, getInstallersOptions, removeInstall } from './installers-selection.logic';

export interface IAssignInstallerProps {
  onChange: (installers: IInstaller[]) => void;
  state: ILocationEditModalState;
}

const AssignedInstallersLabel = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray30};
  margin-top: 24px;
`;

const InstallersSelection: React.FC<IAssignInstallerProps> = ({ onChange, state }) => {
  const [installers, setInstallers] = React.useState<ISelectOption[]>([]);
  const [selectedInstaller, setSelectedInstaller] = React.useState<ISelectOption>();
  const [pending, setPending] = React.useState<boolean>(false);

  React.useEffect(() => {
    getInstallersOptions(state.company).then(response => {
      const installersOptions = response.data.data.map(({ displayName, id }) => ({
        label: displayName,
        value: id,
      }));

      setInstallers(installersOptions);
    });
  }, [state.company]);

  const onAssignInstallerClick = () => {
    if (state.data.id) {
      setPending(true);
      assignInstaller(state.data.id, selectedInstaller.value)
        .then(() => {
          Toast.showToast({
            colorType: 'Success',
            message: 'Installer has been assigned.',
          });
          onChange([
            {
              name: selectedInstaller.label,
              activeDirectoryId: selectedInstaller.value,
            },
            ...state.installers,
          ]);
          setSelectedInstaller(undefined);
        })
        .finally(() => setPending(false));
    } else {
      onChange([
        {
          name: selectedInstaller.label,
          activeDirectoryId: selectedInstaller.value,
        },
        ...state.installers,
      ]);
      setSelectedInstaller(undefined);
    }
  };
  const onInstallersRemoveClick = userId => {
    if (state.data.id) {
      showLoader(<ModalLoader />);
      removeInstall(state.data.id, userId)
        .then(() => {
          Toast.showToast({
            colorType: 'Success',
            message: 'Installer has been removed.',
          });
          const filteredInstallers = state.installers.filter(
            installer => installer.activeDirectoryId !== userId,
          );
          onChange(filteredInstallers);
        })
        .then(() => hideLoader());
    } else {
      const filteredInstallers = state.installers.filter(
        installer => installer.activeDirectoryId !== userId,
      );
      onChange(filteredInstallers);
    }
  };

  return (
    <div className="w-420">
      <div className="h2-medium m-t-24">Installers</div>
      <div className="inline-flex-container justify-content-space-between align-bottom">
        <LabeledSelect
          label="Installer name"
          labelClassName="color-d3"
          className="w-100p"
          placeholder="Select Installer..."
          value={selectedInstaller}
          options={installers}
          onChange={value => setSelectedInstaller(value)}
        />
        <Button
          className="m-l-12"
          type={ButtonType.BUTTON}
          text="Assign"
          disabled={!selectedInstaller}
          onClick={onAssignInstallerClick}
          pending={pending}
        />
      </div>
      <AssignedInstallersLabel>Assigned installers:</AssignedInstallersLabel>
      {state.installers &&
        state.installers.map(({ name, activeDirectoryId }) => (
          <EditableListElement
            id={`installer-${activeDirectoryId}`}
            className="h-36"
            text={name}
            onDelete={() => onInstallersRemoveClick(activeDirectoryId)}
            editable={false}
            removable
          />
        ))}
    </div>
  );
};

export default InstallersSelection;
