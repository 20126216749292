import * as React from 'react';
import { ActionModal, SnapInIcon } from '@streda/web_components';

const DeleteListElementModal = props => {
  const { onHide, onDelete, show } = props;

  return (
    <ActionModal
      onHide={() => onHide(false)}
      onConfirm={() => onDelete()}
      show={show}
      icon={<SnapInIcon />}
      title=""
      header="Delete dock?"
      description={
        <>
          All snap-ins, devices & connections
          <br /> attached to the dock will be erased.
          <br /> Are you sure want to delete?
        </>
      }
      altButton2Text="Delete"
    />
  );
};

export default DeleteListElementModal;
