import _ = require('lodash');
import * as React from 'react';
import {
  getAccount,
  getUserApi,
  HomeOwnerDto,
  LoaderMode,
  ToastHelper,
  ToastType,
} from '@streda/web_components';
import { loadData } from '../analytics/analytics-logic';
import { HomeownerSortKey } from './homeowners.types';

const useHomeowners = (
  page: number,
  pageSize?: number,
  sortKey?: HomeownerSortKey,
  descending?: boolean,
): { homeowners: HomeOwnerDto[]; totalHomeowners: number; loading: boolean; error?: boolean } => {
  const [homeowners, setHomeowners] = React.useState<HomeOwnerDto[]>([]);
  const [totalHomeowners, setTotalHomeowners] = React.useState<number>(0);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    const sortHomeowners = setTimeout(() => {
      if (!_.isEmpty(homeowners) && sortKey) {
        setHomeowners(prevState => {
          // @ts-ignore
          const sortedHomeowners = prevState.sort((ho1, ho2) => {
            const existingProperties = !_.isNil(ho1[sortKey]) && !_.isNil(ho2[sortKey]);
            if (descending) {
              return existingProperties ? ho2[sortKey].localeCompare(ho1[sortKey]) : -1;
            }
            return existingProperties ? ho1[sortKey].localeCompare(ho2[sortKey]) : -1;
          });
          return sortedHomeowners;
        });
        setLoading(false);
      }
    }, 500);
    return () => clearInterval(sortHomeowners);
  }, [sortKey, descending]);

  React.useEffect(() => {
    const getHomeowners = async () => {
      try {
        loadData(true, LoaderMode.COVER, '.homeowners-table');
        const { data } = await getUserApi(getAccount()).userGetHomeOwners(page, pageSize);
        setTotalHomeowners(data.recordsTotalNumber);
        setLoading(false);
        setHomeowners(data.data as HomeOwnerDto[]);
      } catch (err) {
        const { response = {} } = err;
        setLoading(false);
        setError(true);
        setHomeowners([]);
        if (response.status !== 400) {
          ToastHelper(
            ToastType.DANGER,
            'Error during getting homeowners. Please try again or contact with system administrator',
            err,
          );
        }
      }
    };
    getHomeowners();
  }, [page, pageSize]);

  return {
    homeowners,
    totalHomeowners,
    loading,
    error,
  };
};

export default useHomeowners;
