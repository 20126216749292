import * as React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useHistory, Switch, withRouter, Route, useRouteMatch } from 'react-router-dom';
import {
  Button,
  RowOptions,
  IModalState,
  LeaveSceneModal,
  InfoIcon,
  ConfigSyncIndicator,
  getConnectionsConfigurationSyncStatus,
  variants,
  ActionModal,
  DevicesConnection,
} from '@streda/web_components';
import { IDevicesConfigurationProps } from './configuration-panel.types';
import {
  getConfiguration,
  saveConfigurations,
  sendLocationToBcb,
} from './configuration-panel.logic.api';
import LocationEditModal from '../../../locations/location-edit-modal/location-edit-modal';
import BasicConnections from '../connections/basic/basic-connections';
import SaveConfigurationModal from './save-configuration-modal/save-configuration-modal';
import SendConfigurationModal from './send-configuration-modal/send-configuration-modal';
import Scenes from '../scenes/scenes';
import AdvancedConnections from '../connections/advanced/advanced-connections';
import {
  ConfigurationsActionBtnsContainer,
  HeaderOptionButton,
  PanelsNavigationButtons,
} from './configuration-panel.components';
import ConfigurationInfoModal from './configuration-info-modal/configuration-info-modal';

const ConfigurationPanel: React.FC<IDevicesConfigurationProps> = (
  props: IDevicesConfigurationProps,
) => {
  const { url } = useRouteMatch();
  const {
    className,
    locationDetails,
    locationId,
    rooms,
    docks,
    location,
    onConfigurationChange,
  } = props;
  const { pathname } = location;
  const { details, assignedBcbs } = locationDetails;
  const history = useHistory();
  const [formModalState, setFormModalState] = React.useState<IModalState>({ visible: false });
  const [saveConfigurationModalState, setSaveConfigurationModalState] = React.useState({
    visible: false,
  });
  const [sendConfigModalState, setSendConfigModalState] = React.useState({
    visible: false,
  });
  const [leaveSceneModalState, setLeaveSceneModalState] = React.useState({
    visible: false,
    viewToMove: null,
  });
  const deviceConnectionsState = React.useState<DevicesConnection[]>([]);
  const [deviceConnections, setDeviceConnections] = deviceConnectionsState;
  const [lastSavedDevicesConnections, setLastSavedDevicesConnections] = React.useState([]);
  const [showDiscardModal, setShowDiscardModal] = React.useState(false);
  const [isConfigurationSynced, setIsConfigurationSynced] = React.useState<boolean>(false);
  const [showConfigInfoModal, setShowConfigInfoModal] = React.useState<boolean>();

  React.useEffect(() => {
    getConnectionsConfigurationSyncStatus(locationId).then(result =>
      setIsConfigurationSynced(result),
    );
  }, [locationId]);

  React.useEffect(() => {
    getConfiguration(locationId).then(result => {
      const parsedConnections = result.map(connection => new DevicesConnection(connection));
      setDeviceConnections([...parsedConnections]);
      setLastSavedDevicesConnections([...parsedConnections]);
    });
  }, [locationId]);

  return (
    <div className={className ?? ''}>
      <div className="devices-configuration-box">
        <div className="inline-flex-container justify-content-space-between devices-configuration-top-bar">
          <div className="flex-container align-center">
            <RowOptions
              options={[
                {
                  value: 'Go to locations list',
                  onClick: () => {
                    if (
                      [`${url}/scenes/create-scene`, `${url}/scenes/edit-scene`].includes(pathname)
                    ) {
                      setLeaveSceneModalState({
                        visible: true,
                        viewToMove: '/locations',
                      });
                    } else {
                      history.push('/locations');
                    }
                  },
                },
                {
                  value: 'Location details',
                  onClick: () => setFormModalState({ id: locationId, visible: true }),
                },
              ]}
            />
            <span
              className="devices-configuration-location-description ellipsis"
              data-tooltip-id="ConfigPanelTooltip"
              data-tooltip-content={`${details.name} (${details.lotNumber})`}
            >
              {details.name} ({details.lotNumber})
            </span>
            <div
              className="devices-configuration-tooltip-icon-wrapper"
              data-tooltip-id="ConfigPanelTooltip"
              data-tooltip-content={`SCB: ${
                assignedBcbs && assignedBcbs.length ? assignedBcbs[0].bcbId : 'unknown'
              }`}
            >
              <InfoIcon />
            </div>
          </div>
          <div className="flex-container align-center">
            <ConfigSyncIndicator synchronized={isConfigurationSynced} short />
            <PanelsNavigationButtons locationId={locationId} />
            <Switch>
              <Route path={[`${url}/scenes/create-scene`, `${url}/scenes/edit-scene`]}>
                <div className="configuration-panel-actions-btns-container">
                  <HeaderOptionButton
                    type="button"
                    onClick={() => {
                      setLeaveSceneModalState({
                        visible: true,
                        viewToMove: `/location/${locationId}/configuration/scenes`,
                      });
                    }}
                    active
                  >
                    Back
                  </HeaderOptionButton>
                </div>
              </Route>
            </Switch>
          </div>
        </div>

        <Route path={[`${url}/connections`, `${url}/connections-advanced`]}>
          <ConfigurationsActionBtnsContainer>
            <Button
              type="button"
              text="Discard changes"
              onClick={() => setShowDiscardModal(true)}
              testId="save-configuration-button"
              colorType={variants.WHITE}
            />
            <Button
              type="button"
              text="Save changes"
              onClick={() => {
                const isConfigPanelInfoConfirmed = localStorage.getItem(
                  'connectionsConfigPanelInfoConfirmed',
                );

                if (isConfigPanelInfoConfirmed) {
                  setSaveConfigurationModalState({ visible: true });
                } else {
                  setShowConfigInfoModal(true);
                }
              }}
              testId="save-configuration-button"
            />
            <Button
              type="button"
              text="Activate changes"
              onClick={() => setSendConfigModalState({ visible: true })}
              testId="save-configuration-button"
            />
          </ConfigurationsActionBtnsContainer>
        </Route>

        <Switch>
          <Route path={`${url}/connections`}>
            <BasicConnections
              location={locationDetails}
              rooms={rooms}
              docks={docks}
              deviceConnectionsState={deviceConnectionsState}
            />
          </Route>
          <Route path={`${url}/connections-advanced`}>
            <AdvancedConnections
              location={locationDetails}
              locationId={locationId}
              rooms={rooms}
              docks={docks}
              deviceConnectionsState={deviceConnectionsState}
            />
          </Route>
          <Route path={`${url}/scenes`}>
            <Scenes
              locationId={locationId}
              rooms={rooms}
              docks={docks}
            />
          </Route>
        </Switch>

        <LocationEditModal
          state={formModalState}
          onHide={() => {
            setFormModalState({ visible: false });
            onConfigurationChange();
          }}
        />
        <ConfigurationInfoModal
          show={showConfigInfoModal}
          onConfirm={doNotShowAgain => {
            if (doNotShowAgain) {
              localStorage.setItem('connectionsConfigPanelInfoConfirmed', 'true');
            }

            setShowConfigInfoModal(false);
            setSaveConfigurationModalState({ visible: true });
          }}
        />
        <SaveConfigurationModal
          show={saveConfigurationModalState.visible}
          onHide={() => setSaveConfigurationModalState({ visible: false })}
          onConfirm={() => {
            saveConfigurations(locationId, deviceConnections).then(() => {
              setIsConfigurationSynced(false);
              setLastSavedDevicesConnections([...deviceConnections]);
            });
            setSaveConfigurationModalState({ visible: false });
          }}
        />
        <ActionModal
          onHide={() => setShowDiscardModal(false)}
          onConfirm={() => {
            setDeviceConnections([...lastSavedDevicesConnections]);
            setShowDiscardModal(false);
          }}
          show={showDiscardModal}
          title=""
          header="Are you sure to discard?"
          description="All new connections & configured elements will be lost."
          altButton2Text="Discard"
        />
        <LeaveSceneModal
          show={leaveSceneModalState.visible}
          onHide={() => setLeaveSceneModalState({ visible: false, viewToMove: null })}
          onConfirm={() => {
            setLeaveSceneModalState({ visible: false, viewToMove: null });

            history.push(leaveSceneModalState.viewToMove);
          }}
        />
        <SendConfigurationModal
          show={sendConfigModalState.visible}
          onHide={() => setSendConfigModalState({ visible: false })}
          onConfirm={() => {
            sendLocationToBcb(locationId).then(() => setIsConfigurationSynced(true));
            setSendConfigModalState({ visible: false });
          }}
        />
      </div>
      <ReactTooltip disableStyleInjection="core" multiline id="ConfigPanelTooltip" />
    </div>
  );
};

export default withRouter(ConfigurationPanel);
