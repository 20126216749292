import IBFile from '../../../../models/file';
import { deleteLocationFile } from './location-details.api';

export interface File {
  blobId: any;
  file: {};
  fileName: string;
  fileType: number;
}

export const onFileDelete = (
  locationId: string,
  files: IBFile[],
  file: IBFile,
  onFilesChange: (filteredFiles: any) => void,
) => {
  const { blobId } = file;
  const filteredFiles = files.filter(att => att.fileName !== file.fileName);

  if (blobId) {
    deleteLocationFile(locationId, blobId, () => {
      onFilesChange(filteredFiles);
    });
  } else {
    onFilesChange(filteredFiles);
  }
};

export default {
  onFileDelete,
};
