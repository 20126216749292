import * as React from 'react';
import { SvgImage } from '@streda/web_components';
import { IValidationStatusProps } from './validation-status.types';
import { getAssetPath } from '../../../../utils/images.utils';

const ValidationStatus: React.FC<IValidationStatusProps> = (props: IValidationStatusProps) => {
  const { iconName, message, className } = props;
  return (
    <div className={className ?? ''}>
      <div className="form-validation-status">
        <SvgImage
          className="form-validation-status-icon"
          inAssetsPath={getAssetPath(`img/icons/${iconName}.svg`)}
        />
        {message}
      </div>
    </div>
  );
};

export default ValidationStatus;
