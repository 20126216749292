import styled, { css } from 'styled-components';

export const UserAvatar = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.d4};
  text-transform: uppercase;
`;

export const UserInfoToggleContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  overflow: hidden;
  user-select: none;
`;

export const UserInfo = styled.span`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  flex: 1;
  align-items: flex-start;
`;

const NameFixture = () => css`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.background};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: left;
`;

export const FirstName = styled.span`
  ${NameFixture()}
`;
export const LastName = styled.span`
  ${NameFixture()}
`;

export const UserName = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

export const Email = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.background};
`;
