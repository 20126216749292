import * as React from 'react';
import { Switch, withRouter, Route, Redirect } from 'react-router-dom';
import NotFoundPage from '../not-found-page';
import MobilePolicyPrivacy from './mobile-policy-privacy';

const PrivacyPolicyApp: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/privacypolicy">
        <Redirect to="/privacypolicy/mobile" />
      </Route>
      <Route path="/privacypolicy/mobile">
        <MobilePolicyPrivacy />
      </Route>
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default withRouter(PrivacyPolicyApp);
