import * as React from 'react';
import {
  ActionModal,
  Button,
  ButtonType,
  CompanyIcon,
  ISelectOption,
  LabeledSelect,
  Toast,
} from '@streda/web_components';
import { assignCompanyToLocation, getCompaniesOptions } from './company-selection.logic';
import { ILocationEditModalState } from '../location-edit-modal/location-edit-modal.types';
import { assignLocationToCompany } from '../location-edit-modal/location-edit-modal.logic';
import { onBackupRestore } from '../location-settings/location-settings.logic';

export interface IAssignInstallerProps {
  onChange: (companyId: string) => void;
  state: ILocationEditModalState;
}

const CompanySelection: React.FC<IAssignInstallerProps> = ({ onChange, state }) => {
  const [companies, setCompanies] = React.useState<ISelectOption[]>([]);
  const [selectedCompany, setSelectedCompany] = React.useState<ISelectOption>();
  const [showRemoveCompanyModal, setShowRemoveCompanyModal] = React.useState<boolean>(false);
  const [pending, setPending] = React.useState<boolean>(false);
  const isEditMode = !!state.data.id;

  React.useEffect(() => {
    getCompaniesOptions().then(companiesOptions => {
      setCompanies(companiesOptions);

      if (state.company) {
        const selectedCompanyOption = companiesOptions.find(
          company => company.value === state.company,
        );
        setSelectedCompany(selectedCompanyOption);
      }
    });
  }, [state.company]);
  const onAssignCompany = () => {
    setPending(true);
    assignCompanyToLocation(state.data.id, selectedCompany.value)
      .then(() => {
        onChange(selectedCompany.value);
        Toast.showToast({
          colorType: 'Success',
          message: 'Company has been assigned.',
        });
      })
      .finally(() => setPending(false));
  };

  const onRemoveCompany = () => {
    setPending(true);
    setShowRemoveCompanyModal(false);
    assignCompanyToLocation(state.data.id, null)
      .then(() => {
        onChange(undefined);
        setSelectedCompany(undefined);
        Toast.showToast({
          colorType: 'Success',
          message: 'Company has been removed.',
        });
      })
      .finally(() => setPending(false));
  };

  return (
    <div>
      <div className="h2-medium">Building Company</div>
      <div className="w-420 inline-flex-container justify-content-space-between align-bottom">
        <LabeledSelect
          label="Company name"
          labelClassName="color-d3"
          className="w-100p"
          placeholder="Select Company..."
          value={selectedCompany}
          options={companies}
          disabled={isEditMode && !!state.company}
          onChange={value => {
            if (state.data.id) {
              setSelectedCompany(value);
            } else {
              onChange(value.value);
            }
          }}
        />
        {state.data.id && state.company && (
          <Button
            className="m-l-12"
            type={ButtonType.BUTTON}
            text="Remove"
            disabled={false}
            onClick={() => setShowRemoveCompanyModal(true)}
            pending={pending}
          />
        )}
        {state.data.id && !state.company && (
          <Button
            className="m-l-12"
            type={ButtonType.BUTTON}
            text="Assign"
            disabled={!selectedCompany}
            onClick={onAssignCompany}
            pending={pending}
          />
        )}
      </div>
      <ActionModal
        onHide={() => setShowRemoveCompanyModal(false)}
        onConfirm={onRemoveCompany}
        icon={<CompanyIcon width={84} height={84} />}
        show={showRemoveCompanyModal}
        title=""
        header="Remove company"
        description={
          <>
            Do you want to remove company from location?
            <br /> Company users will no longer have access.
          </>
        }
        altButton2Text="Delete"
      />
    </div>
  );
};

export default CompanySelection;
