import * as React from 'react';
import { INavItemProps } from './nav-item.types';
import { NavContainer, StyledNavLink } from './nav-item.components';

const NavItem: React.FC<INavItemProps> = (props: INavItemProps) => {
  const { to, children, icon, collapsed, onClick } = props;

  const getContent = () => {
    return (
      <>
        {icon && <div className="b-nav-icon">{icon}</div>}
        {children && !collapsed ? children : null}
      </>
    );
  };

  if (to) {
    return (
      <StyledNavLink className="b-nav-link" to={to} activeClassName="b-nav-link-active">
        {getContent()}
      </StyledNavLink>
    );
  }

  return (
    <NavContainer
      className="b-nav-link"
      onClick={event => {
        if (onClick) {
          onClick(event);
        }
      }}
    >
      {getContent()}
    </NavContainer>
  );
};

export default NavItem;
