import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const NavContainer = styled.div`
  cursor: pointer;
  user-select: none;
  color: ${({ theme }) => theme.colors.font1} !important;
`;

export const StyledNavLink = styled(NavLink)`
  color: ${({ theme }) => theme.colors.font1} !important;
`;
