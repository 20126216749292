import * as React from 'react';
import LocationSettings from './location-settings';
import LocationSettingsAuth from './location-settings-auth';

const LocationSettingsWrapper = props => {
  const { onAuthPinChange, authorized, locationId } = props;

  return (
    <div className="location-settings-wrapper">
      {authorized}
      {authorized ? (
        <LocationSettings locationId={locationId} />
      ) : (
        <LocationSettingsAuth onPinCodeChange={onAuthPinChange} />
      )}
    </div>
  );
};

export default LocationSettingsWrapper;
