import * as React from 'react';
import { NoneButton, Input, ArrowLeftIcon, StredaLogo } from '@streda/web_components';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

const StyledTopBar = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: white;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  height: 91px;
  padding: 0px 56px;
  z-index: 99999;
`;

const BackButtonContainer = styled.div`
  display: inline-flex;
  user-select: none;
`;

const StyledLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 50px;
  cursor: pointer;
  text-decoration: none !important;

  &.active {
    .nav-item {
      &__text {
        color: ${({ theme }) => theme.colors.font1};
      }

      &__fill-icon {
        svg path {
          fill: ${({ theme }) => theme.colors.secondary};
        }
      }

      &__stroke-icon {
        svg path {
          stroke: ${({ theme }) => theme.colors.secondary};
        }
      }
    }
  }

  &__fill-icon {
    svg path {
      fill: ${({ theme }) => theme.colors.font1};
    }
  }

  &__stroke-icon {
    svg path {
      stroke: ${({ theme }) => theme.colors.font1};
    }
  }

  .nav-item-text {
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.font1};
    user-select: none;
    margin-left: 9px;
  }
`;

interface ILocationDashboardTopBarProps {
  withoutSearch?: boolean;
  searchText?: string;
  setSearchText?: (value: string) => void;
}

const LocationDashboardTopBar: React.FunctionComponent<ILocationDashboardTopBarProps> = props => {
  const { withoutSearch, searchText, setSearchText } = props;
  const history = useHistory();
  const { locationId } = useParams() as { locationId: string | undefined };

  return (
    <StyledTopBar>
      <div className="inline-flex-container">
        <NoneButton
          data-tooltip-content="Go to Admin Panel"
          className="m-r-60"
          type="button"
          onClick={() => history.push('/locations')}
          data-testid="logo-button"
        >
          <div className="logo-wrapper">
            <StredaLogo />
            <span className="home-owner-label">Home</span>
          </div>
        </NoneButton>
        <StyledLink to={`/location/${locationId}`} activeClassName="active">
          <span className="nav-item-text">LOCATION DASHBOARD</span>
        </StyledLink>
        <StyledLink to={`/location/${locationId}/analytics`} activeClassName="active">
          <span className="nav-item-text">ANALYTICS</span>
        </StyledLink>
        {!withoutSearch && (
          <div className="inline-flex-container">
            <Input
              className="m-l-36"
              value={searchText}
              onChange={newVal => setSearchText(newVal)}
              placeholder="Search specific device..."
              testId="search-device"
            />
          </div>
        )}
      </div>
      <BackButtonContainer>
        <NoneButton
          type="button"
          onClick={() => history.push('/locations')}
          data-testid="back-to-admin-button"
        >
          <div className="inline-flex-container">
            <span className="back-label">Go to Admin Panel</span>
            <ArrowLeftIcon />
          </div>
        </NoneButton>
      </BackButtonContainer>
    </StyledTopBar>
  );
};

export default LocationDashboardTopBar;
