import * as React from 'react';
import { Switch, withRouter, Route } from 'react-router-dom';
import LocationsPage from './locations-page/locations-page';
import NotFoundPage from '../not-found-page';

const LocationsApp: React.FC = () => {
  return (
    <Switch>
      <Route exact path={['/locations', '/locations/:locationId', '/locations/addLocation']}>
        <LocationsPage className="page-content" />
      </Route>
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default withRouter(LocationsApp);
