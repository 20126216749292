import * as React from 'react';
import { Switch, withRouter, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { CreateScene, hideLoader, ModalLoader, Page, showLoader } from '@streda/web_components';
import styled from 'styled-components';
import NotFoundPage from '../../../not-found-page';
import ScenesList from './scenes-list/scenes-list';
import { saveScene } from '../configuration-panel/configuration-panel.logic.api';

const ScenesPageGrid = styled(Page)`
  margin-top: 72px;
`;

const Scenes = props => {
  const { url } = useRouteMatch();
  const { locationId, rooms, docks, location } = props;
  const clearScene = {
    actions: [],
    locationId,
    name: '',
    enabled: true,
    roomIds: null,
  };
  const sceneInEditState = React.useState({ ...clearScene });
  const [sceneInEdit, setSceneInEdit] = sceneInEditState;
  const history = useHistory();

  return (
    <Switch>
      <Route exact path={url}>
        <ScenesList locationId={locationId} rooms={rooms} docks={docks} />
      </Route>
      <Route path={[`${url}/create-scene`, `${url}/edit-scene`]}>
        <ScenesPageGrid grid>
          <CreateScene
            rooms={rooms}
            docks={docks}
            sceneInEditState={sceneInEditState}
            location={location}
            onCreateSceneModalCancel={() =>
              history.push(`/location/${locationId}/configuration/scenes`)
            }
            onSceneSave={() => {
              showLoader(<ModalLoader />);
              saveScene(locationId, sceneInEdit)
                .then(() => {
                  setSceneInEdit(clearScene);
                  history.push(`/location/${locationId}/configuration/scenes`);
                })
                .finally(() => hideLoader())}
            }
          />
        </ScenesPageGrid>
      </Route>
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default withRouter(Scenes);
