import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { Footer, LoginPageContainer } from '@streda/web_components';
import styled from 'styled-components';
import { ILoginPageProps } from './login-page.types';
import { useAuth } from '../../../utils/hooks/useAuth';
import { ThemeContext } from '../../../ThemeContext';

const waveGraphic = require('../../../assets/bitmap/wave.png');

const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 0;
  background: #373b40;
`;

const WaveGraphic = styled.img`
  width: 100%;
  height: 100%;
  object-fit: none;
  z-index: 0;
  position: fixed;
  left: 0;
  overflow: hidden;
  top: 0;
  vertical-align: middle;
`;

const LoginPage: React.FC<ILoginPageProps> = (props: ILoginPageProps) => {
  const { loginCallback, history } = props;
  const [errorState, setErrorState] = React.useState('');
  const { setDarkTheme } = React.useContext(ThemeContext);

  React.useEffect(() => {
    setDarkTheme(true);
    return () => {
      setDarkTheme(false);
    };
  }, []);

  const onLoggingInError = (state: string) => {
    setErrorState(state);
  };

  const onExternalLoginSuccess = () => {
    loginCallback();
    history.push('/');
  };

  const { login, inProgress } = useAuth(onExternalLoginSuccess, onLoggingInError);

  return (
    <>
      <BackgroundContainer>
        <WaveGraphic alt="" src={waveGraphic.default} />
        <Footer companyName="Copyrights 2023 Streda®, Isolectra B.V" />
      </BackgroundContainer>
      <LoginPageContainer
        onLoginHandler={login}
        loginInProgress={inProgress}
        errorMsg={errorState}
      />
    </>
  );
};

export default withRouter(LoginPage);
