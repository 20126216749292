import * as React from 'react';
import getClassNameForStatus from './network-status-indicator.logic';
import { INetworkStatusIndicatorProps, StatusTextDict } from './network-status-indicator.types';

const NetworkStatusIndicator: React.FC<INetworkStatusIndicatorProps> = (
  props: INetworkStatusIndicatorProps,
) => {
  const { status, className } = props;
  return (
    <div className={className}>
      <div className={getClassNameForStatus(status)}>{StatusTextDict[status]}</div>
    </div>
  );
};

export default NetworkStatusIndicator;
