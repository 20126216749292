import { ILocationEditModalState } from './location-edit-modal.types';
import { locationStatuses, getStatusNumberByName } from '../../../../utils/location.utils';
import {
  getLocationDetailsValidation,
  getOwnerDetailsValidation,
  getDocksValidation,
  countValidator,
  LocationValidationType,
  getCompanySelectionValidation,
} from './validation.def';

export const getValidatorByType = (type: LocationValidationType, status = 'Draft') => {
  return model => {
    switch (type) {
      case 'location-details':
        return getLocationDetailsValidation(model, status);
      case 'company':
        return getCompanySelectionValidation(model, status);
      case 'owner-details':
        return getOwnerDetailsValidation(model, status);
      case 'docks':
        return getDocksValidation(model);
      case 'rooms':
      case 'docks-count':
      case 'installers-count':
      case 'home-owner-count':
        return countValidator(model);
      default:
        return null;
    }
  };
};

export const validateByStatusAndType = (
  model: any,
  statusNumber: number,
  type: LocationValidationType,
) => {
  const statusInfo = locationStatuses.find(s => s.number === statusNumber);
  if (statusInfo) {
    const validationResult = getValidatorByType(type, statusInfo.name)(model);
    return !validationResult || (!!validationResult && validationResult.length < 1);
  }
  return true;
};

export const validateStep = (state: ILocationEditModalState): boolean => {
  return validateByStatusAndType(
    state.data.details,
    getStatusNumberByName(state.data.details.status ?? 'Draft'),
    'location-details',
  );
  // &&
  // validateByStatusAndType(
  //   state.data.owner,
  //   getStatusNumberByName(state.data.details.status ?? 'Draft'),
  //   'owner-details',
  // )
};
