import * as globalImportUrl from 'url';
import Axios, { AxiosPromise } from 'axios';
import { basePath, RequiredError } from '../utils/api.utils';

/* Uploading an array of files is not supported using swagger with OpenAPI standard so
 * OpenApi Codegen cannot generate proper service method for "UploadFile".
 * It can be fixed by calling FormData.append() for each file.
 *
 * https://swagger.io/docs/specification/2-0/file-upload/ - Multiple Upload section
 */

const uploadMultipleAttachments = (
  id: string,
  fileType?: number,
  formFiles?: Array<File>,
  options: any = {},
  configuration?,
): AxiosPromise<any> => {
  if (id === null || id === undefined) {
    throw new RequiredError(
      'id',
      'Required parameter id was null or undefined when calling locationIdUploadFilePost.',
    );
  }
  const path = `/Location/{id}/uploadFile`.replace(`{${'id'}}`, encodeURIComponent(String(id)));

  let baseOptions;
  if (configuration) {
    baseOptions = configuration.baseOptions;
  }
  const urlObj = globalImportUrl.parse(path, true);
  const requestOptions = { method: 'POST', ...baseOptions, ...options };
  const formParams = new FormData();
  const headerParameter = {} as any;
  const queryParameter = {} as any;

  // authentication Bearer required
  if (configuration && configuration.apiKey) {
    const apiKeyValue =
      typeof configuration.apiKey === 'function'
        ? configuration.apiKey('Authorization')
        : configuration.apiKey;
    headerParameter.Authorization = apiKeyValue;
  }

  if (fileType !== undefined) {
    queryParameter.fileType = fileType;
  }

  // Append an array of files to FormData
  if (formFiles && formFiles.length && formFiles.length > 0) {
    formFiles.forEach(formFile => {
      formParams.append('formFiles', formFile);
    });
  }

  headerParameter['Content-Type'] = 'multipart/form-data';

  urlObj.query = {
    ...urlObj.query,
    ...queryParameter,
    ...options.query,
  };
  // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
  delete urlObj.search;
  const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
  requestOptions.headers = {
    ...headerParameter,
    ...headersFromBaseOptions,
    ...options.headers,
  };
  requestOptions.data = formParams;

  return Axios(basePath + globalImportUrl.format(urlObj), requestOptions);
};

export default uploadMultipleAttachments;
