import _ = require('lodash');
import * as React from 'react';
import { getAccount, getUserApi, SystemUserDto, Toast } from '@streda/web_components';
import { SystemUserSortKey } from './users-page.types';

const useSystemUsers = (
  page: number,
  pageSize?: number,
  sortKey?: SystemUserSortKey,
  descending?: boolean,
): { systemUsers: SystemUserDto[]; totalUsers: number; loading: boolean; error?: boolean } => {
  const [systemUsers, setSystemUsers] = React.useState<SystemUserDto[]>([]);
  const [totalUsers, setTotalUsers] = React.useState<number>(0);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    const sortUsers = setTimeout(() => {
      if (!_.isEmpty(systemUsers) && sortKey) {
        setSystemUsers(prevState => {
          const sortedUsers = prevState.sort((ho1, ho2) => {
            const existingProperties = !_.isNil(ho1[sortKey]) && !_.isNil(ho2[sortKey]);
            if (descending) {
              return existingProperties ? ho2[sortKey].localeCompare(ho1[sortKey]) : -1;
            }
            return existingProperties ? ho1[sortKey].localeCompare(ho2[sortKey]) : -1;
          });
          return sortedUsers;
        });
        setLoading(false);
      }
    }, 500);
    return () => clearInterval(sortUsers);
  }, [sortKey, descending, systemUsers]);

  React.useEffect(() => {
    const getSystemUsers = async () => {
      try {
        setLoading(true);
        const response = await getUserApi(getAccount()).userGetSystemUser(
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          page,
          pageSize,
        );
        const { data } = response;
        setTotalUsers(data.recordsTotalNumber);
        setLoading(false);
        setSystemUsers([...systemUsers, ...data.data] as SystemUserDto[]);
      } catch {
        setLoading(false);
        setError(true);
        setSystemUsers([]);
        Toast.showToast({
          message:
            'Error during getting users. Please try again or contact with system administrator',
          colorType: 'Danger',
        });
      }
    };
    getSystemUsers();
  }, [page, pageSize]);

  return {
    systemUsers,
    totalUsers,
    loading,
    error,
  };
};

export default useSystemUsers;
