import * as React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { AccountRoles, hasUserRole } from '@streda/web_components';
import SideBar from './navigation/sidebar/sidebar';
import UsersApp from './pages/users/users.app';
import NotFoundPage from './pages/not-found-page';
import LocationsApp from './pages/locations/locations.app';
import CompaniesApp from './pages/companies/companies.app';
import LocationApp from './pages/location/location.app';
import * as pkj from '../../package.json';
import Homeowners from './pages/homeowners/homeowners';
import CompanyEdit from './company-edit';

const MainView: React.FC = () => {
  const isSuperAdmin = hasUserRole(AccountRoles.SUPER_ADMINISTRATOR);

  return (
    <Router>
      <div className="w-100p inline-flex-container full-height">
        <SideBar />
        <div className="main-content">
          <Switch>
            <Redirect exact from="/" to="/locations" />
            <Redirect exact from="/locations/reload" to="/locations" />
            <Route path="/users" component={UsersApp} />
            <Route path="/homeowners" component={Homeowners} />
            <Route path="/locations" component={LocationsApp} />
            <Route path="/location/:locationId" component={LocationApp} />
            <Route path="/companies" component={CompaniesApp} />
            {/* Do not mount settings route, as it is empty for now */}
            {/* <Route path="/settings" component={Settings} /> */}
            <Route component={NotFoundPage} />
          </Switch>
        </div>
        {!isSuperAdmin && <CompanyEdit />}
        <div className="build-version small-text">[{pkj.version}]</div>
      </div>
    </Router>
  );
};

export default MainView;
