import * as React from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  CloseIcon,
  IconButton,
  ButtonType,
  Button,
  LabeledInput,
  ActionModal,
  CompanyIcon,
  ButtonColorType,
  NewCompanyDto,
  CompanyDto,
  hasUserRole,
  AccountRoles,
} from '@streda/web_components';
import { addCompany, updateCompany } from './company-edit-modal.logic';
import {
  StyledModal,
  ModalHeader,
  ModalHeaderTitle,
  ModalBody,
  EditButton,
  ModalFooter,
  FullWidthButton,
} from '../../../layout/AddEditModal';
import { ICompanyEditModal } from './company-edit-modal.types';

const AdditionalInfoParagraph = styled.p`
  font-size: 12px;
  font-weight: 500;
  margin-top: 24px;
`;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2)
    .max(50)
    .required(),
  phoneNumber: Yup.string().matches(/^(\+\d{1,3})?\d{9,}$/, 'Phone number is not valid'),
  email: Yup.string().email('Email address is not valid'),
  city: Yup.string()
    .min(2)
    .max(50),
  street: Yup.string()
    .min(2)
    .max(50),
  zipCode: Yup.string().matches(/^[A-Z0-9]+(?:-[A-Z0-9]+)?$/i, 'Invalid zip code format'),
  country: Yup.string()
    .min(2)
    .max(50),
});

const CompanyEditModal: React.FC<ICompanyEditModal> = ({
  onSaveSuccess,
  onClose,
  companyDetails,
}) => {
  const initialValues: CompanyDto = companyDetails || {
    name: '',
    active: true,
  };
  const [previewMode, setPreviewMode] = React.useState(!!companyDetails);
  const [showActivationModal, setShowActivationModal] = React.useState(false);
  const isSuperAdmin = hasUserRole(AccountRoles.SUPER_ADMINISTRATOR);
  const onCompanyAdd = (company: NewCompanyDto) => addCompany(company);
  const onCompanyEdit = (company: NewCompanyDto) => updateCompany(companyDetails?.id, company);
  const onFormSubmit = async (values: NewCompanyDto) => {
    setShowActivationModal(false);
    const isSuccess = companyDetails?.id ? await onCompanyEdit(values) : await onCompanyAdd(values);

    if (isSuccess && onSaveSuccess) {
      onSaveSuccess();
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onFormSubmit,
  });
  const { errors, values } = formik;
  const onChangeActiveStatus = () => {
    formik.setFieldValue('active', !formik.values.active);
    formik.submitForm();
  };

  return (
    <div>
      <StyledModal title="" show onHide={() => {}}>
        <form onSubmit={formik.handleSubmit}>
          <ModalHeader>
            <ModalHeaderTitle>
              {companyDetails?.id ? 'Company details' : 'Add New Company'}
            </ModalHeaderTitle>
            <IconButton type={ButtonType.BUTTON} icon={<CloseIcon />} onClick={() => onClose()} />
          </ModalHeader>
          <ModalBody>
            {previewMode && <EditButton onClick={() => setPreviewMode(false)}>Edit</EditButton>}
            <LabeledInput
              placeholder="Type name"
              label="Company name"
              value={values.name}
              onChange={value => formik.setFieldValue('name', value)}
              disabled={previewMode}
              type="text"
              error={!!errors.name}
              errorMessage={errors.name}
            />
            <LabeledInput
              placeholder="Type phone number"
              label="Phone number"
              value={values.phoneNumber}
              onChange={value => formik.setFieldValue('phoneNumber', value)}
              disabled={previewMode}
              type="text"
              error={!!errors.phoneNumber}
              errorMessage={errors.phoneNumber}
            />
            <LabeledInput
              placeholder="Type email"
              label="Email address"
              value={values.email}
              onChange={value => formik.setFieldValue('email', value)}
              disabled={previewMode}
              type="text"
              error={!!errors.email}
              errorMessage={errors.email}
            />
            <LabeledInput
              placeholder="Type city"
              label="City"
              value={values.city}
              onChange={value => formik.setFieldValue('city', value)}
              disabled={previewMode}
              type="text"
              error={!!errors.city}
              errorMessage={errors.city}
            />
            <LabeledInput
              placeholder="Type street"
              label="Street"
              value={values.street}
              onChange={value => formik.setFieldValue('street', value)}
              disabled={previewMode}
              type="text"
              error={!!errors.street}
              errorMessage={errors.street}
            />
            <LabeledInput
              placeholder="Type zip code"
              label="Zip code"
              value={values.zipCode}
              onChange={value => formik.setFieldValue('zipCode', value)}
              disabled={previewMode}
              type="text"
              error={!!errors.zipCode}
              errorMessage={errors.zipCode}
            />
            <LabeledInput
              placeholder="Type country"
              label="Country"
              value={values.country}
              onChange={value => formik.setFieldValue('country', value)}
              disabled={previewMode}
              type="text"
              error={!!errors.country}
              errorMessage={errors.country}
            />
            {previewMode && !formik.values.active && (
              <FullWidthButton
                type={ButtonType.BUTTON}
                colorType={ButtonColorType.WHITE}
                text={formik.isSubmitting ? '' : 'Reactive company'}
                onClick={() => setShowActivationModal(true)}
                pending={formik.isSubmitting}
              />
            )}
            {previewMode && formik.values.active && isSuperAdmin && (
              <FullWidthButton
                type={ButtonType.BUTTON}
                colorType={ButtonColorType.WHITE}
                text={formik.isSubmitting ? '' : 'Deactivate company'}
                onClick={() => setShowActivationModal(true)}
                pending={formik.isSubmitting}
              />
            )}
            {!companyDetails?.id && (
              <AdditionalInfoParagraph>
                After creating company you can invite users.
              </AdditionalInfoParagraph>
            )}
          </ModalBody>
          <ModalFooter>
            {!previewMode && (
              <Button
                type={ButtonType.SUBMIT}
                text={companyDetails?.id ? 'Save changes' : 'Create'}
                disabled={!formik.isValid || formik.isSubmitting}
                pending={formik.isSubmitting}
              />
            )}
          </ModalFooter>
        </form>
      </StyledModal>
      <ActionModal
        onHide={() => setShowActivationModal(false)}
        onConfirm={() => onChangeActiveStatus()}
        show={showActivationModal}
        backdrop={false}
        icon={<CompanyIcon width="84px" height="84px" />}
        title=""
        header={formik.values.active ? 'Deactivate company' : 'Reactivate company'}
        description={
          <>
            Do you want to {formik.values.active ? 'deactivate' : 'reactivate'} company?
            <br />
            <b>{formik.values.name}</b>
          </>
        }
      />
    </div>
  );
};

export default CompanyEditModal;
